import React from "react";
import './css/dashboard-styles.css';
import Header from "../../components/Header";
import DashboardHead from "./DashboadHead";
import DashboardProjects from "./DashboardProjects";
import DashboardContent from "./DashboardContent";
import {Helmet} from "react-helmet";
import Footer from "../Footer";
import {useSelector} from "react-redux";

const Dashboard = () => {

    const brandName = useSelector(state => state.rebrand.data);

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'VirtualReel'} | Dashboard </title>
            </Helmet>
            <div className="dashboard-page-body">
                <main className="dashboard-page-main">
                    <Header/>

                    <DashboardHead brandName={brandName} />
                    <DashboardProjects/>
                    <DashboardContent brandName={brandName} />

                    <Footer/>
                </main>
            </div>
        </>
    )
}


export default React.memo(Dashboard);