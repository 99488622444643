import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {faPlay, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VideoPopupModal from "../editor/VideoPopupModal";

const MetaVerseVideo = ({videos,handleSelectVideo, user, type, error, fetchVideos360, loader}) => {

    const scriptData = useSelector(state => state.script);

    const [videoData, setVideoData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const selectVideo = ( url, time, vid_type, poster) => {

        let duration = 1;

        if(vid_type === 'youtube') {
            let timeArr = time.split('ago ');
            let durationArr = timeArr[1].split(' ');
            duration = (parseInt(durationArr[0])*60)+parseInt(durationArr[2]);
        }else{
            duration = vid_type
        }

        let tempData = {
            user_id: user.id,
            campaign_type: type==='five'? `campaign_one` : `campaign_${type}`,
            bg_file: [{
                startTime: 0,
                endTime: 10,
                path: url,
                poster: poster,
                opacity: 1
            }],
            duration: duration
        }

        if(type === 'two'){
            tempData.text = scriptData.script;
        }
        if(type === 'regular'){
            tempData.campaign_type = 'campaign_three';
        }

        handleSelectVideo(tempData);
    }

    const handlePlayVideo = (e, url) => {
        e.stopPropagation();

        setShowModal(true);
        setVideoUrl(url)
    }

    const handleLoadMore = () => {
        fetchVideos360();
    }

    useEffect(()=>{
        setVideoData(videos);
    },[videos])

    return(
        <>
            <div className="gallery">
                <div className="row">
                {
                    // videoData.length > 0 ?
                        videoData.map((item, index)=>(
                            <div className="col-12 col-md-3 cursor-pointer" key={index}
                                 onClick={(e)=>selectVideo(
                                     item.hasOwnProperty('type') ? item.url : item.link, item.timestring,
                                     item.hasOwnProperty('type') ? item.duration : 'youtube',
                                     item.hasOwnProperty('type') ? item.thumbnail : `https://img.youtube.com/vi/${item.link.split('v=')[1]}/mqdefault.jpg`
                                 )}
                            >
                                <div className="media-wrapper test4">
                                <div className="vid-dur"><span>{item?.duration} s</span></div>
                                    {
                                        item.hasOwnProperty('type') ?
                                            <img src={item.thumbnail} alt="360 Degree VR Videos" width="100%" key={index} />
                                        : <img src={`https://img.youtube.com/vi/${item.link.split('v=')[1]}/mqdefault.jpg`}
                                                 alt="360 Degree VR Videos" width="100%" key={index} />
                                    }

                                    <div className="overlay__media__wrapper">
                                        {/* <FontAwesomeIcon color={`#00c780`} icon={faPlay} onClick={(e)=>handlePlayVideo(e, item.hasOwnProperty('type') ? item.url : item.link)} /> */}
                                        <button onClick={(e)=>handlePlayVideo(e, item.hasOwnProperty('type') ? item.url : item.link)}>Preview</button>
                                        <button>Select</button>
                                    </div>
                                </div>
                            </div>
                        ))
                        // : ''
                }

                    {
                        error !== '' ?
                            <p>{error}</p>
                        : ''
                    }
                </div>
            </div>

            <div className="lMore">
                <button onClick={()=>handleLoadMore()}>
                    {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} Load More
                </button>
            </div>

            <VideoPopupModal showModal={showModal} setShowModal={setShowModal} videoUrl={videoUrl} />

        </>
    )
}


export default React.memo(MetaVerseVideo);