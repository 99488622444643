
export const addVideos = (data) => (dispatch) => {
    dispatch({type: 'ADD_VIDEOS', payload: data})
}

export const addArticles = (data) => (dispatch) => {
    dispatch({type: 'ADD_ARTICLES', payload: data})
}

export const addScriptData = (data) => (dispatch) => {
    dispatch({type: 'ADD_SCRIPT_DATA', payload: data})
}

export const addRegularData = (data) => (dispatch) => {
    dispatch({type: 'ADD_REGULAR_DATA', payload: data})
}

export const changeCurrentLoad = (data) => (dispatch) => {
    dispatch({type: 'CHANGE_CURRENT_LOAD', payload: data})
}