import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL, imageData} from "../../global/global";
import swal from "sweetalert";
import {addImageLayer, replaceImageLayer, updateCampTwoMedia, updateTextStyle} from "../../actions/editorAction";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import randomstring from "randomstring";
import {addTimelineData} from "../../actions/timelineAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert";


const UploadMedia = () => {

    const allowedExt = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const campType = useSelector(state => state.currentLayer.data.campaign_type);
    const textData = useSelector(state => state.currentLayer.data.texts_data);

    const selectedText = textData.filter(text => text.is_selected === true);

    const user_id = auth.user!==null?auth.user.id:'';

    const [loader, setLoader] = useState(false);
    const [percent, setPercent] = useState(0);
    const [videos, setVideos] = useState([]);
    const [images, setImages] = useState([]);

    const onInputFile = (e) => {

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 5000000;
                let type = "image_normal";
                if (e.target.files[0].type === "video/mp4") {
                    allowedSize = 500000000;
                    type = "normal";
                }

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('upload_type', type);
                    formData.append('user_id', user_id);
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}file-upload`, formData, options).then(res => {
                        if (res.data.status === true) {
                            fetchUserLibrary();
                        }else{
                            dispatch(setAlert(res.data.message,'danger'))
                        }
                        setLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for image 5MB for video 500MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    const fetchUserLibrary = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-user-assets`,
            data: {user_id: user_id},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setVideos(res.data.data.threesixtyvideo);
                setImages(res.data.data.PanoramaImages)
            }
        }).catch(err=>{

        })
    }

    const [deleteId, setDeleteId] = useState({
        id: '',
        type: '',
        user_id: user_id
    });
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);


    const onConfirm = () => {
        setShowDelete(true);
        setButton('Deleting...');

        axios({
            method: "POST",
            url: `${baseURL}delete-media`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchUserLibrary();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }


    const processBackground = (url, poster, type, id) => {

        dispatch(updateTextStyle('UPDATE_TIMELINE_SELECTED_LAYER', ''));

        delete imageData.background_slide_type;
        if(type === 'video'){
            imageData.id = id;
            imageData.video_src = url;
            delete imageData.src;
        }else{
            imageData.id = id;
            delete imageData.video_src;
            imageData.src = poster;
        }

        delete imageData.hover_video;

        dispatch(addTimelineData(imageData))
    }

    const handleImageLayer = async (type, url, poster) => {

        if(campType === 'campaign_two'){
            let repImage = {
                path: url,
                type: type,
                bgcolor: ''
            }
            if(type==='video'){
                repImage.size = poster;
            }
            console.log(repImage, 'media');

            if(selectedText.length > 0){
                repImage.id = selectedText[0].id;
                dispatch(updateCampTwoMedia('UPDATE_CAMP_TWO_MEDIA',repImage));
            }
        }else{
            dispatch(updateTextStyle('UPDATE_SELECTED_IMAGE_LAYER', ''));
            let id = randomstring.generate({length: 10, charset: 'numeric'});

            let imageJson = {
                id: id,
                size: "1920x1080",
                startTime: 0,
                endTime: 10,
                type: type,
                path: url,
                poster: poster,
                position: [0,0],
                scale: [1,1],
                opacity: 1,
                is_selected: true
            }

            if(campType === 'campaign_three'){
                dispatch(replaceImageLayer(imageJson));
            }else{
                dispatch(addImageLayer(imageJson));
                processBackground(url, poster, type, id)
            }
        }
    }

    const handleDelete = (e, id, type) => {
        e.stopPropagation();

        setDeleteId({...deleteId, id: id, type: type, user_id: user_id});
        setShowDelete(true);
    }


    useEffect(()=>{
        fetchUserLibrary();

       return () => {
           setImages([]);
           setVideos([]);
       }
    },[])


    return(
        <>
            <Alert/>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="media-upload-box">
                        <button type="button" className="form-control"><span className="mr-1"><i className="fas fa-cloud-upload-alt" /></span> Upload Media</button>
                        <div className="custom-file">
                            <input disabled={loader} type="file" onChange={(e)=>onInputFile(e)} className="custom-file-input" id="inputGroupFile01" />
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <div className="progress mt-4">
                        <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#00a5b8" }} aria-valuenow={`${percent}`}
                             aria-valuemin="0" aria-valuemax="100">{percent}%
                        </div>
                    </div>
                    : ''
            }
            <div className="search-load-box mt-4 asas1">
                <div className="row">

                    {
                        images.length > 0 ?
                            images.map((image, index)=>(
                                image.type === "normal" ?
                                    <div className="colmn col-6 cursor-pointer" key={index} onClick={()=>handleImageLayer('image',image.url, image.url)}>
                                        <div className="search-load d-flex justify-content-center align-items-center media-upload">
                                            <img src={image?.url} alt="thumbnail" width="100%" />
                                            <div className="vid-dur">
                                                <div className="delete-icon icon" onClick={(e)=>handleDelete(e, image.id, 'image_normal')}>
                                                <svg viewBox="0 0 448 512">
                                                    <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                : ''
                            ))
                        : ''
                    }

                    {
                        videos.length > 0 ?
                            videos.map((video, index)=>(
                                video.video_type === "normal" ?
                                    <div className="colmn col-6 cursor-pointer" key={index} onClick={()=>handleImageLayer('video',video.video_url, video.poster)}>
                                        <div className="search-load d-flex justify-content-center align-items-center  media-upload">
                                            <img src={video?.poster} alt="thumbnail" width="100%" />
                                            <div className="vid-dur">
                                                <div className="delete-icon icon" onClick={(e)=>handleDelete(e, video.id, 'normal')}>
                                                    <svg viewBox="0 0 448 512">
                                                        <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            ))
                            : ''
                    }

                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={()=>onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>

        </>
    )
}

export default React.memo(UploadMedia);