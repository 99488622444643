import React from "react";
// import mac from "../../images/mac.png";
// import iMac from "../../images/imac.png";
import s3Bg from "../../images/s3-bg.png";
import s3Img from "../../images/s3-img.png";
import mac1 from "../../images/mac1.png";
import mac2 from "../../images/mac2.png";
import mac3 from "../../images/mac3.png";
import mac4 from "../../images/mac4.png";


const DashboardContent = (props) => {
    return(
        <>
            <section id="s2" className="s2  dashb-sec">
                <div className="custom-wrapper container-fluid">
                    <div className="content-wrapper">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 colmn colmn-1 mb-lg-0 mb-4">
                                <div className="title-area mb-3">
                                    <h4 className="f-uppercase mb-2">Instantly create interactive 3D videos</h4>

                                    <h3 className="f-700">Convert regular videos into Metaverse videos </h3>
                                </div>

                                <div className="text-wrapper">
                                    <p className="md-txt">Turn boring, old-fashioned videos into interactive 3D
                                        Metaverse videos instantly. No special cameras or technical know-how needed.</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 colmn colmn-2">
                                <div className="img-wrapper">
                                    <img className="img-fluid mx-auto" src={mac1} alt="mac" />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 colmn colmn-1 mb-lg-0 mb-4 order-sm-2 order-1">
                                <div className="title-area mb-3">
                                    <h4 className="f-uppercase mb-2">Never run out of fresh 360 content</h4>

                                    <h3 className="f-700">Turn any text into 100s of Metaverse videos using A.I.</h3>
                                </div>

                                <div className="text-wrapper">
                                    <p className="md-txt">Create a collection of ready-to-use, high-converting 360
                                        videos for your ads or content simply by copy-pasting any text or article.</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 colmn colmn-2 order-sm-1 order-2">
                                <div className="img-wrapper">
                                    <img className="img-fluid mx-auto" src={mac2} alt="mac" />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 colmn colmn-1 mb-lg-0 mb-4">
                                <div className="title-area mb-3">
                                    <h4 className="f-uppercase mb-2">Effortlessly get your brand remembered </h4>

                                    <h3 className="f-700">Fully customize your 3D videos in a few clicks</h3>
                                </div>

                                <div className="text-wrapper">
                                    <p className="md-txt">Create fully-brandable and social media-optimized 360 Degree VR Videos
                                        displaying your logos, brand colors, 3D avatars, and more. </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 colmn colmn-2">
                                <div className="img-wrapper">
                                    <img className="img-fluid mx-auto" src={mac3} alt="mac" />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 colmn colmn-1 mb-lg-0 mb-4 order-sm-2 order-1">
                                <div className="title-area mb-3">
                                    <h4 className="f-uppercase mb-2">Make 3D videos your audience will love</h4>

                                    <h3 className="f-700">Get unlimited access to a Metaverse-ready media library</h3>
                                </div>

                                <div className="text-wrapper">
                                    <p className="md-txt">Choose from 1000s of images, videos, audio, music, 3D
                                        characters, and more that you can add to your and your clients 360 Degree VR Videos.</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 colmn colmn-2 order-sm-1 order-2">
                                <div className="img-wrapper">
                                    <img className="img-fluid mx-auto" src={mac4} alt="mac" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="join-now" className="s3 s3-alt dashb-sec" style={{backgroundImage: `url(${s3Bg})`}}>
                <div className="custom-wrapper container">
                    <div className="content-wrapper">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-12 colmn colmn-1 mb-lg-0 mb-4">
                                <p className="h4 f-uppercase mb-1">{props.brandName!==false? props.brandName.name :'VIRTUALREEL'} LESSON #1</p>

                                <p className="h2 f-500 mb-3"><span style={{color: "#00FFFF"}}>Join Us</span> for our LIVE
                                    Webinar.</p>

                                <div className="text-wrapper mb-3">
                                    <p className="md-txt">
                                        Join us for a private training that will walk you through how to use {props.brandName!==false? props.brandName.name :'VirtualReel'} to help you create and sell videos for maximum engagement, views and profits.
                                        This training or upgrade will not be made available afterwards. Sign up and make
                                        sure to show up on this live training with all your questions.
                                    </p>
                                </div>

                                

                                <a href="https://abhineos.com/training" className="d-flex demo-btn demo-btn-alt" target="_blank">
                                    <div className="play-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.08" height="17.846"
                                             viewBox="0 0 16.08 17.846">
                                            <path id="Path_33" data-name="Path 33"
                                                  d="M11.02.393C9.411-.529,8.107.227,8.107,2.081V15.764c0,1.856,1.3,2.611,2.913,1.689l11.96-6.859c1.609-.923,1.609-2.419,0-3.342Z"
                                                  transform="translate(-8.107 0)" fill="#fff"></path>
                                        </svg>
                                    </div>

                                   <p className="h5 f-500">Signup</p>
                                </a>
                                {/*    <p className="h4 f-600">Watch Demo Video</p>
                                {/*</a> *!/*/}
                            </div>
                            <div className="col-lg-6 col-12 colmn colmn-2">
                                <div className="img-wrapper">
                                    <img className="img-fluid mx-auto" src={s3Img} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="join-now" className="s3 s3-alt dashb-sec d-none" style={{backgroundImage: `url(${s3Bg})`}}>
                <div className="custom-wrapper container">
                    <div className="content-wrapper">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-12 colmn colmn-1 mb-lg-0 mb-4">
                                {/* <p className="h4 f-uppercase mb-1">{props.brandName!==false? props.brandName.name :'VIRTUALREEL'} LESSON #1</p> */}

                                <p className="h2 f-500 mb-3"><span style={{color: "#00FFFF"}}>Join Us</span> for our LIVE
                                    Webinar.</p>

                                <div className="text-wrapper mb-3">
                                    <p className="md-txt">
                                        Join us for a private training that will walk you through how to use {props.brandName!==false? props.brandName.name :'VirtualReel'} to help you create and sell videos for maximum engagement, views and profits.
                                        This training or upgrade will not be made available afterwards. Sign up and make
                                        sure to show up on this live training with all your questions.
                                    </p>
                                </div>

                                

                                <a href="https://abhineos.com/training" className="d-flex demo-btn demo-btn-alt" target="_blank">
                                    <div className="play-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.08" height="17.846"
                                             viewBox="0 0 16.08 17.846">
                                            <path id="Path_33" data-name="Path 33"
                                                  d="M11.02.393C9.411-.529,8.107.227,8.107,2.081V15.764c0,1.856,1.3,2.611,2.913,1.689l11.96-6.859c1.609-.923,1.609-2.419,0-3.342Z"
                                                  transform="translate(-8.107 0)" fill="#fff"></path>
                                        </svg>
                                    </div>

                                   <p className="h5 f-500">Signup</p>
                                </a>
                                {/*    <p className="h4 f-600">Watch Demo Video</p>
                                {/*</a> *!/*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(DashboardContent);