import React from "react";
import queryString from "query-string";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import Header from "../Header";
import TrainingTitle from "./TrainingTitle";
import TrainingContent from "./TrainingContent";
import Footer from "../Footer";
import TrainingArticleContent from "./TrainingArticleContent";

const TrainingArticle = ({location}) => {

    const brandName = useSelector(state => state.rebrand.data);

    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;

    const articleData = useSelector(state => state.support.articles);

    const filterArticle = articleData.filter(article => article.id === id)[0];
    const filterName = filterArticle.items.filter((id) => id.id == art_id)[0];


    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'VirtualReel'} | Training </title>
            </Helmet>

            <main className="training-main-body">
                <Header/>

                <section className="training">
                    <div className="custom-wrap">
                        <div className="row justify-content-between">
                            <div className="col-lg-4 colmn colmn-1">
                                <TrainingTitle id={id} supportData={articleData}  name={filterName} />
                            </div>
                            <div className="col-lg-8 colmn colmn-2">
                                <TrainingArticleContent id={art_id} supportData={filterArticle} />
                            </div>
                        </div>
                    </div>
                </section>

                <Footer/>
            </main>
        </>
    )
}

export default TrainingArticle;