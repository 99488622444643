import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import { addMetaVerseVideo } from "../../actions/editorAction";
import PlayVideoHover from "./PlayVideoHover";
import {updateBackground} from "../../actions/timelineAction";
import InfiniteScroll from "react-infinite-scroll-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faSpinner} from "@fortawesome/free-solid-svg-icons";
import VideoPopupModal from "./VideoPopupModal";

const SearchDimVideo = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [video, setVideo] = useState([]);
    const [search, setSearch] = useState({
        keyword: '',
        type: 'video',
        page_number: 1
    });

    const fetchUserLibrary = () => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}load-library-three-sixty-bg`,
            data: JSON.stringify({ keyword: search.keyword, type: search.type, page_number: search.page_number }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setVideo(res.data.data);
                setError(false)
            }
            else {
                setError(res.data.message)
            }

            setSearch({ ...search, page_number: search.page_number+1 });
            setLoader(false);
        }).catch(err => {
            setError('Getting internal serverl error')
            setLoader(false);
        })
    }

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });

        setVideo([]);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        fetchUserLibrary();
    }

    const handleBgVideo = (url, poster) => {
        dispatch(addMetaVerseVideo(url, poster))
        dispatch(updateBackground(url, poster))
    }

    const handlePlayVideo = (e, url) => {
        e.stopPropagation();

        setShowModal(true);
        setVideoUrl(url)
    }


    useEffect(() => {
        fetchUserLibrary();

        return () => {
            setVideo([]);
        }
    }, [])

    return (
        <>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="media-search-box">
                        <form method="post" onSubmit={(e) => onFormSubmit(e)} className="input-group">
                            <div className="input-group-prepend">
                                <button type="submit" className="input-group-text"><i className="fas fa-search" /></button>
                            </div>
                            <input type="text" onChange={(e) => onInputChange(e)} className="form-control" placeholder="Search" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="search-load-box mt-4 test5">
                <div className="row scrollSt" id="scrollableMetaVideo" style={{ overflowX: "hidden" }}>
                    <InfiniteScroll
                        dataLength={video.length} //This is important field to render the next data
                        next={() => fetchUserLibrary()}
                        hasMore={true}
                        scrollableTarget="scrollableMetaVideo"
                        className="row"
                    >
                    {
                        video.length > 0 ?
                            video.map((item, index) => (
                                <div className="colmn col-6 cursor-pointer" key={index} onClick={() => handleBgVideo(item.url, item.thumbnail)} >
                                    <div className="search-load d-flex justify-content-center align-items-center test1">

                                    <div className="vid-dur"><span>{item?.duration} s</span></div>

                                        <img src={item.thumbnail} alt="360 Degree VR Videos" width="100%" key={index} />
                                        <div className="overlay__media__wrapper">
                                            <FontAwesomeIcon color={`#00c780`} icon={faPlay} onClick={(e)=>handlePlayVideo(e, item.url)} />
                                            {/* <button onClick={(e)=>handlePlayVideo(e, item.url)}>Preview</button> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                            : ''
                    }
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h6 className="text-center" style={{fontSize: "30px"}}><FontAwesomeIcon icon={faSpinner} /></h6>
                                </div>
                                : ''
                        }
                    {
                        error ?
                            <div className="col-md-12">
                                <h6 className="text-center">{error}</h6>
                            </div>
                            : ''
                    }
                    </InfiniteScroll>
                </div>
            </div>

            <VideoPopupModal showModal={showModal} setShowModal={setShowModal} videoUrl={videoUrl} />
        </>
    )
}


export default React.memo(SearchDimVideo);