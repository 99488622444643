import React, {useState} from "react";
import defaultIcon from "../../images/aulbm.png";
import HoverVideoPlayer from "react-hover-video-player";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faTimesCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";

const MetaVerseUpload = ({handleSelectVideo, user, videos, type, fetchUserLibrary}) => {

    const scriptData = useSelector(state => state.script);
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const [deleteId, setDeleteId] = useState({
        id: '',
        type: '',
        user_id: ''
    });
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const handleDelete = (e, id, type) => {
        e.stopPropagation();

        setDeleteId({...deleteId, id: id, type: type, user_id: user.user.id});
        setShowDelete(true);
    }

    const onConfirm = () => {
        setShowDelete(true);
        setButton('Deleting...');

        axios({
            method: "POST",
            url: `${baseURL}delete-media`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': user.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchUserLibrary();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const selectVideo = (url, poster) => {
        let tempData = {
            user_id: user.user.id,
            campaign_type: type==='five'? `campaign_one` : `campaign_${type}`,
            bg_file: [{
                startTime: 0,
                endTime: 10,
                path: url,
                poster: poster,
                opacity: 1
            }],
            duration: 0
        }

        if(type === 'two'){
            tempData.text = scriptData.script;
        }
        if(type === 'regular'){
            tempData.campaign_type = 'campaign_three';
        }

        handleSelectVideo(tempData);
    }

    const handlePlayVideo = (e, url) => {
        e.stopPropagation();

        setShowModal(true);
        setVideoUrl(url)
    }

    return(
        <>
            
            <div className="gallery">
                <div className="row">
                {
                    videos.length > 0 ?
                        videos.map((item,index)=>(
                            item.video_type === "360" ?
                                <div className="col-12 col-md-3 cursor-pointer" key={index} >
                                    <div className="media-wrapper test3" onClick={()=>selectVideo(item.video_url,item.poster ,index)} >
                                    <div className="vid-dur cursor-pointer" onClick={(e)=>handleDelete(e, item.id, 'threesixty')}>
                                        <div className="delete-icon icon">
                                            <svg viewBox="0 0 448 512">
                                                <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                            </svg>
                                        </div>
                                    </div>

                                        <img src={item?.poster ? item.poster : defaultIcon} alt={item?.name} width="100%" height="100%" style={{ objectFit: "cover" }} />
                                        <div className="overlay__media__wrapper">
                                            <button onClick={(e)=>handlePlayVideo(e, item.video_url)}>Preview</button>
                                            <button>Select</button>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        ))
                        : ''
                }
                </div>
            </div>

            <div className={`modal fade demo-modal popular-videos  ${showModal ? 'show-drop' : ''}`} id="demoModal" tabIndex="-1" role="dialog"
                 aria-labelledby="demoModal" aria-hidden="true" style={{opacity: "1"}}>
                <div className="modal-dialog" role="document"
                     style={{width: "673px", margin: "0px auto", top: "80px"}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" />
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                                <span aria-hidden="true">
                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ReactPlayer controls={true} playing={showModal} url={videoUrl}/>
                        </div>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={()=>onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>
        </>
    )
}


export default React.memo(MetaVerseUpload);