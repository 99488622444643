import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import "./css/meta-library.css";
import Header from "../Header";
import Footer from "../Footer";
import {useDispatch, useSelector} from "react-redux";
import {addScriptData} from "../../actions/supportAction";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {baseURL} from "../../global/global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const CreateMetaText = () => {

    const brandName = useSelector(state => state.rebrand.data);
    const auth = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [langCode, setLangCode] = useState('da')
    const [membership, setMembership] = useState([]);

    const [scriptData, setScriptData] = useState({
        title: '',
        script: ''
    })

    const handleChange = (e) => {
        setScriptData({...scriptData, [e.target.name]: e.target.value});
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        dispatch(addScriptData(scriptData));
        history.push('/create-metaverse?type=two')
    }

    const handleTranslate = () => {
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}translate`,
            data: {lang_code: langCode, text: scriptData.script },
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setScriptData({...scriptData, script: res.data.data.text})
            }

            setLoader(false);
        }).catch(err=>{
            setLoader(false);
        })
    }


    const fetchLanguage = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-google-language`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setLanguages(res.data.data);
            }
        }).catch(err=>{
            console.log('error: ',err);
        })
    }

    useEffect(()=>{
        fetchLanguage();
    },[])

    /* For Check Membership Levels */
    useEffect(()=>{
        if(auth.user!==null){

            let memLevels = auth.user.membership.split('__');
            setMembership(memLevels);

        }
    },[auth.user])

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'VirtualReel'} | Script </title>
            </Helmet>
            <div className="meta-lib-page-body">
                <div className="meta-lib-page-main">

                    <Header/>
                    
                    <section id="videos-wrapper" className="videos-wrapper videos-wrapper-alt mb-5" >
                        <div className="custom-wrapper container">
                            <div className="scriptTitle pb-4">
                               <h6 className="text-white">Script <small>(Use [VIRTUALREEL] to bifurcate slides)</small></h6>
                                {
                                    membership.includes('professional') || membership.includes('professionallite') ?
                                        <div className="translateDiv">
                                            <span className="translatTitle">Translate into</span>
                                            <span>
                                      <select onChange={(e)=>setLangCode(e.target.value)} >
                                          <option disabled>Select Language</option>
                                          {
                                              languages.length > 0 ?
                                                  languages.map((language, index) => {
                                                      return (
                                                          <option value={language.code} key={index}>{language.name}</option>
                                                      )
                                                  })
                                                  : ''
                                          }

                                      </select>
                                  </span>
                                            <button onClick={()=>handleTranslate()} disabled={loader===true||scriptData.script===''?true:false} className="btn-grad">
                                                {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} GO
                                            </button>
                                        </div>
                                    : ''
                                }
                            </div>
                            <div className="script-box">
                                <form onSubmit={(e)=>handleFormSubmit(e)} method="post">
                                    {/*<div>*/}
                                    {/*    <label className="text-white">Title</label>*/}
                                    {/*    <input name="title" onChange={(e)=>handleChange(e)} type="text" className="form-control" />*/}
                                    {/*</div>*/}
                                    <div>
                                        <label for="add-script" className="text-white">Add your script</label>
                                        <textarea value={scriptData.script} rows="8" className="form-control" name="script" onChange={(e) => handleChange(e)}>{scriptData.script}</textarea>
                                        {/* <textarea rows="8" className="form-control" name="script" onChange={(e) => handleChange(e)} name="test"
                                        placeholder="Write or copy & paste your script" /> */}
                                    </div>
                                    <div className="mt-4 text-right">
                                        <button disabled={scriptData.script===''?'disable':''} type="submit" className="btn-create">Create</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>

                    <Footer/>
                </div>
            </div>
        </>
    )
}


export default React.memo(CreateMetaText);