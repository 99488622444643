import React from "react";
import HoverVideoPlayer from "react-hover-video-player";
import thumb from "../../images/thumb-1.png";
import {useSelector} from "react-redux";

const MetaVerseCampaign = ({handleSelectVideo , user, videos, type}) => {

    const scriptData = useSelector(state => state.script);

    const selectVideo = (url, poster) => {
        let tempData = {
            user_id: user.user.id,
            campaign_type: type==='five'? `campaign_one` : `campaign_${type}`,
            bg_file: [{
                startTime: 0,
                endTime: 10,
                path: url,
                poster: poster,
                opacity:1
            }],
            duration: 0
        }
        if(type === 'regular'){
            tempData.campaign_type = 'campaign_three';
        }

        if(type === 'two'){
            tempData.text = scriptData.script;
        }

        handleSelectVideo(tempData);
    }

    return(
        <>
            <div className="gallery mt-5">
                <div className="row">
                {
                    videos.length > 0 ?
                        videos.map((video,index)=>(
                            video.output_video !== '' ?
                                <div className="cursor-pointer col-12 col-md-3" key={index} onClick={()=>selectVideo(video.output_video, video.output_thumbnail)}>
                                    <div className="media-wrapper metaverse-video-box metaverse-video-box-alt" >
                                        <HoverVideoPlayer
                                            videoSrc={video.output_video}
                                            pausedOverlay={
                                                <img src={video?.output_thumbnail} alt={video?.name} width="100%" height="100%"  />
                                            }
                                            loadingOverlay={
                                                <div className="loader-center"><i style={{ color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></div>
                                            }
                                            className="video"
                                        />
                                        <div className="overlay__media__wrapper forVid">
                                            <div className="overlay__media__wrapper">
                                                <button>Select</button>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-center">{video.project_name}</p>
                                </div>
                            : ''
                        ))
                    :
                     <h4 className="text-center">No campaigns created</h4>
                }
                </div>
            </div>
        </>
    )
}

export default React.memo(MetaVerseCampaign);