import React from "react";
import './css/training-style.css';
import Header from "../Header";
import {Helmet} from "react-helmet";
import Footer from "../Footer";
import {useSelector} from "react-redux";
import queryString from "query-string";
import TrainingTitle from "./TrainingTitle";
import TrainingContent from "./TrainingContent";

const Training = ({location}) => {

    const brandName = useSelector(state => state.rebrand.data);
    const supportData = useSelector(state => state.support.articles);

    const articleId = queryString.parse(location.search).id;

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'VirtualReel'} | Training </title>
            </Helmet>

            <main className="training-main-body">
                <Header/>

                <section className="training">
                    <div className="custom-wrap">
                        <div className="row justify-content-between">
                            <div className="col-lg-4 colmn colmn-1">
                                <TrainingTitle id={articleId} supportData={supportData} name={false} />
                            </div>
                            <div className="col-lg-8 colmn colmn-2 pb-5">
                                <TrainingContent id={articleId} supportData={supportData} />
                            </div>
                        </div>
                    </div>
                </section>

                <Footer/>
            </main>
        </>
    )
}

export default Training;