export const addTimelineData = (data) => (dispatch) => {
    dispatch({type: 'ADD_LAYERS_DATA', payload: data})
}

export const replaceTimelineLayer = (data) => (dispatch) => {
    dispatch({type: 'ADD_NEW_LAYERS_DATA', payload: data})
}

export const updateBackground = (url, poster) => (dispatch) => {
    let data = {
        url: url,
        poster: poster
    }

    dispatch({type: 'UPDATE_BACKGROUND_TIMELINE', payload: data})
}

export const removeTimelineData = () => (dispatch) => {
    dispatch({type: 'REMOVE_BACKGROUND_TIMELINE'})
}

export const selectTimelineLayer = (id) => (dispatch) => {
    dispatch({type: 'ADD_SELECTED_TIMELINE_LAYER', payload: id})
}

export const updateTimelineScale = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_TIMELINE_SCALE', payload: data})
}

export const updateTimelinePosition = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_TIMELINE_TOP_LEFT', payload: data})
}

export const updateTimelineData = (type, data) => (dispatch) => {
    dispatch({type: type, payload: data})
}

export const updateImageLayers = (type, id, url) => (dispatch) => {
    let obj = {
        type: type,
        id: id,
        url: url
    }
    dispatch({type: type, payload: obj})
}