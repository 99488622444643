import React, {useEffect, useState} from "react";
import axios from "axios";
import defaultVid from "../../images/aulbm.png";
import {addMetaVerseVideo} from "../../actions/editorAction";
import {useDispatch, useSelector} from "react-redux";
import {baseURL} from "../../global/global";
import {faPlay, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {updateBackground} from "../../actions/timelineAction";
import InfiniteScroll from "react-infinite-scroll-component";
import VideoPopupModal from "./VideoPopupModal";

const SearchMetaVerseVideo = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const [loader, setLoader] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);
    const [videos, setVideos] = useState([]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        fetchMetaVideos();
    }

    const fetchMetaVideos = () => {
        axios({
            method: 'POST',
            url: `${baseURL}load-library-three-sixty-youtube`,
            data: {keyword: keyword, page_number: page ,type: 'video'},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setVideos(res.data.data.youtube)
            }
            setLoader(false);
        }).catch(error=>{
            setLoader(false);
        })
    }

    const handleBgVideo = (url, poster) => {
        dispatch(addMetaVerseVideo(url, poster))
        dispatch(updateBackground(url, poster))
    }

    const handlePlayVideo = (e, url) => {
        e.stopPropagation();

        setShowModal(true);
        setVideoUrl(url)
    }

    useEffect(()=>{
        fetchMetaVideos();

        return () => {
            setVideos([]);
        }
    },[])

    return(
        <>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="mv-search-box">
                        <form method="post" onSubmit={(e)=>handleFormSubmit(e)} className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-search" /></span>
                            </div>
                            <input onChange={(e)=>setKeyword(e.target.value)} type="text" className="form-control" placeholder="Search" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="search-load-box mt-4 test2">
                <div className="row scrollSt" id="cameraFootageTab" style={{ overflowX: "hidden" }}>
                <InfiniteScroll
                    dataLength={videos.length} //This is important field to render the next data}
                    // next={() => searchVideo(search.page_number + 1)}
                    hasMore={true}
                    scrollableTarget="cameraFootageTab"
                    className="row"
                    >
                    {
                        videos.length > 0 ?
                            videos.map((item,index)=>(
                                <div className="colmn col-6 cursor-pointer" key={index} onClick={()=>handleBgVideo(
                                    item.hasOwnProperty('type') ? item.url : item.link,
                                    item.hasOwnProperty('type') ? item.thumbnail : `https://img.youtube.com/vi/${item.link.split('v=')[1]}/mqdefault.jpg`
                                )}>
                                    <div className="search-load d-flex justify-content-center align-items-center test4">
                                    <div className="vid-dur"><span>{item?.duration} s</span></div>
                                        
                                        {
                                            item.hasOwnProperty('type') ?
                                                <img src={item.thumbnail} alt="360 Degree VR Videos" width="100%" />
                                            : <img src={`https://img.youtube.com/vi/${item.link.split('v=')[1]}/mqdefault.jpg`} alt="360 Degree VR Videos" width="100%" />

                                        }
                                        <div className="overlay__media__wrapper">
                                            <FontAwesomeIcon color={`#00c780`} icon={faPlay} onClick={(e)=>handlePlayVideo(e, item.hasOwnProperty('type') ? item.url : item.link)} />
                                            {/* <button onClick={(e)=>handlePlayVideo(e, item.hasOwnProperty('type') ? item.url : item.link)}>Preview</button> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                        : ''
                    }

                    {
                        loader ?
                            <div className="col-md-12">
                                <h6 className="text-center" style={{fontSize: "30px"}}><FontAwesomeIcon icon={faSpinner} /></h6>
                            </div>
                            : ''
                    }
                    </InfiniteScroll>
                </div>
            </div>

            <VideoPopupModal showModal={showModal} setShowModal={setShowModal} videoUrl={videoUrl} />
        </>
    )
}

export default React.memo(SearchMetaVerseVideo);