import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import AudioFiles from "./AudioFiles";
import soundIcon from "../../images/SOUND WAVE.png";
import loaderGif from "../../images/loading.gif";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const SearchAudio = ({ currentUrl, setCurrentUrl, selectedAudio, setSelectedAudio }) => {

    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [audioData, setAudioData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [error, setError] = useState(false);

    const fetchAudio = (page = 1) => {
        setLoader(true);

        axios({
            method: 'POST',
            url: `${baseURL}load-library-audio`,
            data: JSON.stringify({ page_number: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setAudioData(audioData.concat(res.data.data));
                setPageCount(page);
            } else {
                setError(res.data.message);
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    useEffect(() => {
        fetchAudio(pageCount);

        return () => {
            setAudioData([]);
        }
    }, [])

    return (
        <>
            <div className="scroll-wrapper lightwavy-wrapper">
                <div className="row m-0 scrollSt" id="scrollableAudio" style={{ overflowX: "hidden" }}>
                    <InfiniteScroll
                        dataLength={audioData.length} //This is important field to render the next data
                        next={() => fetchAudio(pageCount + 1)}
                        hasMore={true}
                        scrollableTarget="scrollableAudio"
                        className="row"
                    >
                        {
                            audioData.length > 0 ?
                                audioData.map((audio, index) => (
                                    <AudioFiles
                                        type="audio"
                                        icon={soundIcon}
                                        title={audio.title}
                                        url={audio.url}
                                        currentUrl={currentUrl}
                                        setCurrentUrl={setCurrentUrl}
                                        selectedAudio={selectedAudio}
                                        setSelectedAudio={setSelectedAudio}
                                        key={index}
                                    />
                                ))
                                : ''
                        }
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h6 className="text-center" style={{fontSize: "30px"}}><FontAwesomeIcon icon={faSpinner} /></h6>
                                </div>
                                : ''
                        }
                        {
                            error ?
                                <div className="col-md-12">
                                    <h6 className="text-center">{error}</h6>
                                </div>
                                : ''
                        }
                    </InfiniteScroll>
                    <img src={loaderGif} className="loader-gif d-none"/>
                </div>
            </div>
        </>
    )
}

export default React.memo(SearchAudio)