import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../Header";

import './css/account-styles.css';
import {useDispatch, useSelector} from "react-redux";
import {changeProfile, updateName, updatePassword} from "../../actions/authAction";
import swal from "sweetalert";
import Alert from "../Alert";
import Footer from "../Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";



const Profile = () => {

    const dispatch = useDispatch();
    const brandName = useSelector(state => state.rebrand.data);
    const auth = useSelector(state => state.auth);
    const user = auth.user;

    const [profileStatus, setProfileStatus] = useState(false);
    const [membership, setMembership] = useState('commercial');
    const [name, setName] = useState('');
    const [nameLoader, setNameLoader] = useState(false);

    const [password, setPassword] = useState({
        password: '',
        confirm_password: '',
        new_password: ''
    });
    const [passLoader, setPassLoader] = useState(false);

    const handlePassInput = (e) => {
        setPassword({...password, [e.target.name]: e.target.value});
    }


    const onImageChange = (e) => {
        if(e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {

            if(e.target.files[0].size < 5000000) {
                e.preventDefault();
                setProfileStatus(true);

                const form = new FormData();
                form.append('user_id', user.id);
                form.append('file', e.target.files[0]);

                dispatch(changeProfile(form, setProfileStatus));
            }else{
                swal("Oops!","Max allowed size 5MB");
            }
        }else{
            swal("Oops!","You have Selected Invalid File Type");
        }
    }


    const handleNameSubmit = (e) => {
        e.preventDefault();
        setNameLoader(true);
        let json = {name: name};

        dispatch(updateName(json, setNameLoader));
    }

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        setPassLoader(true);

        dispatch(updatePassword(password, setPassLoader));
    }

    /* For Check Membership Levels */
    useEffect(()=>{
        if(auth.user!==null){

            let memLevels = auth.user.membership.split('__');
            setMembership(memLevels[memLevels.length-1]);

        }
    },[auth.user])


    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'VirtualReel'} | Profile </title>
            </Helmet>
            <Alert/>
            <div className="account-page-body">
                <main className="account-page-main">

                    <Header/>

                    <section id="profile-sec" className="inner-page-profile banner">
                        <div className="custom-wrapper">
                            <div className="custom-content">
                                {/* <div className="profile user-profile">
                                    <div className="img-wrapper">
                                        <div className="md-txt f-600 upload-file" htmlFor="upload-file">
                                            <img className="mx-auto img-fluid" src={auth.user!==null? auth.user.image:''} width="100px" />
                                            <div className="custom-file-upload">
                                                 <input onChange={(e)=>onImageChange(e)} type="file" id="upload-file" hidden/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-wrapper"> 
                                        <p className="h5 f-600 text-center">{auth.user!==null? auth.user.name:''}</p>
                                        <div className="text-center">
                                            <label className="md-txt f-600">Subcript here</label>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="user-details d-flex justify-content-center align-items-center flex-column mb-5">
                                    <div className="user-img">
                                        <img className="mx-auto img-fluid" src={auth.user!==null? auth.user.image:''} width="100px" />
                                        {/* <div className={`${profileStatus === false ? 'hide-social' : ''} my-account-loader`}>
                                            <i className="fa fa-spinner fa-spin mr-2" style={{fontSize: "30px"}}/>
                                        </div> */}
                                        {/* <div className="custom-file">
                                            <input type="file" onChange={(e)=>onImageChange(e)}
                                                className="custom-file-input" accept="image/*"
                                            />
                                        </div> */}
                                        {
                                            profileStatus ?
                                                <div className="modal-content-loader">
                                                    <h6 className="text-center" style={{fontSize: "20px"}}><FontAwesomeIcon icon={faSpinner} /></h6>
                                                </div>
                                            : ''
                                        }
                                        <div className="upload-icon custom-file">
                                            <FontAwesomeIcon icon={faCloudUploadAlt}/>
                                            <input type="file" onChange={(e)=>onImageChange(e)}
                                                className="custom-file-input" accept="image/*"
                                            />
                                        </div>
                                    </div>
                                    <div className="text-wrapper">
                                        <p className="h5 f-600 text-center">{auth.user!==null? auth.user.name:''}</p>
                                        <div className="text-center">
                                            <label className="md-txt f-600">{membership}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="account-content">
                                    <div className="row account-form">
                                        <div className="col-lg-12">
                                            <div className="title text-center mx-auto">
                                                <h5>Personal Information </h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="info-form">
                                                <form method="POST" onSubmit={(e)=>handleNameSubmit(e)}>
                                                    <div className="row justify-content-between mx-0">
                                                        <div className="col-md-6 col-sm-12 col-12">
                                                            <p className="fs-12 f-300">Profile Name</p>
                                                            <input type="text" name="first-name" className="form-control form-inputs"
                                                                   placeholder="First Name" defaultValue={auth.user!==null? auth.user.name:''}
                                                                   onChange={(e)=>setName(e.target.value)}
                                                            />
                                                        </div>

                                                        <div className="col-lg-6 col-12 mail">
                                                            <p className="fs-12 f-300">Your Email</p>
                                                            <input readOnly type="email" name="email" className="form-control form-inputs"
                                                                   placeholder="Enter Your Email"
                                                                   value={auth.user!==null? auth.user.email:''} />
                                                        </div>
                                                        <div className="col-lg-12 p-0">
                                                            <button disabled={nameLoader} type="submit" className="btn-block submit-form md-txt">
                                                                {nameLoader?<FontAwesomeIcon icon={faSpinner} />:''} Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row account-form">
                                        <div className="col-lg-12">
                                            <div className="title text-center mx-auto">
                                                <h5>Reset Password</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="pass-form">
                                                <form method="POST" onSubmit={(e)=>handlePasswordSubmit(e)}>
                                                    <div className="row justify-content-between mx-0">
                                                        <div className="col-12" style={{maxWidth: "100%"}}>
                                                            <p className="fs-12 f-300">Current Password</p>
                                                            <input required type="password" name="password"
                                                                   className="form-control form-inputs"
                                                                   placeholder="***************"
                                                                   onChange={(e)=>handlePassInput(e)}
                                                            />
                                                        </div>

                                                        <div className="col-12">
                                                            <p className="fs-12 f-300">New Password</p>
                                                            <input required type="password" name="new_password"
                                                                   className="form-control form-inputs"
                                                                   placeholder="***************"
                                                                   onChange={(e)=>handlePassInput(e)}
                                                            />
                                                        </div>

                                                        <div className="col-lg-6 col-12">
                                                            <p className="fs-12 f-300">Confirm Password</p>
                                                            <input required type="password" name="confirm_password"
                                                                   className="form-control form-inputs"
                                                                   placeholder="***************"
                                                                   onChange={(e)=>handlePassInput(e)}
                                                            />
                                                        </div>


                                                        <div className="col-lg-12 p-0">
                                                            <button type="submit" className="btn-block submit-form md-txt cursor-pointer">
                                                                {passLoader?<FontAwesomeIcon icon={faSpinner} />:''} Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer/>
                </main>
            </div>
        </>
    )
}

export default Profile