import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {baseURL, imageData} from "../../global/global";
import {addImageLayer, updateCampTwoMedia, updateTextStyle} from "../../actions/editorAction";
import swal from "sweetalert";
import InfiniteScroll from "react-infinite-scroll-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import randomstring from "randomstring";
import {addTimelineData} from "../../actions/timelineAction";

const SearchCharEmoji = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const imgData = useSelector(state => state.currentLayer.data.images_data);
    const campType = useSelector(state => state.currentLayer.data.campaign_type);
    const textData = useSelector(state => state.currentLayer.data.texts_data);

    const selectedText = textData.filter(text => text.is_selected === true);

    const [limit, setLimit] = useState(1)
    const [loader, setLoader] = useState();
    const [error, setError] = useState(false);

    const [imagesData, setImagesData] = useState([]);
    const [search, setSearch] = useState({
        keyword: 'car',
        type: 'emoji',
        page_number: 1
    });

    const onInputChange = (e) => {
        setError(false);
        setSearch({ ...search, keyword: e.target.value });

        setImagesData([]);
    }

    const searchImage = (page = 1) => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}load-library-images`,
            data: JSON.stringify({ keyword: search.keyword, type: search.type, page_number: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setError(false);

                let arr = [...imagesData];
                setImagesData(arr.concat(res.data.data));
            } else {
                setError(res.data.message);
            }

            setSearch({ ...search, page_number: page });

            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setError('Getting internal server error');
        })
    }


    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);

        searchImage();
    }

    const processBackground = (data, id) => {

        dispatch(updateTextStyle('UPDATE_TIMELINE_SELECTED_LAYER', ''));

        let newImg = new Image();
        newImg.src = data;

        imageData.id = id;
        imageData.src = data;
        imageData.width = newImg.width;
        imageData.height = newImg.height;
        imageData.scaleX = 0.2;
        imageData.scaleY = 0.2;
        delete imageData.video_src;
        delete imageData.background_slide_type;

        dispatch(addTimelineData(imageData));
    }


    const handleImageLayer = (type, url) => {

        if(campType === 'campaign_two'){
            let repImage = {
                path: url,
                type: type,
                bgcolor: ''
            }

            if(selectedText.length > 0){
                repImage.id = selectedText[0].id;
                dispatch(updateCampTwoMedia('UPDATE_CAMP_TWO_MEDIA',repImage));
            }
        }else{

            if (imgData.length < limit) {

                dispatch(updateTextStyle('UPDATE_SELECTED_IMAGE_LAYER', ''));
                let id = randomstring.generate({length: 10, charset: 'numeric'});

                let imageJson = {
                    id: id,
                    size: "1920x1080",
                    startTime: 0,
                    endTime: 10,
                    type: type,
                    path: url,
                    poster: url,
                    position: [0, 0],
                    scale: [1, 1],
                    opacity: 1,
                    is_selected: true
                }

                dispatch(addImageLayer(imageJson));
                processBackground(url, id);
            } else {
                swal(`You can add ${limit} layer for this campaign`)
            }
        }
    }


    useEffect(() => {
        searchImage();

        if (campType === 'campaign_three') {
            setLimit(1)
        } else {
            setLimit(100)
        }

        return () => {
            setImagesData([]);
        }
    }, [])


    return (
        <>
            {/*<div className="row mt-2">*/}
            {/*    <div className="col-md-12">*/}
            {/*        <div className="media-search-box">*/}
            {/*            <form method="post" onSubmit={(e) => onFormSubmit(e)} className="input-group">*/}
            {/*                <div className="input-group-prepend">*/}
            {/*                    <button type="submit" className="input-group-text"><i className="fas fa-search" /></button>*/}
            {/*                </div>*/}
            {/*                <input type="text" name="keyword" onChange={(e) => onInputChange(e)} className="form-control" placeholder="Search" />*/}
            {/*            </form>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="search-load-box mt-4 test7">
                <div className="row scrollSt" id="scrollableEmoji" style={{ overflowX: "hidden" }}>
                
                    <InfiniteScroll
                    dataLength={imagesData.length} //This is important field to render the next data}
                    next={() => searchImage(search.page_number + 1)}
                    hasMore={true}
                    scrollableTarget="scrollableEmoji"
                    className="row"
                    >
                        {
                            imagesData.length > 0 ?
                                imagesData.map((item, index) => (
                                    <div className="colmn col-4 cursor-pointer" key={index} onClick={() => handleImageLayer('image', item.local_url)}>
                                        <div className="search-load d-flex justify-content-center align-items-center test2">
                                            <img src={item?.local_url} alt="image" width="100%" />
                                        </div>
                                    </div>
                                ))
                                : ''
                        }
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h6 className="text-center" style={{fontSize: "30px"}}><FontAwesomeIcon icon={faSpinner} /></h6>
                                </div>
                                : ''
                        }
                        {
                            error ?
                                <div className="col-md-12">
                                    <p className="text-center">{error}</p>
                                </div>
                                : ''
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>
    )
}

export default React.memo(SearchCharEmoji);