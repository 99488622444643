const initialState = {
    data: {
        user_id: '',
        project_name: '',
        project_id: '',
        type: '',
        spain_time: '',
        campaign_type: '',
        template_id: '',
        texts_data: [],
        bg_file: [{
            startTime: 0,
            endTime: 10,
            path: '',
            poster: false
        }],
        intro_video: [],
        outro_video: [],
        images_data: [],
        audio_url: '',
        bg_audio_url: '',
        duration: 0
    }
}


export default function (state= initialState, action){
    const { type, payload } = action;

    switch (type) {

        case 'ADD_CAMPAIGN_DATA':
            return {
                ...state,
                data: payload
            }

        case 'ADD_TEXT_LAYER':
            let arr = [...state.data.texts_data, payload]
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: arr
                }
            }

        case 'UPDATE_IMAGE_COLOR':
            let arrImg = [...state.data.images_data, payload]
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: arrImg
                }
            }

        case 'DELETE_TEXT_LAYER':
            let txt_data = state.data.texts_data.filter(item => item.id != payload)
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: txt_data
                }
            }

        case 'DELETE_IMAGE_LAYER':
            let img_data = state.data.images_data.filter(item => item.id != payload)
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: img_data
                }
            }

        case 'UPDATE_CAMP_TWO_MEDIA':
            let campMedia = state.data.images_data.map((item) => {
                return item.id==payload.id?{...item, path: payload.path, type: payload.type, bgcolor: payload.bgcolor, size: payload?.size}
                    :{...item, path: item.path, type: item.type, bgcolor: item.bgcolor};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: campMedia
                }
            }


        case 'ADD_SELECTED_LAYER':
            let newData = state.data.texts_data.map((item) => {
                return item.id===payload?{...item, is_selected: true}:{...item, is_selected: false};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: newData
                }
            }

        case 'UPDATE_SELECTED_LAYER':
            let layerData = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, is_selected: false}:{...item, is_selected: false};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: layerData
                }
            }

        case 'UPDATE_SELECTED_IMAGE_LAYER':
            let layerImageData = state.data.images_data.map((item) => {
                return item.is_selected===true?{...item, is_selected: false}:{...item, is_selected: false};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: layerImageData
                }
            }

        case 'UPDATE_IMAGE_BY_ID':
            let layerIdData = state.data.images_data.map((item) => {
                return item.id==payload?{...item, is_selected: true}:{...item, is_selected: false};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: layerIdData
                }
            }

        case 'UPDATE_FONT_FAMILY':
            let fontData = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, fontFamily: payload}:{...item, fontFamily: item.fontFamily};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontData
                }
            }

        case 'UPDATE_IMAGE_OPACITY':
            let imageOp = state.data.images_data.map((item) => {
                return item.is_selected===true?{...item, opacity: payload}:{...item, opacity: item.opacity};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: imageOp
                }
            }

        case 'UPDATE_FONT_TEXT':
            let fontText = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, text: payload}:{...item, text: item.text};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontText
                }
            }

        case 'UPDATE_EDITOR_SCALE':
            let scaleArr = state.data.images_data.map((item) => {
                return item.id==payload.id?{...item, scale: [payload.scaleX, payload.scaleY], position: [payload.left, payload.top]}:{...item, scale: item.scale, position: item.position};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: scaleArr
                }
            }

        case 'UPDATE_FONT_SIZE_SCALE':
            let fontScale = state.data.texts_data.map((item) => {
                return item.id==payload.id?{...item,
                    fontSize: payload.fontSize, position: [payload.left, payload.top]}:
                    {...item, fontSize: item.fontSize, position: item.position};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontScale
                }
            }

        case 'UPDATE_FONT_SIZE':
            let fontSize = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, fontSize: payload}:{...item, fontSize: item.fontSize};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontSize
                }
            }

        case 'UPDATE_FONT_BOLD':
            let fontBold = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, bold: payload}:{...item, bold: item.bold};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontBold
                }
            }

        case 'UPDATE_FONT_ITALIC':
            let fontItalic = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, italic: payload}:{...item, italic: item.italic};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontItalic
                }
            }

        case 'UPDATE_FONT_UPPERCASE':
            let fontUpper = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, uppercase: payload}:{...item, uppercase: item.uppercase};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontUpper
                }
            }

        case 'UPDATE_FONT_LOWERCASE':
            let fontLower = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, lowercase: payload}:{...item, lowercase: item.lowercase};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontLower
                }
            }

        case 'UPDATE_FONT_UNDERLINE':
            let fontUnderline = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, underline: payload}:{...item, underline: item.underline};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontUnderline
                }
            }

        case 'UPDATE_FONT_STRIKE':
            let fontStrike = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, strike: payload}:{...item, strike: item.strike};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontStrike
                }
            }

        case 'UPDATE_FONT_ALIGN':
            let fontAlign = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, fontAlign: payload}:{...item, fontAlign: item.fontAlign};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontAlign
                }
            }

        case 'UPDATE_FONT_LINE_HEIGHT':
            let fontLine = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, lineHeight: payload}:{...item, lineHeight: item.lineHeight};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontLine
                }
            }


        case 'UPDATE_FONT_COLOR':
            let fontColor = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, color: payload}:{...item, color: item.color};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontColor
                }
            }

        case 'UPDATE_FONT_BG_COLOR':
            let fontBgColor = state.data.texts_data.map((item) => {
                return item.is_selected===true?{...item, bgColor: payload}:{...item, bgColor: item.bgColor};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: fontBgColor
                }
            }

        case 'UPDATE_TEXT_POSITION':
            let textPos = state.data.texts_data.map((item) => {
                return item.id==payload.id?{...item, position: [payload.left, payload.top]}:{...item, position: item.position};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    texts_data: textPos
                }
            }

        case 'UPDATE_IMAGE_POSITION':
            let imagePos = state.data.images_data.map((item) => {
                return item.id==payload.id?{...item, position: [payload.left, payload.top]}:{...item, position: item.position};
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: imagePos
                }
            }

        case 'ADD_IMAGE_LAYER':
            let imageLayer = [...state.data.images_data, payload]
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: imageLayer
                }
            }

        case 'REPLACE_IMAGE_LAYER':
            let replaceLayer = [payload]
            return {
                ...state,
                data: {
                    ...state.data,
                    images_data: replaceLayer
                }
            }

        case 'ADD_AUDIO':
            return {
                ...state,
                data: {
                    ...state.data,
                    audio_url: payload
                }
            }

        case 'UPDATE_BACKGROUND_AUDIO':
            return {
                ...state,
                data: {
                    ...state.data,
                    backroundground_audio: payload
                }
            }

        case 'UPDATE_FOREGROUND_AUDIO':
            return {
                ...state,
                data: {
                    ...state.data,
                    forground_audio: payload
                }
            }

        case 'ADD_INTRO':
            return {
                ...state,
                data: {
                    ...state.data,
                    intro_video: payload
                }
            }

        case 'ADD_OUTRO':
            return {
                ...state,
                data: {
                    ...state.data,
                    outro_video: payload
                }
            }

        case 'REMOVE_INTRO':
            return {
                ...state,
                data: {
                    ...state.data,
                    intro_video: []
                }
            }

        case 'REMOVE_OUTRO':
            return {
                ...state,
                data: {
                    ...state.data,
                    outro_video: []
                }
            }

        case 'ADD_TTS_AUDIO':
            return {
                ...state,
                data: {
                    ...state.data,
                    bg_audio_url: payload
                }
            }

        case 'ADD_BG_VIDEO':
            let bgVideo = [...state.data.bg_file];
            console.log(payload,payload)
            bgVideo[0].path = payload.url;
            bgVideo[0].poster = payload.poster;
            return {
                ...state,
                data: {
                    ...state.data,
                    bg_file: bgVideo
                }
            }

        case 'ADD_DURATION':
            return {
                ...state,
                data: {
                    ...state.data,
                    duration: payload
                }
            }

        case 'ADD_SLIDE_DURATION':
            return {
                ...state,
                data: {
                    ...state.data,
                    spain_time: payload
                }
            }

        default:
            return state

    }
}