import React from "react";
import SearchCharEmoji from "./SearchCharEmoji";
import SearchCharacters from "./SearchCharacters";

const CharacterLibrary = () => {
    return(
        <>
            <div className="media-part-sec">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="media-box-wrraper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="media-menu-box">
                                        <ul className="nav d-flex justify-content-between align-items-center py-1" id="pills-tab" role="tablist">
                                            <li className="nav-item" style={{flex: '50%'}}>
                                                <a className="nav-link active" id="pills-emoji-tab" data-toggle="pill" href="#pills-emoji" role="tab" aria-controls="pills-emoji" aria-selected="true">Emoji</a>
                                            </li>
                                            <li className="nav-item" style={{flex: '50%'}}>
                                                <a className="nav-link" id="pills-3dChar-tab" data-toggle="pill" href="#pills-3dChar" role="tab" aria-controls="pills-3dChar" aria-selected="false">3D Characters</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-emoji" role="tabpanel" aria-labelledby="pills-emoji-tab">
                                                <SearchCharEmoji/>
                                            </div>

                                            <div className="tab-pane fade" id="pills-3dChar" role="tabpanel" aria-labelledby="pills-3dChar-tab">
                                                <SearchCharacters/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(CharacterLibrary);