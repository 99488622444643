import React, {useEffect, useState} from "react";
import axios from "axios";
import {baseURL} from "../../global/global";
import swal from "sweetalert";
import {useDispatch, useSelector} from "react-redux";
import {addIntroVideo, removeIntroVideo} from "../../actions/editorAction";

import defaultVid from '../../images/sec-2-vid.png';
import HoverVideoPlayer from "react-hover-video-player";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SweetAlert from "react-bootstrap-sweetalert";
import {setAlert} from "../../actions/alert";

const IntroOutro = () => {

    let allowedExt = ['video/mp4'];

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const introData = useSelector(state => state.currentLayer.data.intro_video);
    const outroData = useSelector(state => state.currentLayer.data.outro_video);

    const [loader, setLoader] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [modelType, setModelType] = useState('intro');
    const [uploadType, setUploadType] = useState('intro');
    const [percent, setPercent] = useState(0)

    const [intro, setIntro] = useState([]);
    const [outro, setOutro] = useState([]);

    const [deleteId, setDeleteId] = useState({
        id: '',
        type: '',
        user_id: ''
    });
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const handleDelete = (e, id, type) => {
        e.stopPropagation();

        setDeleteId({...deleteId, id: id, type: type, user_id: auth.user.id});
        setShowDelete(true);
    }

    const onConfirm = () => {
        setShowDelete(true);
        setButton('Deleting...');

        axios({
            method: "POST",
            url: `${baseURL}delete-media`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchUserLibrary();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const handleFileUpload = (e, type) => {

        setUploadType(type);
        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 100000000;

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('upload_type', type);
                    formData.append('user_id', auth.user?.id);
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}file-upload`, formData, options).then(res => {
                        if (res.data.status === true) {
                            fetchUserLibrary();
                            addIntroOutro(res.data.data.url, type, res.data.data.thumbnail)
                        }
                        setLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for video 100MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    const addIntroOutro = (file, type, poster='') => {
        setShowModel(false);
        let tempData = [{
            startTime: 0,
            endTime: 0,
            path: file,
            poster: poster
        }]

        dispatch(addIntroVideo(`ADD_${type.toUpperCase()}`, tempData))
    }

    const removeIntroOutro = (type) => {
        if(type==='outro'){
            dispatch(removeIntroVideo(`REMOVE_${type.toUpperCase()}`))
        }else if(type === 'intro'){
            dispatch(removeIntroVideo(`REMOVE_${type.toUpperCase()}`))
        }
    }

    const handleShowModel = (type) => {
        setModelType(type);
        setShowModel(true);
    }

    const fetchUserLibrary = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-user-assets`,
            data: {user_id: ''},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setIntro(res.data.data.intro);
                setOutro(res.data.data.outro);
            }
        }).catch(err => {

        })
    }

    useEffect(()=>{
        fetchUserLibrary();
    },[])

    return(
        <>
            <div className="in-out-part-sec pt-4">
                <div className="row m-0 justify-content-between">
                    <div className="col-md-12 mx-auto p-0">
                        <div className="in-out-box-wrapper">
                            <div className="row align-items-stretch m-0 justify-content-between">
                                <div className="colmn col-12">
                                    <div className="in-out-upload-part in-out-logo">
                                        <h6 className="pb-2">Intro</h6>
                                        <div className="in-out-upload-box">
                                            <h6><span className="mr-1"><i className="fas fa-cloud-upload-alt" /></span> Upload Your Intro</h6>
                                            <div className="custom-file">
                                                <input disabled={loader} onChange={(e)=>handleFileUpload(e, 'intro')} type="file" className="custom-file-input" />
                                            </div>
                                        </div>
                                        <div className="in-out-remove-logo mt-3">
                                            <div className="row m-0">
                                                <div className="col-7 p-0 d-flex ">
                                                    <div className="media-upload-box">
                                                        <p className="cursor-pointer mt-1" style={{fontSize: "12px"}} onClick={()=>handleShowModel('intro')} >
                                                            <span className="mr-1"><i className="fas fa-cloud-upload-alt" /></span>  Choose from your library
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0 d-flex align-items-center">
                                                    <div className="remove-logo-switch-btn ">
                                                        <div className="form-check form-switch p-0">
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Remove Intro</label>
                                                            <input onChange={()=>removeIntroOutro('intro')} className="form-check-input" type="checkbox" disabled={introData.length>0?false:true} checked={introData.length>0?false:true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                loader && uploadType==='intro' ?
                                                    <div className="progress mt-4">
                                                        <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#00a5b8" }} aria-valuenow={`${percent}`}
                                                             aria-valuemin="0" aria-valuemax="100">{percent>5?percent+'%':''}
                                                        </div>
                                                    </div>
                                                    : ''
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12 mx-auto">
                        <div className="in-out-box-wrapper">
                            <div className="row align-items-stretch m-0 justify-content-between">
                                <div className="colmn col-12">
                                    <div className="in-out-upload-part in-out-watermark">
                                         <h6 className="pb-2">Outro</h6>
                                        <div className="in-out-upload-box">
                                            <h6><span className="mr-1"><i className="fas fa-cloud-upload-alt" /></span> Upload Your Outro</h6>
                                            <div className="custom-file">
                                                <input disabled={loader} onChange={(e)=>handleFileUpload(e, 'outro')} type="file" className="custom-file-input" id="inputGroupFile01" />
                                            </div>
                                        </div>
                                        <div className="in-out-remove-logo mt-3">
                                            <div className="row">
                                                <div className="col-7 d-flex ">
                                                    <div className="media-upload-box">
                                                        <p className="cursor-pointer mt-1" style={{fontSize: "12px"}} onClick={()=>handleShowModel('outro')}>
                                                            <span className="mr-1"><i className="fas fa-cloud-upload-alt" /></span> Choose from your library
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-5 d-flex align-items-center">
                                                    <div className="remove-logo-switch-btn ">
                                                        <div className="form-check form-switch p-0">
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Remove Outro</label>
                                                            <input onChange={()=>removeIntroOutro('outro')} className="form-check-input" type="checkbox" disabled={outroData.length>0?false:true} checked={outroData.length>0?false:true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                loader && uploadType==='outro' ?
                                                    <div className="progress mt-4">
                                                        <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#00a5b8" }} aria-valuenow={`${percent}`}
                                                             aria-valuemin="0" aria-valuemax="100">{percent>5?percent+'%':''}
                                                        </div>
                                                    </div>
                                                    : ''
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal fade demo-modal ${showModel?'show-drop show':''}`} id="demoModal" tabIndex="-1" role="dialog"
                 aria-labelledby="demoModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="h5 modal-title f-300" id="demoModalLabel">SELECT {modelType.toUpperCase()}</p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>setShowModel(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="container modal-body modal-body-scroll">
                            <div className="row demo-vid-wrapper">
                                {
                                    modelType === 'intro' ?
                                        intro.length > 0 ?
                                            intro.map((item,index)=>(
                                                <div className="col-md-4 mb-4" key={index}>
                                                    <div className="wrap-intro">
                                                        <h6 className="text-center">{item.name}</h6>
                                                        <HoverVideoPlayer
                                                            videoSrc={item.video_url}
                                                            pausedOverlay={
                                                                <img src={item?.poster} alt={`outro`} width="100%" />
                                                            }
                                                            loadingOverlay={
                                                                <div className="loader-center"><FontAwesomeIcon icon={faSpinner} /></div>
                                                            }
                                                            className="video"
                                                        />
                                                        <button onClick={()=>addIntroOutro(item.video_url, 'intro', item.poster)} className="form-control mt-1">Use</button>
                                                        <div className="vid-dur cursor-pointer" onClick={(e)=>handleDelete(e, item.id, 'intro')}>
                                                            <div className="delete-icon icon">
                                                            <svg viewBox="0 0 448 512">
                                                                <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                                            </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            : <h5 style={{width: "100%"}} className="text-center">No Intro Uploaded</h5>
                                    :
                                        outro.length > 0 ?
                                            outro.map((item,index)=>(
                                                <div className="col-md-4" key={index}>
                                                    <div className="wrap-intro">
                                                        <h6 className="text-center">{item.name}</h6>
                                                        <HoverVideoPlayer
                                                            videoSrc={item.video_url}
                                                            pausedOverlay={
                                                                <img src={item?.poster} alt={`outro`} width="100%" />
                                                            }
                                                            loadingOverlay={
                                                                <div className="loader-center"><FontAwesomeIcon icon={faSpinner} /></div>
                                                            }
                                                            className="video"
                                                        />
                                                        <button onClick={()=>addIntroOutro(item.video_url, 'outro',item.poster)} className="form-control mt-1">Use</button>
                                                        <div className="vid-dur cursor-pointer" onClick={(e)=>handleDelete(e, item.id, 'outro')}>
                                                            <div className="delete-icon icon">
                                                            <svg viewBox="0 0 448 512">
                                                                <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                                            </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        :  <h5 style={{width: "100%"}} className="text-center">No Outro Uploaded</h5>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={()=>onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>
        </>
    )
}

export default React.memo(IntroOutro);