import React from "react";
import {Link} from "react-router-dom";

const TrainingTitle = ({supportData, id, name}) => {

    console.log(supportData);

    const currentArt = supportData.filter((article)=> article.id === id);
    console.log(currentArt);

    return(
        <>
            <div className="sidebar">
                <div className="mb-2 support-data">
                    <Link to="/help-and-support" className="support-link">Support </Link>
                    /
                    <Link className={`${name ? '' : 'active-help'} support-link`} to={`training?id=${currentArt[0].id}`}> {currentArt[0].name} </Link>
                    {
                        name ? <> / <span className="active-help">{name.title}</span></> : ''
                    }

                </div>
                <div className="sidebar-title">
                    <p className="h4 f-600">POPULAR TOPICS</p>
                </div>
                <div className="topics">
                    <div className="topic-sub">
                        <ul>
                            {
                                supportData.length > 0 ?
                                    supportData.map((item,index)=>(
                                        <li className={`fs-18 ${item.id===id?'active-article':''}`} key={index}>
                                            <Link style={{color: "#fff"}} to={`/training?id=${item?.id}`}>{item?.name}</Link>
                                        </li>
                                    ))
                                : ''
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}


export default React.memo(TrainingTitle);