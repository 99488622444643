import React, { useEffect, useState } from "react";
import axios from "axios";
import {baseURL, imageData} from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {addImageLayer, replaceImageLayer, updateCampTwoMedia, updateTextStyle} from "../../actions/editorAction";
import swal from "sweetalert";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import randomstring from "randomstring";
import {addTimelineData} from "../../actions/timelineAction";

const SearchMediaImage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const imgData = useSelector(state => state.currentLayer.data.images_data);
    const campType = useSelector(state => state.currentLayer.data.campaign_type);
    const textData = useSelector(state => state.currentLayer.data.texts_data);

    const selectedText = textData.filter(text => text.is_selected === true);

    const [limit, setLimit] = useState(1)
    const [loader, setLoader] = useState();
    const [error, setError] = useState(false);

    const [imagesData, setImagesData] = useState([]);
    const [search, setSearch] = useState({
        keyword: 'nature',
        type: 'image',
        page_number: 1
    });

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });

        setImagesData([]);
    }

    const searchImage = (page = 1) => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}load-library-images`,
            data: JSON.stringify({ keyword: search.keyword, type: search.type, page_number: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setError(false);

                let arr = [...imagesData];
                setImagesData(arr.concat(res.data.data));
            } else {
                setError(res.data.message);
            }

            setSearch({ ...search, page_number: page });

            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setError('Getting internal server error');
        })
    }

    const processBackground = (data, id) => {

        dispatch(updateTextStyle('UPDATE_TIMELINE_SELECTED_LAYER', ''));

        let newImg = new Image();
        newImg.src = data;

        imageData.id = id;
        imageData.width = newImg.width;
        imageData.height = newImg.height;
        imageData.src = data;

        delete imageData.video_src;
        delete imageData.hover_video;
        delete imageData.background_slide_type;

        dispatch(addTimelineData(imageData))
    }


    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);

        searchImage();
    }


    const handleImageLayer = (type, item) => {

        if(campType === 'campaign_two'){

            let repImage = {
                path: item.url,
                type: 'image',
                bgcolor: ''
            }

            if(selectedText.length > 0){
                repImage.id = selectedText[0].id;
                dispatch(updateCampTwoMedia('UPDATE_CAMP_TWO_MEDIA',repImage));
            }

        }else{
            dispatch(updateTextStyle('UPDATE_SELECTED_IMAGE_LAYER', ''));
            let id = randomstring.generate({length: 10, charset: 'numeric'});

            if (imgData.length < limit) {
                let imageJson = {
                    id: id,
                    size: "1920x1080",
                    startTime: 0,
                    endTime: 10,
                    type: type,
                    path: item.url,
                    poster: item.thumbnail,
                    position: [0, 0],
                    scale: [1, 1],
                    opacity: 1,
                    is_selected: true
                }

                dispatch(addImageLayer(imageJson));
                processBackground(item.thumbnail, id);
            } else {

                let imageJson = {
                    id: id,
                    size: "1920x1080",
                    startTime: 0,
                    endTime: 10,
                    type: type,
                    path: item.url,
                    poster: item.thumbnail,
                    position: [0, 0],
                    scale: [1, 1],
                    opacity: 1,
                    is_selected: true
                }

                if (campType === 'campaign_three') {
                    dispatch(replaceImageLayer(imageJson));
                } else {
                    dispatch(addImageLayer(imageJson));
                    processBackground(item.thumbnail, id);
                }
            }
        }
    }


    useEffect(() => {
        searchImage();

        if (campType === 'campaign_three') {
            setLimit(1)
        } else {
            setLimit(100)
        }

        return () => {
            setImagesData([]);
        }
    }, [])


    return (
        <>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="media-search-box">
                        <form method="post" onSubmit={(e) => onFormSubmit(e)} className="input-group">
                            <div className="input-group-prepend">
                                <button type="submit" className="input-group-text"><i className="fas fa-search" /></button>
                            </div>
                            <input type="text" name="keyword" onChange={(e) => onInputChange(e)} className="form-control" placeholder="Search" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="search-load-box mt-4 test4">
                <div className="row scrollSt" id="scrollableImage" style={{ overflowX: "hidden" }}>
                    <InfiniteScroll
                        dataLength={imagesData.length} //This is important field to render the next data
                        next={() => searchImage(search.page_number + 1)}
                        hasMore={true}
                        scrollableTarget="scrollableImage"
                        className="row"
                    >
                        {
                            imagesData.length > 0 ?
                                imagesData.map((item, index) => (
                                    <div className="colmn col-6 cursor-pointer" key={index} onClick={() => handleImageLayer('image', item)}>
                                        <div className="search-load d-flex justify-content-center align-items-center test3">
                                        {/*<div className="vid-dur"><span>1:30</span></div>*/}
                                            <img src={item?.thumbnail} alt="image" />
                                        </div>
                                    </div>
                                ))
                                : ''
                        }
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h6 className="text-center" style={{fontSize: "30px"}}><FontAwesomeIcon icon={faSpinner} /></h6>
                                </div>
                                : ''
                        }
                        {
                            error ?
                                <div className="col-md-12">
                                    <h6 className="text-center">{error}</h6>
                                </div>
                                : ''
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>
    )
}

export default React.memo(SearchMediaImage);