import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";

const PrivacyEmail = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const email = auth.user!==null?auth.user.email:'';

    const [userEmail, setUserEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        axios({
            method: 'POST',
            url: `${baseURL}update-email`,
            data: {email: userEmail},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(res.data.message, "success"));
                dispatch({type:'CHANGE_USER_EMAIL',payload: userEmail});
            }else{
                dispatch(setAlert(res.data.message, "danger"));
            }
            setUserEmail('');
        }).catch(err=>{
            console.log(err);
            dispatch(setAlert('Something went wrong, please try again', "danger"));
        })
    }

    return(
        <>
            <Alert/>
            <section id="change-email-sec" className="change-email-sec">
                <div className="custom-wrapper container">
                    <div className="content-wrapper">
                        <p className="h4 mb-4">Change Email</p>
                        <div className="form-wrapper">
                            <form onSubmit={(e)=>handleSubmit(e)} method="POST" className="change-email-form">
                                <div className="row mx-0 justify-content-between">
                                    <div className="form-group colmn colmn-1 mb-0">
                                        <p className="fs-15 f-300" style={{color: '#C8D8E9'}}>Current Email Address</p>
                                        <input type="email" name="email" className="current-email" value={email} readOnly/>
                                    </div>
                                    <div className="form-group colmn colmn-2 mb-0">
                                        <p className="fs-15 f-300" style={{color: '#C8D8E9'}}>New Email Address</p>
                                        <input onChange={(e)=>setUserEmail(e.target.value)} type="email" value={userEmail} name="email" className="confirm-email" placeholder="new@email.com" />
                                    </div>
                                </div>
                                <button className="change-email-submit md-txt f-500">
                                    Update
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(PrivacyEmail)