const initialState = {
    title: '',
    script: '',
    regular_data: false,
    current_load: 'bg_layer'
}

export default function (state= initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case 'ADD_SCRIPT_DATA':
            return{
                ...state,
                title: payload.title,
                script: payload.script
            }

        case 'ADD_REGULAR_DATA':
            return {
                ...state,
                regular_data: payload
            }

        case 'CHANGE_CURRENT_LOAD':
            return {
                ...state,
                current_load: payload
            }

        default:
            return state

    }
}