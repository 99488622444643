import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import WebFont from "webfontloader";
import randomstring from "randomstring"
import {addTextLayer, updateCampTwoMedia, updateTextData, updateTextStyle} from "../../actions/editorAction";
import {SketchPicker} from "react-color";
import {baseURL, fontJson, layerTextData} from "../../global/global";
import {addTimelineData} from "../../actions/timelineAction";

const TextStyles = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const campType = useSelector(state => state.currentLayer.data.campaign_type);
    const textData = useSelector(state => state.currentLayer.data.texts_data);
    const imagesData = useSelector(state => state.currentLayer.data.images_data);

    const selectedText = textData.find(({is_selected}) => is_selected === true);

    const [fonts, setFonts]= useState([]);
    const [googleFonts, setGoogleFonts] = useState([]);
    const [activeImage, setActiveImage] = useState([]);

    const [showTextColor, setShowTextColor] = useState(false);
    const [showTextBg, setShowTextBg] = useState(false);
    const [showSlideBg, setShowSlideBg] = useState(false);

    const fetchFonts = () => {
        axios({
            method: 'POST',
            url: `https://adminserver.reelapps.io/fetch-fonts`,
            data: {},
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            if (res.data.status === true) {
                setFonts(res.data.data);

                let arr = res.data.data.map((item, index) => {
                    return (
                        item.value
                    )
                })

                setGoogleFonts(arr);
            }
        }).catch(err => {

        })
    }

    const handleTextChange = (e) => {
        dispatch(updateTextData('UPDATE_FONT_TEXT', e.target.value));
    }

    const handleAddText = () => {
        dispatch(updateTextStyle('UPDATE_SELECTED_LAYER', ''));
        dispatch(updateTextStyle('UPDATE_TIMELINE_SELECTED_LAYER', ''));

        let id = parseInt(randomstring.generate({length: 10, charset: 'numeric'}));

        fontJson.id = id;
        dispatch(addTextLayer(fontJson));

        layerTextData.id = id;
        dispatch(addTimelineData(layerTextData));
    }

    const handleFontSelect = (e) => {
        dispatch(updateTextStyle('UPDATE_FONT_FAMILY', e.target.value));
        dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_FAMILY', e.target.value));
    }

    const handleFontSize = (e) => {
        dispatch(updateTextStyle('UPDATE_FONT_SIZE', e.target.value));
        dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_SIZE', e.target.value));
    }

    const handleTextStyle = (type) => {
        if (type === 'bold') {
            if (selectedText.bold === true) {
                dispatch(updateTextStyle('UPDATE_FONT_BOLD', false));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_BOLD', false));
            } else {
                dispatch(updateTextStyle('UPDATE_FONT_BOLD', true));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_BOLD', true));
            }
        } else if (type === 'italic') {
            if (selectedText.italic === true) {
                dispatch(updateTextStyle('UPDATE_FONT_ITALIC', false));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_ITALIC', false));
            } else {
                dispatch(updateTextStyle('UPDATE_FONT_ITALIC', true));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_ITALIC', true));
            }
        } else if (type === 'uppercase') {
            if (selectedText.uppercase === true) {
                dispatch(updateTextStyle('UPDATE_FONT_UPPERCASE', false));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_UPPERCASE', false));
            } else {
                dispatch(updateTextStyle('UPDATE_FONT_UPPERCASE', true));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_UPPERCASE', true));
            }
        } else if (type === 'lowercase') {
            if (selectedText.lowercase === true) {
                dispatch(updateTextStyle('UPDATE_FONT_LOWERCASE', false));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_LOWERCASE', false));
            } else {
                dispatch(updateTextStyle('UPDATE_FONT_LOWERCASE', true));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_LOWERCASE', true));
            }
        } else if (type === 'underline') {
            if (selectedText.underline === true) {
                dispatch(updateTextStyle('UPDATE_FONT_UNDERLINE', false));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_UNDERLINE', false));
            } else {
                dispatch(updateTextStyle('UPDATE_FONT_UNDERLINE', true));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_UNDERLINE', true));
            }
        } else if (type === 'strike') {
            if (selectedText.strike === true) {
                dispatch(updateTextStyle('UPDATE_FONT_STRIKE', false));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_STRIKE', false));
            } else {
                dispatch(updateTextStyle('UPDATE_FONT_STRIKE', true));
                dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_STRIKE', true));
            }
        }
    }

    const handleTextAlign = (value) => {
        dispatch(updateTextStyle('UPDATE_FONT_ALIGN', value));
        dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_ALIGN', value));
    }

    const handleLineHeight = (e) => {
        dispatch(updateTextStyle('UPDATE_FONT_LINE_HEIGHT', e.target.value));
        dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_LINE_HEIGHT', e.target.value));
    }

    const handleTextColor = (value) => {
        dispatch(updateTextStyle('UPDATE_FONT_COLOR', value));
        dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_COLOR', value));
    }

    const handleTextBg = (value) => {

        dispatch(updateTextStyle('UPDATE_FONT_BG_COLOR', value));
        dispatch(updateTextStyle('UPDATE_TIMELINE_FONT_BG_COLOR', value));

    }

    const handleSlideBg = (value) => {
        if(selectedText!==undefined) {
            let repImage = {
                path: '',
                type: 'color',
                bgcolor: value
            }

            repImage.id = selectedText.id;
            dispatch(updateCampTwoMedia('UPDATE_CAMP_TWO_MEDIA', repImage));
        }
    }


    useEffect(()=>{
        fetchFonts();

    },[])

    useEffect(()=>{

        if(selectedText!==undefined) {
            let activeImage = imagesData.filter(image => image.id == selectedText.id);

            setActiveImage(activeImage);
        }
    },[selectedText, JSON.stringify(imagesData)])

    useEffect(() => {
        if (googleFonts.length > 0) {
            WebFont.load({
                google: {
                    families: googleFonts
                }
            });
        }
    }, [googleFonts])

    return(
        <>
            <div className="editor-text-customization">
                {
                    campType !== 'campaign_two' ?
                        <div className="mb-4">
                            <span className="label text-white col-12 p-0" style={{fontFamily: 'Poppins', fontWeight: 500}}>Add Text</span>
                            <div className="mv-upload-box mt-3">
                                <button type="button" className="form-control f-500"><span className="mr-1 f-500"><i className="fas fa-cloud-upload-alt" /></span> Add Text</button>
                                <div className="custom-file">
                                    <input type="button" className="custom-file-input" onClick={()=>handleAddText()} />
                                </div>
                            </div>
                        </div>
                    :
                        <div className="mb-4">
                            <span className="label text-white col-12 p-0" style={{fontFamily: 'Poppins', fontWeight: 500}}>Add Text</span>
                            <div className="mv-upload-box mv-upload-box-nx mt-3">
                                <div className="custom-filenn">
                                    <textarea className="editor-input-dark text-white form-control edit-input-height" cols="6"
                                              value={selectedText!==undefined ? selectedText.text : '' }
                                              onChange={(e)=>handleTextChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                }

                <div className="editor-wrapper editor-text-wrapper">
                    <div className="editor-font-family-size-wrapper d-flex flex-wrap justify-content-between align-content-center">
                        <span className="label text-white col-12 p-0 mb-3" style={{fontFamily: 'Poppins', fontWeight: 500}}>Font Style</span>
                        <div className="editor-text-family">
                            <select onChange={(e)=>handleFontSelect(e)} id="sel-font" className="form-control editor-input-dark edit-input-height mb-2" name="f-family">
                                {
                                    fonts.length > 0 ?
                                        fonts.slice(0,400).map((item,index)=>(
                                            <option
                                                selected={selectedText!==undefined? selectedText.fontFamily===item.name?true:false :false}
                                                style={{fontFamily: item.name}} value={item.name}
                                                key={index}
                                            >
                                                {item.name}
                                            </option>
                                        ))
                                    : ''
                                }
                            </select>
                            <span className="icon">
                            <svg width="12px" height="12px" viewBox="0 0 12 12">
                              <svg id="ico-arrow-down" viewBox="0 0 30 30">
                                <path fill="currentColor" opacity="0.83" d=" M 0.00 7.80 C 0.47 7.75 1.40 7.67 1.86 7.63 C 6.05 11.69 10.11 15.88 14.28 19.96 C 14.64 19.96 15.38 19.95 15.74 19.95 C 19.87 15.90 23.89 11.73 28.06 7.72 C 28.55 7.69 29.52 7.64 30.00 7.62 L 30.00 9.25 C 25.96 13.48 21.76 17.56 17.60 21.67 C 16.20 23.11 13.87 23.14 12.44 21.72 C 8.26 17.61 4.10 13.48 0.00 9.29 L 0.00 7.80 Z" />
                              </svg>
                            </svg>
                          </span>
                        </div>
                        {
                            campType === 'campaign_two' ?
                                <div className="editor-text-weight-size d-flex flex-xl-nowrap flex-wrap">
                                    <div className="input-wrapper w-100">
                                        <input id="f-size" type="number" onChange={(e)=>handleFontSize(e)} className="form-control editor-input-dark edit-input-height"
                                               name="f-size" value={selectedText!==undefined? selectedText.fontSize :18} />
                                    </div>
                                </div>
                            : ''
                        }
                    </div>
                    <div className="editor-text-style editor-text-alignment mt-2 d-flex flex-xl-nowrap flex-wrap justify-content-between">
                        <button onClick={()=>handleTextStyle('bold')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.bold===true?'active':'' :''}`} data-toggle="tooltip" title="Text Bold"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-bold" x="0px" y="0px" viewBox="0 0 100 100" xmlSpace="preserve"><path fill="currentColor" d="M0,0c33.3,0,66.7-0.1,100,0.1c0,8.2-0.4,16.4-1.6,24.5c-1.2-0.3-3.5-0.8-4.6-1.1c-1-3.1-2-6.3-3-9.4 c-8.2-2-16.7-2.2-24.1,2.4c0,25.2,0.2,50.4-0.2,75.6c4.7,1.4,11.8,0.9,12.3,7.4c-19,0.9-38.1,0.3-57.2,0.3 c-0.5-7.1,7.1-6.2,11.8-7.6c0.1-25.2-0.1-50.4,0-75.7c-7.3-4.4-15.7-4.4-23.8-2.6C8.3,18.6,6,22.9,2.1,26C0,17.6,0,8.8,0,0z" /></svg></svg></button>
                        <button onClick={()=>handleTextStyle('italic')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.italic===true?'active':'' :''}`} data-toggle="tooltip" title="Text Italic"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-italic" x="0px" y="0px" viewBox="0 0 100 109.1" xmlSpace="preserve"><path fill="currentColor" d="M5.6,0C37,0,68.5,0,100,0c-2.3,8.8-5.1,17.5-8.4,26c-1.5,0.1-4.5,0.2-6,0.2c0.1-3.6,0.1-7.2,0.2-10.8 C76,12.9,65.9,13.3,57,18.2c-7.5,27.6-15.4,55.1-23,82.7c4.1,0.7,8.1,1.3,12.2,1.9c-0.4,1.4-1.3,4.3-1.8,5.7 c-14.7,1.3-29.4,0.3-44.1,0.3c0.5-1.5,1.6-4.6,2.1-6.2c4.8-1.2,13,0.9,14.1-5.9C23.4,70.4,30.9,44.2,38.3,18 c-10.1-6.8-29.6-6.9-32.5,7.8C4.3,26,1.4,26.4,0,26.6C0.6,17.5,2.9,8.7,5.6,0z" /></svg></svg></button>
                        <button onClick={()=>handleTextStyle('uppercase')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.uppercase===true?'active':'' :''}`} data-toggle="tooltip" title="Text Uppercase"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-upper" x="0px" y="0px" viewBox="0 0 100 54.5" xmlSpace="preserve"><path fill="currentColor" d="M0,0c15.2,0,30.3-0.1,45.5,0c0,4.4-0.2,8.7-0.7,13.1c-0.7,0-2.1,0-2.8,0c-0.4-1.7-0.8-3.4-1.2-5.1 C36.2,6.5,31.4,6.5,27.3,9c0,13.7,0.2,27.5-0.1,41.2c2.5,0.9,6.5,0.5,6.7,4.1c-7.4,0.3-14.7,0.3-22.1,0.1c-0.1-3.8,4-3.3,6.6-4.2 c-0.1-13.7-0.1-27.4,0-41.1C14.2,6.5,9.4,6.5,4.8,7.7C4.4,9.4,4,11.1,3.5,12.8c-0.7,0.1-2.2,0.3-2.9,0.4C0.2,8.9,0,4.4,0,0z" /><path fill="currentColor" d="M54.5,0C69.7,0,84.9,0,100,0c0,4.4-0.2,8.7-0.7,13.1c-0.7,0-2.1,0-2.8,0c-0.4-1.7-0.8-3.4-1.2-5.1C90.8,6.5,86,6.5,81.8,9 c0,13.7,0.2,27.5-0.1,41.2c2.5,0.9,6.5,0.5,6.7,4.1c-7.3,0.3-14.7,0.3-22,0.1c-0.4-3.9,3.6-3.3,6.3-3.9c0.4-13.8,0-27.7,0.2-41.5 c-4.1-2.5-8.9-2.5-13.4-1.3c-0.5,1.7-0.9,3.4-1.3,5.1c-0.7,0.1-2.2,0.3-2.9,0.4C54.7,8.9,54.5,4.4,54.5,0z" /></svg></svg></button>
                        <button onClick={()=>handleTextStyle('lowercase')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.lowercase===true?'active':'' :''}`} data-toggle="tooltip" title="Text Lowercase"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-lower" x="0px" y="0px" viewBox="0 0 133.4 100" xmlSpace="preserve"><path fill="currentColor" d="M0,0c27.8,0,55.6-0.1,83.4,0.1c-0.1,8-0.4,16-1.3,24c-1.2,0-3.8-0.1-5.1-0.1c-0.8-3.2-1.5-6.3-2.3-9.5 C66.4,12,57.6,11.9,50,16.5c0.1,25.2,0.3,50.4-0.2,75.5c4.7,1.4,11.9,0.9,12.3,7.5c-13.5,0.6-27,0.5-40.5,0.1 c-0.1-6.9,7.3-6.2,12.1-7.7c-0.3-25.2-0.2-50.3-0.1-75.5C26,12,17.3,12,8.9,14.1c-0.8,3.1-1.6,6.3-2.5,9.4 c-1.3,0.2-3.9,0.6-5.2,0.9C0.3,16.3,0,8.2,0,0z" /><path fill="currentColor" d="M91.7,50c13.9,0,27.8-0.1,41.7,0.1c0,3.8,0,7.6,0,11.4c-4.8-4.2-10.6-6.7-16.7-3.7c0.2,11.2,0,22.4,0.1,33.6 c-0.8,4.4,3.9,6.1,6.5,8.6c-7.1,0-14.2,0-21.3,0c2.5-2.5,7.3-4.2,6.5-8.6c0.1-11.2-0.2-22.4,0.1-33.6c-6.2-2.9-12.1-0.5-16.7,3.9 C91.7,57.8,91.7,53.9,91.7,50z" /></svg></svg></button>
                        {/*<button onClick={()=>handleTextStyle('underline')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.underline===true?'active':'' :''}`} data-toggle="tooltip" title="Text Underline"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-underline" x="0px" y="0px" viewBox="0 0 83.4 100" xmlSpace="preserve"><path fill="currentColor" d="M0,0.1c27.8,0,55.6-0.2,83.4,0.1c0,8.1-0.4,16.1-1.5,24.1c-1.2-0.1-3.5-0.4-4.7-0.5c-0.9-3.1-1.9-6.2-2.8-9.4 C66.2,12,57.4,11.9,50,16.6c0.1,19.6,0.1,39.2-0.2,58.8c4.7,1.6,12.1,0.8,12.2,7.5c-13.4,0.6-26.8,0.5-40.3,0.2 c-0.7-7.1,6.8-6.1,11.5-7.4c0.5-19.7-0.1-39.4,0.2-59.1C26,12,17.5,12.1,9.3,14c-1,3.1-2,6.2-3,9.3c-1.2,0.3-3.6,1-4.8,1.4 C0.2,16.6,0,8.3,0,0.1z" /><path fill="currentColor" d="M0.1,91.7c27.8-0.1,55.5-0.1,83.3,0c0,2.1,0,6.2,0,8.3c-27.8,0.1-55.5,0.1-83.3,0C0.1,97.9,0.1,93.7,0.1,91.7z" /></svg></svg></button>*/}
                        {/*<button onClick={()=>handleTextStyle('strike')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.strike===true?'active':'' :''}`} data-toggle="tooltip" title="Text Strikethrough"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-strike" x="0px" y="0px" viewBox="0 0 83.3 100" xmlSpace="preserve"><path fill="currentColor" d="M-0.1,0.1c27.8,0,55.6-0.2,83.4,0.1c-0.1,8-0.5,16-1.4,24c-1.2-0.1-3.7-0.2-4.9-0.3c-0.8-3.2-1.7-6.3-2.6-9.5 c-8.2-2.4-17-2.5-24.5,2.2c0.2,11.2,0,22.3,0.1,33.4C44.4,50,38.9,50,33.3,50c0.1-11.1-0.1-22.3,0.1-33.4C25.9,12,17.3,12.1,9,14.1 c-0.9,3.1-1.9,6.3-2.7,9.4c-1.2,0.3-3.7,0.8-5,1.1C0.2,16.4-0.1,8.2-0.1,0.1z" /><path fill="currentColor" d="M0,58.3c27.8,0,55.5-0.1,83.3,0c0,2.1,0,6.2,0,8.3c-11.1-0.1-22.2,0.1-33.3,0c0,8.5-0.2,17-0.4,25.4 c4.7,1.5,12.1,0.8,12.2,7.5c-13.4,0.6-26.9,0.5-40.3,0.2c-0.7-7.2,6.7-6.1,11.5-7.3c0.4-8.6,0.3-17.2,0.2-25.8 c-11.1,0.1-22.2,0-33.3,0C0,64.6,0,60.4,0,58.3z" /></svg></svg></button>*/}
                    </div>
                    <div className="editor-text-align editor-text-alignment mt-2 d-flex flex-xl-nowrap flex-wrap justify-content-between">
                        <button onClick={()=>handleTextAlign('left')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.fontAlign==='left'?'active':'' :''}`} data-toggle="tooltip" title="Align Text Left"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-left" x="0px" y="0px" viewBox="0 0 157.3 180" xmlSpace="preserve"><rect fill="currentColor" x="-0.1" width="157.4" height="20.3" /><rect fill="currentColor" x="-0.1" y="39.8" width="77.5" height="20.7" /><rect fill="currentColor" x="-0.1" y="79.8" width="157.3" height="20.4" /><rect fill="currentColor" y="119.6" width="77.4" height="20.7" /><rect fill="currentColor" x="-0.1" y="159.6" width="157.4" height="20.4" /></svg></svg></button>
                        <button onClick={()=>handleTextAlign('center')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.fontAlign==='center'?'active':'' :''}`} data-toggle="tooltip" title="Align Text Center"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-center" x="0px" y="0px" viewBox="0 0 157.3 180" xmlSpace="preserve"><rect fill="currentColor" width="157.3" height="20.4" /><polygon fill="currentColor" points="39.9,39.8 117.4,39.8 117.4,60.5 39.9,60.5  " /><rect fill="currentColor" y="79.8" width="157.3" height="20.5" /><polygon fill="currentColor" points="39.9,119.6 117.4,119.6 117.4,140.3 39.9,140.3  " /><rect fill="currentColor" y="159.6" width="157.3" height="20.4" /></svg></svg></button>
                        <button onClick={()=>handleTextAlign('right')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.fontAlign==='right'?'active':'' :''}`} data-toggle="tooltip" title="Align Text Right"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-right" x="0px" y="0px" viewBox="0 0 159.6 180" xmlSpace="preserve"><rect fill="currentColor" width="159.5" height="20.3" /><rect fill="currentColor" x={82} y="39.8" width="77.6" height="20.6" /><rect fill="currentColor" x="2.1" y="79.8" width="157.4" height="20.1" /><rect fill="currentColor" x="82.2" y="119.6" width="77.4" height="20.6" /><rect fill="currentColor" y="159.7" width="159.6" height="20.3" /></svg></svg></button>
                        <button onClick={()=>handleTextAlign('justify')} className={`txt-edit-btn text-white editor-input-dark ml-0 ${selectedText!==undefined? selectedText.fontAlign==='justify'?'active':'' :''}`} data-toggle="tooltip" title="Text Align Justify"><svg width="22px" height="12px" viewBox="0 0 22 12"><svg id="ico-text-justify" x="0px" y="0px" viewBox="0 0 161.1 179.9" xmlSpace="preserve"><g><rect fill="currentColor" width="161.1" height="20.3" /><rect fill="currentColor" y={40} width="161.1" height="20.3" /><rect fill="currentColor" y="79.9" width="161.1" height="20.3" /><rect fill="currentColor" x="-0.1" y="119.7" width="161.2" height="20.3" /><rect fill="currentColor" x="-0.1" y="159.7" width="161.2" height="20.3" /></g></svg></svg></button>
                        <div className="input-group-prepend">
                          <span className="icon">
                            <svg width="14px" height="15px" viewBox="0 0 14 15">
                              <svg id="ico-lineheight" x="0px" y="0px" viewBox="0 0 139.9 150" xmlSpace="preserve">
                                <path fill="currentColor" d="M83.2,0c4.4,0,8.7,0,13.1,0c7.8,20,15.6,39.9,23.4,59.9c6.6,0,13.3,0,19.9,0c0.1,2.5,0.2,7.6,0.3,10.2 c-33.4,0-66.7,0-100.1,0c0-2.6,0-7.6,0-10.2c6.7,0,13.4,0,20.1,0C67.6,39.9,75.4,20,83.2,0 M88.7,14c-3.8,8.1-6.5,16.7-9.6,25.1 c7.1,0,14.1,0,21.2,0c-3.1-8.5-5.8-17-9.6-25.2C90.3,14,89.2,14,88.7,14 M76.1,48.4c-1.5,3.9-3,7.8-4.4,11.6c12.1,0,24.1,0,36.1,0 c-1.4-3.9-2.9-7.8-4.4-11.6C94.3,48.4,85.2,48.4,76.1,48.4z" />
                                <path fill="currentColor" d="M21.5,70.1c1.7,0,4.9,0,6.6,0c5.6,8.2,13.9,13.8,21.6,19.8c-6.6,0.1-13.2,0.2-19.8,0.2c0,9.9,0,19.8,0,29.7 c6.6,0.1,13.2,0.1,19.8,0.2c-8.3,6.8-16.7,13.4-25.2,19.9C16.4,133.2,8.2,126.6,0,120c6.6,0,13.2-0.1,19.8-0.1c0-9.9,0-19.9,0-29.8 C13.2,90,6.7,90,0.1,89.9C7.8,83.9,16,78.3,21.5,70.1z" />
                                <path fill="currentColor" d="M83.2,79.9c4.4,0,8.7,0,13.1,0c7.8,20,15.6,39.9,23.4,59.9c6.7,0.1,13.4,0.2,20.1,0.2c0,2.5,0,7.5,0,10 c-33.4,0-66.7,0-100,0c0-2.5,0-7.6,0-10.1c6.7,0,13.4-0.1,20.1-0.1C67.6,119.8,75.4,99.8,83.2,79.9 M79.1,119c7.1,0,14.1,0,21.1,0 c-3.5-8.3-5.1-18.6-10.4-25.2C84.4,99.8,82.7,110.9,79.1,119 M76.1,128.3c-1.5,3.9-2.9,7.7-4.4,11.6c12,0,24,0,36.1,0 c-1.5-3.9-2.9-7.8-4.4-11.6C94.3,128.3,85.2,128.3,76.1,128.3z" />
                              </svg>
                            </svg>
                          </span>
                            <input id="t-lineheight" className="form-control editor-input-dark text-white edit-input-height"
                                   min="1"
                                   step="0.1"
                                   onChange={(e)=>handleLineHeight(e)}
                                   type="number" name="Lineheight" value={selectedText!==undefined? selectedText.lineHeight :1} />
                        </div>
                    </div>
                    {
                        textData.length === 0 ? <div title="Please add text layer" className="wrap-overlay"></div> : ''
                    }
                </div>
                <div className="editor-wrapper editor-color-wrapper editor-font-color mt-4">
                    <span className="label text-white" style={{fontFamily: 'Poppins', fontWeight: 500}}>Font Color</span>
                    <div className="editor-text-color justify-content-between d-flex flex-wrap mt-3">
                        <div className="input-color text-center mb-2">
                            <button onClick={(e) => setShowTextColor(true)} id="t-color-btn" style={{background: `${selectedText!==undefined? selectedText.color :'#ffffff'}`}}> </button>
                            {showTextColor ? <div>
                                <div className="swatch-cover" onClick={(e) => setShowTextColor(false)}/>
                                <SketchPicker disableAlpha={true} color={selectedText!==undefined? selectedText.color :'#ffffff'} onChange={(e) => handleTextColor(e.hex)}/>
                            </div> : null}
                            <input id="tColor-hex" className="editor-input-dark text-white form-control edit-input-height" value={selectedText!==undefined? selectedText.color :'#ffffff'} />
                            <label>HEX</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center">
                            <input readOnly id="tColor-red" className="form-control editor-input-dark edit-input-height" type="text" name="Red" defaultValue={255} />
                            <label htmlFor="tColor-red">R</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center">
                            <input readOnly id="tColor-grn" className="form-control editor-input-dark edit-input-height" type="text" name="Green" defaultValue={255} />
                            <label htmlFor="tColor-green">G</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center mr-0">
                            <input readOnly id="tColor-blu" className="form-control editor-input-dark edit-input-height" type="text" name="Blue" defaultValue={255} />
                            <label htmlFor="tColor-blue">B</label>
                        </div>
                        <div className="swatches w-100 d-flex flex-wrap">
                            <button onClick={()=>handleTextColor('#ffffff')} className="tColor swatch" style={{backgroundColor: 'rgb(255, 255, 255)'}}> </button>
                            <button onClick={()=>handleTextColor('#e1e1e1')} className="tColor swatch" style={{backgroundColor: 'rgb(225, 225, 225)'}}> </button>
                            <button onClick={()=>handleTextColor('#d7d7d7')} className="tColor swatch" style={{backgroundColor: 'rgb(215, 215, 215)'}}> </button>
                            <button onClick={()=>handleTextColor('#cccccc')} className="tColor swatch" style={{backgroundColor: 'rgb(204, 204, 204)'}}> </button>
                            <button onClick={()=>handleTextColor('#c2c2c2')} className="tColor swatch" style={{backgroundColor: 'rgb(194, 194, 194)'}}> </button>
                            <button onClick={()=>handleTextColor('#b7b7b7')} className="tColor swatch" style={{backgroundColor: 'rgb(183, 183, 183)'}}> </button>
                            <button onClick={()=>handleTextColor('#a1a1a1')} className="tColor swatch" style={{backgroundColor: 'rgb(161, 161, 161)'}}> </button>
                            <button onClick={()=>handleTextColor('#959595')} className="tColor swatch" style={{backgroundColor: 'rgb(149, 149, 149)'}}> </button>
                            <button onClick={()=>handleTextColor('#e8c9ff')} className="tColor swatch" style={{backgroundColor: 'rgb(232, 201, 255)'}}> </button>
                            <button onClick={()=>handleTextColor('#c87eff')} className="tColor swatch" style={{backgroundColor: 'rgb(200, 126, 255)'}}> </button>
                            <button onClick={()=>handleTextColor('#f34aff')} className="tColor swatch" style={{backgroundColor: 'rgb(243, 74, 255)'}}> </button>
                            <button onClick={()=>handleTextColor('#d19df8')} className="tColor swatch" style={{backgroundColor: 'rgb(209, 157, 248)'}}> </button>
                            <button onClick={()=>handleTextColor('#9b33e9')} className="tColor swatch" style={{backgroundColor: 'rgb(155, 51, 233)'}}> </button>
                            <button onClick={()=>handleTextColor('#8133db')} className="tColor swatch" style={{backgroundColor: 'rgb(129, 51, 219)'}}> </button>
                            <button onClick={()=>handleTextColor('#7033d5')} className="tColor swatch" style={{backgroundColor: 'rgb(112, 51, 213)'}}> </button>
                            <button onClick={()=>handleTextColor('#23803f')} className="tColor swatch" style={{backgroundColor: 'rgb(35, 128, 63)'}}> </button>
                            <button onClick={()=>handleTextColor('#54c423')} className="tColor swatch" style={{backgroundColor: 'rgb(84, 196, 35)'}}> </button>
                            <button onClick={()=>handleTextColor('#606888')} className="tColor swatch" style={{backgroundColor: 'rgb(96, 104, 136)'}}> </button>
                            <button onClick={()=>handleTextColor('#7c83ac')} className="tColor swatch" style={{backgroundColor: 'rgb(124, 131, 172)'}}> </button>
                            <button onClick={()=>handleTextColor('#5b69be')} className="tColor swatch" style={{backgroundColor: 'rgb(91, 105, 190)'}}> </button>
                            <button onClick={()=>handleTextColor('#30409b')} className="tColor swatch" style={{backgroundColor: 'rgb(48, 64, 155)'}}> </button>
                            <button onClick={()=>handleTextColor('#223cdb')} className="tColor swatch" style={{backgroundColor: 'rgb(34, 60, 219)'}}> </button>
                        </div>
                    </div>
                    {
                        textData.length === 0 ? <div title="Please add text layer" className="wrap-overlay"></div> : ''
                    }
                </div>
                <div className="editor-wrapper editor-color-wrapper editor-font-color mt-4">
                    <span className="label text-white" style={{fontFamily: 'Poppins', fontWeight: 500}}>Font Background</span>
                    <div className="text-bg mb-2 justify-content-between d-flex flex-wrap mt-3">
                        <div className="input-color text-center mb-2">
                            <button id="t-bg-btn" onClick={()=>setShowTextBg(true)} style={{background: `${selectedText!==undefined? selectedText.bgColor :'#ffffff'}`}} > </button>
                            {showTextBg ? <div>
                                <div className="swatch-cover" onClick={() => setShowTextBg(false)}/>
                                <SketchPicker disableAlpha={true} color={selectedText!==undefined? selectedText.bgColor :'#ffffff'} onChange={(e) => handleTextBg(e.hex)}/>
                            </div> : null}
                            <input id="tBg-hex" className="editor-input-dark text-white form-control edit-input-height" value={selectedText!==undefined? selectedText.bgColor :'#ffffff'} />
                            <label>HEX</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center">
                            <input readOnly id="tBg-red" className="form-control editor-input-dark edit-input-height" type="text" name="Red" defaultValue={255} />
                            <label htmlFor="tBg-red">R</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center">
                            <input readOnly id="tBg-grn" className="form-control editor-input-dark edit-input-height" type="text" name="Green" defaultValue={255} />
                            <label htmlFor="tBg-green">G</label>
                        </div>
                        <div className="editor-rgb-wrapper text-center mr-0">
                            <input readOnly id="tBg-blu" className="form-control editor-input-dark edit-input-height" type="text" name="Blue" defaultValue={255} />
                            <label htmlFor="tBg-blue">B</label>
                        </div>
                        <div className="swatches w-100 d-flex flex-wrap">
                            <button onClick={()=>handleTextBg('')} className="tBg no-color" style={{backgroundColor: 'rgb(255, 255, 255)'}}> </button>
                            <button onClick={()=>handleTextBg('#e1e1e1')} className="tColor swatch" style={{backgroundColor: 'rgb(225, 225, 225)'}}> </button>
                            <button onClick={()=>handleTextBg('#d7d7d7')} className="tColor swatch" style={{backgroundColor: 'rgb(215, 215, 215)'}}> </button>
                            <button onClick={()=>handleTextBg('#cccccc')} className="tColor swatch" style={{backgroundColor: 'rgb(204, 204, 204)'}}> </button>
                            <button onClick={()=>handleTextBg('#c2c2c2')} className="tColor swatch" style={{backgroundColor: 'rgb(194, 194, 194)'}}> </button>
                            <button onClick={()=>handleTextBg('#b7b7b7')} className="tColor swatch" style={{backgroundColor: 'rgb(183, 183, 183)'}}> </button>
                            <button onClick={()=>handleTextBg('#a1a1a1')} className="tColor swatch" style={{backgroundColor: 'rgb(161, 161, 161)'}}> </button>
                            <button onClick={()=>handleTextBg('#959595')} className="tColor swatch" style={{backgroundColor: 'rgb(149, 149, 149)'}}> </button>
                            <button onClick={()=>handleTextBg('#7d7d7d')} className="tColor swatch" style={{backgroundColor: 'rgb(125, 125, 125)'}}> </button>
                            <button onClick={()=>handleTextBg('#5b5b5b')} className="tColor swatch" style={{backgroundColor: 'rgb(91, 91, 91)'}}> </button>
                            <button onClick={()=>handleTextBg('#414141')} className="tColor swatch" style={{backgroundColor: 'rgb(65, 65, 65)'}}> </button>
                            <button onClick={()=>handleTextBg('#1da9fe')} className="tColor swatch" style={{backgroundColor: 'rgb(29, 169, 254)'}}> </button>
                            <button onClick={()=>handleTextBg('#adbcda')} className="tColor swatch" style={{backgroundColor: 'rgb(173, 188, 218)'}}> </button>
                            <button onClick={()=>handleTextBg('#c4ad6a')} className="tColor swatch" style={{backgroundColor: 'rgb(196, 173, 106)'}}> </button>
                            <button onClick={()=>handleTextBg('#596a95')} className="tColor swatch" style={{backgroundColor: 'rgb(89, 106, 149)'}}> </button>
                            <button onClick={()=>handleTextBg('#4bc9e5')} className="tColor swatch" style={{backgroundColor: 'rgb(75, 201, 229)'}}> </button>
                            <button onClick={()=>handleTextBg('#f58a6d')} className="tColor swatch" style={{backgroundColor: 'rgb(245, 138, 109)'}}> </button>
                            <button onClick={()=>handleTextBg('#72c4b9')} className="tColor swatch" style={{backgroundColor: 'rgb(114, 196, 185)'}}> </button>
                            <button onClick={()=>handleTextBg('#cbd6ec')} className="tColor swatch" style={{backgroundColor: 'rgb(203, 214, 236)'}}> </button>
                            <button onClick={()=>handleTextBg('#c4927a')} className="tColor swatch" style={{backgroundColor: 'rgb(196, 146, 122)'}}> </button>
                            <button onClick={()=>handleTextBg('#fba075')} className="tColor swatch" style={{backgroundColor: 'rgb(251, 160, 117)'}}> </button>
                            <button onClick={()=>handleTextBg('#d8aa2c')} className="tColor swatch" style={{backgroundColor: 'rgb(216, 170, 44)'}}> </button>
                        </div>
                    </div>
                    {
                        textData.length === 0 ? <div title="Please add text layer" className="wrap-overlay"></div> : ''
                    }
                </div>

                {
                    campType === 'campaign_two' ?
                        <div className="editor-wrapper editor-color-wrapper editor-font-color mt-4">
                            <span className="label text-white" style={{fontFamily: 'Poppins', fontWeight: 500}}>Slide Background</span>
                            <div className="text-bg mb-2 justify-content-between d-flex flex-wrap mt-3">
                                <div className="input-color text-center mb-2">
                                    <button id="t-bg-btn" onClick={()=>setShowSlideBg(true)} style={{background: `${activeImage.length>0 && activeImage[0].type==='color' ? activeImage[0].bgcolor :'#ffffff'}`}} > </button>
                                    {showSlideBg ? <div>
                                        <div className="swatch-cover" onClick={() => setShowSlideBg(false)}/>
                                        <SketchPicker disableAlpha={true} color={activeImage.length>0 && activeImage[0].type==='color' ? activeImage[0].bgcolor :'#ffffff'} onChange={(e) => handleSlideBg(e.hex)}/>
                                    </div> : null}
                                    <input id="tBg-hex" className="editor-input-dark text-white form-control edit-input-height" value={activeImage.length>0 && activeImage[0].type==='color' ? activeImage[0].bgcolor :'#ffffff'} />
                                    <label>HEX</label>
                                </div>
                                <div className="editor-rgb-wrapper text-center">
                                    <input readOnly id="tBg-red" className="form-control editor-input-dark edit-input-height" type="text" name="Red" defaultValue={255} />
                                    <label htmlFor="tBg-red">R</label>
                                </div>
                                <div className="editor-rgb-wrapper text-center">
                                    <input readOnly id="tBg-grn" className="form-control editor-input-dark edit-input-height" type="text" name="Green" defaultValue={255} />
                                    <label htmlFor="tBg-green">G</label>
                                </div>
                                <div className="editor-rgb-wrapper text-center mr-0">
                                    <input readOnly id="tBg-blu" className="form-control editor-input-dark edit-input-height" type="text" name="Blue" defaultValue={255} />
                                    <label htmlFor="tBg-blue">B</label>
                                </div>
                                <div className="swatches w-100 d-flex flex-wrap">
                                    <button onClick={()=>handleSlideBg('')} className="tBg no-color" style={{backgroundColor: 'rgb(255, 255, 255)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#e1e1e1')} className="tColor swatch" style={{backgroundColor: 'rgb(225, 225, 225)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#d7d7d7')} className="tColor swatch" style={{backgroundColor: 'rgb(215, 215, 215)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#cccccc')} className="tColor swatch" style={{backgroundColor: 'rgb(204, 204, 204)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#c2c2c2')} className="tColor swatch" style={{backgroundColor: 'rgb(194, 194, 194)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#b7b7b7')} className="tColor swatch" style={{backgroundColor: 'rgb(183, 183, 183)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#a1a1a1')} className="tColor swatch" style={{backgroundColor: 'rgb(161, 161, 161)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#959595')} className="tColor swatch" style={{backgroundColor: 'rgb(149, 149, 149)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#7d7d7d')} className="tColor swatch" style={{backgroundColor: 'rgb(125, 125, 125)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#5b5b5b')} className="tColor swatch" style={{backgroundColor: 'rgb(91, 91, 91)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#414141')} className="tColor swatch" style={{backgroundColor: 'rgb(65, 65, 65)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#1da9fe')} className="tColor swatch" style={{backgroundColor: 'rgb(29, 169, 254)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#adbcda')} className="tColor swatch" style={{backgroundColor: 'rgb(173, 188, 218)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#c4ad6a')} className="tColor swatch" style={{backgroundColor: 'rgb(196, 173, 106)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#596a95')} className="tColor swatch" style={{backgroundColor: 'rgb(89, 106, 149)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#4bc9e5')} className="tColor swatch" style={{backgroundColor: 'rgb(75, 201, 229)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#f58a6d')} className="tColor swatch" style={{backgroundColor: 'rgb(245, 138, 109)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#72c4b9')} className="tColor swatch" style={{backgroundColor: 'rgb(114, 196, 185)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#cbd6ec')} className="tColor swatch" style={{backgroundColor: 'rgb(203, 214, 236)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#c4927a')} className="tColor swatch" style={{backgroundColor: 'rgb(196, 146, 122)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#fba075')} className="tColor swatch" style={{backgroundColor: 'rgb(251, 160, 117)'}}> </button>
                                    <button onClick={()=>handleSlideBg('#d8aa2c')} className="tColor swatch" style={{backgroundColor: 'rgb(216, 170, 44)'}}> </button>
                                </div>
                            </div>
                            {
                                textData.length === 0 ? <div title="Please add text layer" className="wrap-overlay"></div> : ''
                            }
                        </div>
                    : ''
                }
            </div>
        </>
    )
}

export default React.memo(TextStyles);