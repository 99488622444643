import React from "react";


const TrainingArticleContent = ({supportData, id}) => {
    return(
        <>
            <div className="vid-content">
                <div className="scrollable-content">
                    <div className="row" style={{display: "block"}}>

                        {
                            supportData.items.length > 0 ?
                                supportData.items.map((item,index)=>{
                                    return(
                                        item.id == id ?
                                            <>
                                                <div className="colmns colmns-1 mb-4" key={index}
                                                     dangerouslySetInnerHTML={{
                                                         __html: item.content
                                                     }}>
                                                </div>
                                            </>
                                            : ''
                                    )
                                })
                                : ''
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainingArticleContent;