import React from 'react';
import {useSelector} from "react-redux";

const PreviewIntroOutro = ({type}) => {

    const introData = useSelector(state => state.currentLayer.data.intro_video);
    const outroData = useSelector(state => state.currentLayer.data.outro_video);

    return(
        <>
            <div className="editor-preview-wrapper">
                <div className="editor-preview-box">
                    {
                        introData.length>0 && type === 'intro' ?
                            <video className="preview-def-wh" width="100%" height="100%" style={{opacity: 1}} autoPlay loop>
                                <source src={introData[0].path} type="video/mp4"/>
                            </video>
                        : ''
                    }
                    {
                        outroData.length>0 && type === 'outro' ?
                            <video className="preview-def-wh" width="100%" height="100%" style={{opacity: 1}} autoPlay loop>
                                <source src={outroData[0].path} type="video/mp4"/>
                            </video>
                            : ''
                    }
                </div>
            </div>
        </>
    )
}

export default React.memo(PreviewIntroOutro);