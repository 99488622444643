import React, {useEffect, useState} from "react";
import logo from '../images/reelApps.png';
import {useSelector} from "react-redux";

const Footer = () => {

    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);
    const getCurrentYear = () => {
        return new Date().getFullYear();
      };

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return(
        <>
            <footer id="footer" className="footer">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-4 colmn colmn-1">
                            <ul className="list d-flex">
                                <li className="fs-18"><a href="https://reelapps.io/terms.html" target="_blank">TERMS</a></li>
                                <li className="fs-18"><a href="https://reelapps.io/privacy.html" target="_blank">PRIVACY</a></li>
                                <li className="fs-18"><a href="https://support.vineasx.com/" target="_blank">SUPPORT</a></li>
                            </ul>
                        </div>

                        <div className="col-5 colmn colmn-2">
                            <p className="fs-18 copyTxt text-center">© {(new Date().getFullYear())}, All Rights Reserved to VineaSX Solutions LLC. Developed and Maintained by
                                    <a href="https://vega6.com/" target="_blank" className="ml-2">Vega6</a>
                            </p>
                        </div>

                        <div className="col-3 colmn colmn-3">
                            <div className="img-wrapper">
                                {
                                    rebrand.data !== false ?
                                        <a>
                                            <img src={rebrand.data.logo} className="img-fluid ml-auto"  width="180px" />
                                        </a> :
                                        <a href="https://reelapps.io/" target="_blank">
                                            <img src={logo} className="img-fluid ml-auto"  />
                                        </a>
                                }
                                {/*<a href="https://reelapps.io/" target="_blank"><img className="img-fluid ml-auto" src={logo} alt="Vineasx Logo"/></a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default React.memo(Footer);