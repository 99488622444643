import React from "react";
import UploadMetaVerse from "./UploadMetaVerse";
import SearchMetaVerseVideo from "./SearchMetaVerseVideo";
import SearchCharacters from "./SearchCharacters";
import SearchDimVideo from "./SearchDimVideo";

const MetaVerse = () => {
    return(
        <>
            <div className="mv-part-sec">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="mv-box-wrraper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mv-menu-box">
                                        <ul className="nav d-flex justify-content-between align-items-center py-1" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-mv-video-tab" data-toggle="pill" href="#pills-mv-video" role="tab" aria-controls="pills-mv-video" aria-selected="false">VR Videos</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-mv-images-tab" data-toggle="pill" href="#pills-mv-images" role="tab" aria-controls="pills-mv-images" aria-selected="true">Camera Footage</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-mvlibrary-tab" data-toggle="pill" href="#pills-mvlibrary" role="tab" aria-controls="pills-mvlibrary" aria-selected="false">Upload Media</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade" id="pills-mv-images" role="tabpanel" aria-labelledby="pills-mv-images-tab">
                                                <SearchMetaVerseVideo/>
                                            </div>
                                            <div className="tab-pane fade show active" id="pills-mv-video" role="tabpanel" aria-labelledby="pills-video-tab">
                                                <SearchDimVideo/>
                                            </div>
                                            <div className="tab-pane fade" id="pills-mvlibrary" role="tabpanel" aria-labelledby="pills-mvlibrary-tab">
                                                <UploadMetaVerse/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(MetaVerse);