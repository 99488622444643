import React, {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {addVideos} from "../../actions/supportAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons"
import demoImg from "../../images/help-demo.png";
import closeModalx from "../../images/closeModal.svg"

const HelpVideo = () => {

    const dispatch = useDispatch();

    const [videos, setVideos] = useState([]);
    const [loadVideos, setLoadVideos] = useState(false);

    const [vidData, setVidData] = useState({
        title: '',
        url: ''
    })
    const [showModal, setShowModal] = useState(false);

    const playVideo = (url,title) => {
        setVidData({...vidData, url: url, title: title});
        setShowModal(true);
    }

    const fetchVideos = () => {
        setLoadVideos(true);

        axios({
            method: "POST",
            url: "https://adminserver.reelapps.io/api/fetch-videos",
            data: JSON.stringify({app_id: 40}),
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                setVideos(res.data.data);
                dispatch(addVideos(res.data.data));
            }
            setLoadVideos(false);
        }).catch(err=>{
            setLoadVideos(false);
        })
    }

    useEffect(()=>{
        fetchVideos();
    },[])

    return(
        <>
            <section className="sec-2" id="sec-2">
                <div className="divider">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1928.344 69.146">
                        <path id="Path_528" data-name="Path 528" d="M0,1921.465l1298.946-56.143,629.4,56.143V1852.32H0Z"
                              transform="translate(0 -1852.32)" fill="#1f2c51"/>
                    </svg>
                </div>
                <div className="custom-wrapper">
                    <div className="custom-content">
                        <div className="title text-center mx-auto">
                            <p className="h3 f-700 alt">Popular Video Tutorials</p>
                        </div>
                        <div className="row justify-content-between">

                            {
                                videos.length > 0 ?
                                    videos.map((item,index)=>(
                                        <div className="colmn colmn-1 cursor-pointer" key={index} onClick={()=>playVideo(item.url, item.title)}>
                                            <div className="img-wrapper">
                                                <img src={demoImg} className="mx-auto img-fluid" />
                                            </div>
                                            <div className="row vid-title justify-content-between">
                                                <p className="fs-14">{item?.title}</p>
                                            </div>
                                        </div>
                                    ))
                                : ''
                            }

                        </div>

                        {
                            loadVideos ? <div style={{display: "block"}} className="row"><h6 className="text-center">Fetching Videos...</h6></div> : ''
                        }

                    </div>
                </div>
                <div className="divider">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1928.344 69.146">
                        <path id="Path_529" data-name="Path 529"
                              d="M1928.344,1852.32,517.059,1909.437,0,1852.32v69.146H1928.344Z"
                              transform="translate(0 -1852.32)" fill="#374a81"/>
                    </svg>
                </div>
            </section>

            <div className={`commonModal modal demo-modal popular-videos  ${showModal?'show-drop':''}`} id="demoModal" tabIndex="-1" role="dialog"
                 aria-labelledby="demoModal" aria-hidden="true" style={{opacity: "1"}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-video-tutorial" role="document">
                    <div className="modal-content">

                        
                            {/* <p className="h5 modal-title f-300" id="demoModalLabel">Watch <span
                                className="f-800">Virtual</span> {vidData.title}</p> */}
                            
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>setShowModal(false)}>
                                <span aria-hidden="true">
                                   <img src={closeModalx} />
                                </span>
                                {/* <span aria-hidden="true">&times;</span> */}
                            </button>
                        

                        <div className="modal-body">
                            {/* <div className="demo-vid-wrapper">
                                <iframe id="frameSrc" className="embed-responsive-item" src={vidData.url} allowFullScreen=""></iframe>
                            </div> */}
                            <div className='embed-container'>
                                <iframe src={vidData.url} frameBorder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(HelpVideo);