import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL, imageData} from "../../global/global";
import {addImageLayer, updateCampTwoMedia, updateTextStyle} from "../../actions/editorAction";
import swal from "sweetalert";
import InfiniteScroll from "react-infinite-scroll-component";
import HoverVideoPlayer from "react-hover-video-player";
import randomstring from "randomstring";
import {addTimelineData} from "../../actions/timelineAction";
import loadingGif from "../../images/loader.gif";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const SearchCharacters = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const imgData = useSelector(state => state.currentLayer.data.images_data);
    const campType = useSelector(state => state.currentLayer.data.campaign_type);
    const textData = useSelector(state => state.currentLayer.data.texts_data);

    const selectedText = textData.filter(text => text.is_selected === true);

    const [limit, setLimit] = useState(1)
    const [loader, setLoader] = useState();
    const [error, setError] = useState(false);

    const [imagesData, setImagesData] = useState([]);
    const [search, setSearch] = useState({
        keyword: 'car',
        type: 'video',
        page_number: 1
    });

    const searchImage = (page = 1) => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}load-library-character`,
            data: JSON.stringify({ keyword: search.keyword, type: search.type, page_number: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            console.log(res);
            if (res.data.status === true) {
                setError(false);

                let arr = [...imagesData];
                setImagesData(arr.concat(res.data.data));
            } else {
                setError(res.data.message);
            }

            setSearch({...search, page_number: page});

            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setError('Getting internal server error');
        })
    }

    const processBackground = (url, poster, id, hover_vid) => {

        dispatch(updateTextStyle('UPDATE_TIMELINE_SELECTED_LAYER', ''));

        imageData.id = id;
        imageData.video_src = url;
        imageData.width = 1920;
        imageData.height = 1080;
        imageData.scaleX = 0;
        imageData.scaleY = 0;
        imageData.hover_video = hover_vid

        delete imageData.src;
        delete imageData.background_slide_type;

        dispatch(addTimelineData(imageData));
    }


    const handleImageLayer = async (type, url, poster, hover_vid , index) => {

        dispatch(updateTextStyle('UPDATE_SELECTED_IMAGE_LAYER', ''));
        // let childEle = document.getElementsByClassName(`char-${index}`)[0];
        // let videoEle =  childEle.children[2];
        //
        // console.log('height', videoEle.videoHeight)
        // console.log('width', videoEle.videoWidth)

        if(campType === 'campaign_two'){

            let repImage = {
                path: url,
                type: type,
                bgcolor: '',
                size: hover_vid,
            }

            if(selectedText.length > 0){

                repImage.id = selectedText[0].id;
                dispatch(updateCampTwoMedia('UPDATE_CAMP_TWO_MEDIA',repImage));
            }
        }else{
            if(imgData.length < limit) {
                let id = randomstring.generate({length: 10, charset: 'numeric'});

                let imageJson = {
                    id: id,
                    size: "1920x1080",
                    startTime: 0,
                    endTime: 10,
                    type: type,
                    path: url,
                    poster: poster,
                    position: [0, 0],
                    scale: [1, 1],
                    opacity: 1,
                    is_selected: true
                }

                dispatch(addImageLayer(imageJson));
                processBackground(url, poster, id, hover_vid);
            }else{
                swal(`You can add ${limit} layer for this campaign`)
            }
        }
    }


    useEffect(()=>{

        searchImage();

        if(campType === 'campaign_three'){
            setLimit(1)
        }else{
            setLimit(100)
        }

        return () => {
            setImagesData([]);
        }
    },[])

    return(
        <>
            <div className="search-load-box search-load-box-altx mt-4 test8">
                <div className="scrollSt" id="scrollableChar" style={{ overflowX: "hidden" }}>
                    <InfiniteScroll
                        dataLength={imagesData.length} //This is important field to render the next data
                        next={() => searchImage(search.page_number + 1)}
                        hasMore={true}
                        scrollableTarget="scrollableChar"
                        className="row" 
                    >
                        {
                            imagesData.length > 0 ?
                                imagesData.map((item,index)=>(
                                    <div className="col-12 col-md-6 cursor-pointer mb-3" key={index} onClick={()=>handleImageLayer('video',item.url, item.thumbnail,item?.gif_file ,index)}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <HoverVideoPlayer
                                                videoSrc={item?.hover_video}
                                                pausedOverlay={
                                                    <img src={item?.thumbnail} alt={`Emoji`} width="100%" />
                                                }
                                                loadingOverlay={
                                                    <div className="loader-center"><i style={{ color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></div>
                                                }
                                                className={`char-${index}`}
                                            />
                                        </div>
                                    </div>
                                ))
                                : ''
                        }
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h6 className="text-center" style={{fontSize: "30px"}}><FontAwesomeIcon icon={faSpinner} /></h6>
                                </div>
                                : ''
                        }
                        {
                            error ?
                                <div className="col-md-12">
                                    <p className="text-center">{error}</p>
                                </div>
                                : ''
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>
    )
}

export default React.memo(SearchCharacters);