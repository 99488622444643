import { combineReducers } from "redux";
import auth from "./authReducer";
import alert from "./alertReducer";
import support from "./supportReducer";
import currentLayer from "./editorReducer";
import social from "./socialReducer";
import script from "./scriptReducer";
import rebrand from "./rebrandReducer";
import timeline from "./timelineReducer";

export default combineReducers({
    auth,
    alert,
    support,
    currentLayer,
    social,
    script,
    rebrand,
    timeline
});
