const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: localStorage.getItem("token") ? true : false,
    loading: true,
    user: null
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case 'USER_LOADED':
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
            };
        case 'LOGIN_USER':
            localStorage.setItem("token", payload.token);
            return {
                ...state,
                token: payload.token,
                isAuthenticated: true,
                loading: false,
                user: payload.user
            };

        case 'LOGOUT':
            localStorage.removeItem("token");
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                token: null,
                user: null,
            };

        case 'CHANGE_USER_IMAGE':
            return {
                ...state,
                user: {
                    ...state.user,
                    image: payload.path
                }
            }

        case 'CHANGE_USER_NAME':
            return {
                ...state,
                user:{
                    ...state.user,
                    name: payload
                }
            }

        case 'CHANGE_USER_EMAIL':
            return {
                ...state,
                user:{
                    ...state.user,
                    email: payload
                }
            }

        default:
            return state;
    }
}
