import React, {useEffect, useState} from "react";
import soundsIcon from "../../images/SOUNDS WAVE.png";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";

import swal from "sweetalert";
import AudioFiles from "./AudioFiles";
import SweetAlert from "react-bootstrap-sweetalert";

const UploadAudio = ({currentUrl, setSelectedAudio, selectedAudio, setCurrentUrl}) => {

    let allowedExt = ['audio/mpeg'];

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [percent, setPercent] = useState(0);

    const [deleteId, setDeleteId] = useState({
        id: '',
        type: '',
        user_id: ''
    });
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const handleDelete = (e, id, type) => {
        e.stopPropagation();

        setDeleteId({...deleteId, id: id, type: type, user_id: auth.user.id});
        setShowDelete(true);
    }

    const onConfirm = () => {
        setShowDelete(true);
        setButton('Deleting...');

        axios({
            method: "POST",
            url: `${baseURL}delete-media`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchUserLibrary();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const fetchUserLibrary = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-user-assets`,
            data: {user_id: ''},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setUploadedFiles(res.data.data.audio);
            }
        }).catch(err => {

        })
    }

    const onInputFile = (e) => {

        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 5000000;

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('user_id', auth.user?.id);
                    formData.append('upload_type', 'audio');
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}file-upload`, formData, options).then(res => {
                        if (res.data.status === true) {
                            dispatch(setAlert(res.data.message, 'success'));
                            fetchUserLibrary();
                        } else {
                            dispatch(setAlert(res.data.message, 'danger'));
                        }
                        setLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for MP3 5MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    useEffect(()=>{
        fetchUserLibrary();

        return () => {
            setUploadedFiles([]);
        }
    },[])

    return(
        <>
            <div className="audio-library-boxs">
                <div className="audio-upload-btn">
                    <button type="button" className="form-control f-500"><span className="mr-1"><i className="fas fa-cloud-upload-alt" /></span> Upload Audio</button>
                    <div className="custom-file">
                        <input disabled={loader} onChange={(e)=>onInputFile(e)} type="file" className="custom-file-input" />
                    </div>
                </div>
                {
                    loader ?
                        <div className="progress mt-4">
                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#00a5b8" }} aria-valuenow={`${percent}`}
                                 aria-valuemin="0" aria-valuemax="100">{percent>5?percent+'%':''}
                            </div>
                        </div>
                        : ''
                }
                <div className="upload-audio-box mt-4">
                    <div className="row m-0">

                        {
                            uploadedFiles.length > 0 ?
                                uploadedFiles.map((audio, index)=>(
                                    <AudioFiles
                                        type="upload"
                                        icon={soundsIcon}
                                        title={audio.name}
                                        url={audio.audio_url}
                                        currentUrl={currentUrl}
                                        setCurrentUrl={setCurrentUrl}
                                        selectedAudio={selectedAudio}
                                        setSelectedAudio={setSelectedAudio}
                                        id={audio.id}
                                        handleDelete={handleDelete}
                                        key={index}
                                    />
                                ))
                            : ''
                        }

                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={()=>onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>
        </>
    )
}

export default React.memo(UploadAudio);