import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import fbIcon from "../../images/logo-facebook.svg";
import ytIcon from "../../images/YT-DEFAULT.png";
import trimIcon from "../../images/logo-trim.svg";
import reelmergeIcon from "../../images/reelmerge-icon2.png";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const SocialPostModel = (props) => {

    const auth = useSelector(state => state.auth);
    const socials = useSelector(state => state.social);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState('');
    const [currSocial, setCurrSocial] = useState([]);
    const [postData, setPostData] = useState({
        title: '',
        description: '',
        video_url: props.url,
        thumbnail: props.thumbnail,
        integration_id: '',
        type: '',
        network: ''
    })

    const onInputChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    }

    const currentSocial = (social, type, icon) => {
        setCurrSocial(social);
        setSelectedIcon(icon)

        setPostData({ ...postData, type: type , integration_id: social[0].id, network: icon});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        axios({
            method: 'POST',
            url: `${baseURL}${postData.type}`,
            data: postData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'))
            } else {
                dispatch(setAlert(res.data.message, 'danger'))
            }
            props.setShowModal(false);
            setLoader(false);
        }).catch(err => {
            props.setShowModal(false);
            setLoader(false);
        })
    }

    return(
        <>
            <Alert/>
            <div className={`modal fade demo-modal publish-video-modal ${props.showModal?'show-drop':''}`} id="demoModal" tabIndex="-1" role="dialog"
                 aria-labelledby="demoModal" aria-hidden="true" style={{opacity: "1"}}>
                <div className="modal-dialog modal-dialog-centered" role="document" style={{width: "800px", margin: "50px auto 0", top: "10px"}}>
                    <div className="modal-content">

                        

                        <div className="modal-header" style={{borderBottom: '1px sold'}}>
                            <p className="h5 modal-title text-center f-300" id="demoModalLabel">Publish this VIDEO</p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>props.setShowModal(false)}>
                                <span aria-hidden="true"> 
                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="demo-vid-wrapper" style={{width: "100%"}}>

                                <div className="icon-response">
                                    {socials.facebook.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socials.facebook, 'upload-to-facebook', 'facebook')}>
                                        <div className={`wrap-icon-image cursor-pointer ${selectedIcon === 'facebook' ? 'active-push' : ''}`}>
                                            <img src={fbIcon} alt="facebook" className="img-fluid" />
                                        </div>
                                    </span> : ''}
                                    {socials.youtube.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socials.youtube, 'upload-to-youtube', 'youtube')}>
                                        <div className={`wrap-icon-image cursor-pointer ${selectedIcon === 'youtube' ? 'active-push' : ''}`}>
                                            <img src={ytIcon} alt="youtube" className="img-fluid" />
                                        </div>
                                    </span> : ''}
                                    {socials.trimreel.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socials.trimreel, 'push-to-app', 'trimreel')}>
                                        <div className={`wrap-icon-image cursor-pointer ${selectedIcon === 'trimreel' ? 'active-push' : ''}`}>
                                            <img src={trimIcon} alt="trimreel" className="img-fluid" />
                                        </div>
                                    </span> : ''}
                                    {socials.reelmerge.length > 0 ? <span className="icon-response-span" onClick={() => currentSocial(socials.reelmerge, 'push-to-app', 'reelmerge')}>
                                        <div className={`wrap-icon-image cursor-pointer ${selectedIcon === 'reelmerge' ? 'active-push' : ''}`}>
                                            <img src={reelmergeIcon} alt="reelmerge" className="img-fluid" />
                                        </div>
                                    </span> : ''}
                                </div>

                                <form className="gdpr-update-email" method="post" onSubmit={(e) => onFormSubmit(e)}>
                                    <div className="form-group">
                                        <small className="form-text text-white">Title</small>
                                        <input type="text" className="form-control" required name="title" onChange={(e) => onInputChange(e)} placeholder="Enter Title" />
                                    </div>
                                    <div className="form-group">
                                        <small className="form-text text-white">Description</small>
                                        <textarea className="form-control" required name="description"  rows="4" cols="50" onChange={(e) => onInputChange(e)} placeholder="Enter Description" />
                                    </div>
                                    <div className="form-group">
                                        <small className="form-text text-white">Account Type</small>
                                        <select value={``} className="form-control" required name="integration_id" onChange={(e) => onInputChange(e)}>
                                            <option disabled selected>Select Account</option>
                                            {
                                                currSocial.length > 0 ?
                                                    currSocial.map((item, index) => {
                                                        return (
                                                            <option key={index} style={{ backgroundColor: "#000" }} value={item.id}>{item?.username}</option>
                                                        )
                                                    })
                                                    : ''
                                            }
                                        </select>
                                    </div>
                                    <div className="text-center mt-4">
                                        <div className="d-inline-block">
                                            {/*{*/}
                                            {/*    loader ?*/}
                                            {/*    <div className="modal-content-loader">*/}
                                            {/*        <div className="lds-ripple">*/}
                                            {/*            <div></div>*/}
                                            {/*            <div></div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*    :''*/}
                                            {/*}*/}
                                        <button type="submit" className="proj-btn" disabled={loader}>
                                            {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} Publish
                                        </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(SocialPostModel);