import React, {useEffect, useState} from "react";
import dvd from "../../images/dvd.png"
import banner from "../../images/banner-bg.png"
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons"
import closeModalx from "../../images/closeModal.svg"

const DashboardHead = (props) => {

    const auth = useSelector(state => state.auth);
    const [showModal, setShowModal] = useState(false);

    const [user, setUser] = useState(undefined);
    const [url, setUrl] = useState('');

    const handleOpenPopup = () => {
        setShowModal(true);
        setUrl('https://player.vimeo.com/video/667185413');
    }

    const handleClosePopup = () => {
        setShowModal(false);
        setUrl('');
    }

    useEffect(()=>{
        if(auth.user!==null){
            setUser(auth.user);
        }
    },[auth.user])

    return(
        <>
            <section id="banner" className="banner" style={{backgroundImage: `url(${banner})`}}>
                <div className="custom-wrapper">
                    <div className="content-wrapper">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-6 col-12 colmn colmn-1 mb-lg-0 mb-4">
                                <h2 className="f-300">WELCOME TO</h2>

                                {
                                    props.brandName !== false ?
                                        <h1><span className="f-800">{props.brandName.name}</span></h1>
                                    : <h1><span className="f-800">Virtual</span>Reel</h1>
                                }


                                <div className="text-wrapper">
                                    <p className="md-txt f-300">Effortlessly create 3D Metaverse videos in a few clicks.
                                        Turn your regular videos, text, or articles into full-blown 360 Degree VR Videos in any
                                        niche.</p>
                                </div>

                                <a onClick={()=>handleOpenPopup()} className="d-flex demo-btn cursor-pointer">
                                    <div className="play-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.08" height="17.846"
                                             viewBox="0 0 16.08 17.846">
                                            <path id="Path_33" data-name="Path 33"
                                                  d="M11.02.393C9.411-.529,8.107.227,8.107,2.081V15.764c0,1.856,1.3,2.611,2.913,1.689l11.96-6.859c1.609-.923,1.609-2.419,0-3.342Z"
                                                  transform="translate(-8.107 0)" fill="#fff"/>
                                        </svg>
                                    </div>

                                    <p className="h4 f-600">Watch Now</p>
                                </a>

                            </div>

                            {
                                user!==undefined && user.is_client_account === "0" ?
                                    <div className="col-md-6 col-12 colmn colmn-2 posDiv">
                                        <div className="wrapper">
                                            <Link to={`/create-metaverse?type=one`} className="in-wrap d-flex">
                                                <div className="in-wrap-head d-flex align-items-center">
                                                    <div className="img-wrapper mr-3">
                                                        <img className="img-fluid" src={dvd} alt="meta" />
                                                    </div>

                                                    <h4 className="fs-18">Create VR Video from Scratch</h4>
                                                </div>

                                                <div className="in-wrap-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="38.746" height="38.746"
                                                         viewBox="0 0 38.746 38.746">
                                                        <path id="Path_97" data-name="Path 97"
                                                              d="M19.373,0A19.373,19.373,0,1,0,38.746,19.373,19.394,19.394,0,0,0,19.373,0Zm5.985,20.514-8.072,8.072A1.614,1.614,0,0,1,15,26.3l6.931-6.931L15,12.442a1.614,1.614,0,1,1,2.283-2.283l8.072,8.072A1.613,1.613,0,0,1,25.358,20.514Z"
                                                              fill="#fff"/>
                                                    </svg>
                                                </div>
                                            </Link>

                                            <Link to={`/script`} className="in-wrap d-flex">
                                                <div className="in-wrap-head d-flex align-items-center">
                                                    <div className="img-wrapper mr-3">
                                                        <img className="img-fluid" src={dvd} alt="meta" />
                                                    </div>

                                                    <h4 className="fs-18">Create VR Video from Text</h4>
                                                </div>

                                                <div className="in-wrap-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="38.746" height="38.746"
                                                         viewBox="0 0 38.746 38.746">
                                                        <path id="Path_97" data-name="Path 97"
                                                              d="M19.373,0A19.373,19.373,0,1,0,38.746,19.373,19.394,19.394,0,0,0,19.373,0Zm5.985,20.514-8.072,8.072A1.614,1.614,0,0,1,15,26.3l6.931-6.931L15,12.442a1.614,1.614,0,1,1,2.283-2.283l8.072,8.072A1.613,1.613,0,0,1,25.358,20.514Z"
                                                              fill="#fff"/>
                                                    </svg>
                                                </div>
                                            </Link>

                                            <Link to={`/regular-video`} className="in-wrap d-flex">
                                                <div className="in-wrap-head d-flex align-items-center">
                                                    <div className="img-wrapper mr-3">
                                                        <img className="img-fluid" src={dvd} alt="meta" />
                                                    </div>

                                                    <h4 className="fs-18">Create VR Video from Regular Video</h4>
                                                </div>

                                                <div className="in-wrap-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="38.746" height="38.746"
                                                         viewBox="0 0 38.746 38.746">
                                                        <path id="Path_97" data-name="Path 97"
                                                              d="M19.373,0A19.373,19.373,0,1,0,38.746,19.373,19.394,19.394,0,0,0,19.373,0Zm5.985,20.514-8.072,8.072A1.614,1.614,0,0,1,15,26.3l6.931-6.931L15,12.442a1.614,1.614,0,1,1,2.283-2.283l8.072,8.072A1.613,1.613,0,0,1,25.358,20.514Z"
                                                              fill="#fff"/>
                                                    </svg>
                                                </div>
                                            </Link>

                                            <Link to={`/create-metaverse?type=four`} className="in-wrap d-flex">
                                                <div className="in-wrap-head d-flex align-items-center">
                                                    <div className="img-wrapper mr-3">
                                                        <img className="img-fluid" src={dvd} alt="meta" />
                                                    </div>

                                                    <h4 className="fs-18">Create Spin Video from VR Video</h4>
                                                </div>

                                                <div className="in-wrap-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="38.746" height="38.746"
                                                         viewBox="0 0 38.746 38.746">
                                                        <path id="Path_97" data-name="Path 97"
                                                              d="M19.373,0A19.373,19.373,0,1,0,38.746,19.373,19.394,19.394,0,0,0,19.373,0Zm5.985,20.514-8.072,8.072A1.614,1.614,0,0,1,15,26.3l6.931-6.931L15,12.442a1.614,1.614,0,1,1,2.283-2.283l8.072,8.072A1.613,1.613,0,0,1,25.358,20.514Z"
                                                              fill="#fff"/>
                                                    </svg>
                                                </div>
                                            </Link>

                                            <Link to={`/create-metaverse?type=five`} className="in-wrap d-flex">
                                                <div className="in-wrap-head d-flex align-items-center">
                                                    <div className="img-wrapper mr-3">
                                                        <img className="img-fluid" src={dvd} alt="meta" />
                                                    </div>
                                                    <h4 className="fs-18">Panoramic Image to VR Video</h4>
                                                </div>

                                                <div className="in-wrap-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="38.746" height="38.746"
                                                         viewBox="0 0 38.746 38.746">
                                                        <path id="Path_97" data-name="Path 97"
                                                              d="M19.373,0A19.373,19.373,0,1,0,38.746,19.373,19.394,19.394,0,0,0,19.373,0Zm5.985,20.514-8.072,8.072A1.614,1.614,0,0,1,15,26.3l6.931-6.931L15,12.442a1.614,1.614,0,1,1,2.283-2.283l8.072,8.072A1.613,1.613,0,0,1,25.358,20.514Z"
                                                              fill="#fff"/>
                                                    </svg>
                                                </div>
                                            </Link>

                                        </div>
                                    </div>
                                : ''
                            }

                        </div>
                    </div>
                </div> 

                <div className={`commonModal modal demo-modal homeModal ${showModal?'show-drop':''}`} id="demoModal" tabIndex="-1" role="dialog"
                     aria-labelledby="demoModal" aria-hidden="true" style={{opacity: "1"}}>
                    <div className="modal-dialog modal-dialog-centered watch-demo-dialog" role="document">
                        <div className="modal-content">
                            
                                {/* <h5 className="modal-title" /> */}
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>handleClosePopup()}>
                                    <span aria-hidden="true">
                                    <img src={closeModalx} />
                                    </span>
                                </button>
                            
                            <div className="modal-body">
                                <div className='embed-container'><iframe src={url} frameBorder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default React.memo(DashboardHead);