import React from "react";
import {Helmet} from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
import './css/privacy-styles.css'
import PrivacyEmail from "./PrivacyEmail";
import PrivacyGdpr from "./PrivacyGdpr";
import {useSelector} from "react-redux";

const Privacy = () => {

    const brandName = useSelector(state => state.rebrand.data);

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'VirtualReel'} | Privacy </title>
            </Helmet>

            <div className="privacy-page-body">
               <main className="privacy-page-main">
                   <Header/>

                   <div className="privacy-account">
                       <PrivacyEmail/>
                       <PrivacyGdpr/>
                   </div>

                   <Footer/>
               </main>
            </div>

        </>
    )
}

export default React.memo(Privacy);