import React, {useEffect, useState} from "react";

import tileIcon from "../../images/bg-21-9.png";
import timeLineIcon from "../../images/timeline-settings.svg";
import {useDispatch, useSelector} from "react-redux";
import {
    addAudioControl,
    addImageColor,
    addSelectLayer,
    addTextLayer,
    updateTextData,
    updateTextStyle
} from "../../actions/editorAction";
import {fontJson} from "../../global/global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import randomstring from "randomstring";
import {changeCurrentLoad} from "../../actions/supportAction";

const VideoTiles = () => {

    const dispatch = useDispatch();

    const imageData = {
        id:27991,
        size:"1920x1080",
        startTime:"5",
        endTime:"20",
        type:"color",
        path:"",
        bgcolor:"#00A5B8",
        position: [
            190.4282,
            799.7985
        ]
    }

    const campType = useSelector(state => state.currentLayer.data.campaign_type);
    const textData = useSelector(state => state.currentLayer.data.texts_data);
    const imagesData = useSelector(state => state.currentLayer.data.images_data);
    const bgFile = useSelector(state => state.currentLayer.data.bg_file);
    const introData = useSelector(state => state.currentLayer.data.intro_video);
    const outroData = useSelector(state => state.currentLayer.data.outro_video);


    const [textTiles, setTextTiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [activeImage, setActiveImage] = useState([]);
    const [foreAudio, setForeAudio] = useState({
        type: 'tts',
        volume: 100
    });
    const [backgroundAudio, setBackgroundAudio] = useState({
       type: 'library',
       volume: 100
    });

    let activeText = textData.filter(text => text.is_selected === true);

    const handleAddText = () => {
        dispatch(updateTextStyle('UPDATE_SELECTED_LAYER', ''));

        let randId = parseInt(randomstring.generate({length: 5, charset: 'numeric'}))

        fontJson.id = randId;
        dispatch(addTextLayer(fontJson));

        imageData.id = randId
        dispatch(addImageColor(imageData));
    }

    const handleSelectTile = (item) => {
        dispatch(addSelectLayer(item.id));
    }

    const handleRemoveSlide = (e, id, active_id) => {
        e.stopPropagation();

        if(active_id != id) {
            dispatch(updateTextData('DELETE_TEXT_LAYER', id));
            dispatch(updateTextData('DELETE_IMAGE_LAYER', id));
        }
    }

    const handleForeground = (e) => {
        setForeAudio({...foreAudio, type: e.target.value});

        dispatch(addAudioControl('UPDATE_FOREGROUND_AUDIO', e.target.value, foreAudio.volume));
    }

    const handleBackground = (e) => {
        setBackgroundAudio({...backgroundAudio, type: e.target.value});

        dispatch(addAudioControl('UPDATE_BACKGROUND_AUDIO', e.target.value, backgroundAudio.volume));
    }

    useEffect(()=>{

        if(activeText.length > 0) {

            let activeImage = imagesData.filter(image => image.id == activeText[0].id);

            setActiveImage(activeImage);

        }
    },[JSON.stringify(textData), JSON.stringify(imageData)])

    useEffect(()=>{
        setTextTiles(textData);
    },[textData])



    return(
        <>
            <section className="slide-sec">
                <div className="container-fluid">
                    <div className="d-flex align-items-center">
                        <div className="img-wrapper mr-3">
                            <img onClick={()=>setShowModal(true)} className="img-fluid timeline-settings" src={timeLineIcon} alt="timeline" width="30px"/>
                        </div>
                         <div className="col-md-11">

                             {
                                 bgFile.length > 0 && campType !== 'campaign_two' ?
                                     bgFile.map((item,index)=>(
                                         <div className="slide-box inOut" key={index}>
                                             <div className="row">

                                                 {
                                                     introData.length > 0 ?
                                                         <div className="col cursor-pointer" onClick={()=>dispatch(changeCurrentLoad('intro'))}>
                                                             <div className="bg-ul min" style={{backgroundImage: `url(${introData[0]?.poster})`, minWidth: "250px"}}/>
                                                         </div>
                                                     : ''
                                                 }

                                                 <div className="col cursor-pointer" onClick={()=>dispatch(changeCurrentLoad('bg_layer'))}>
                                                     <div className="bg-ul min" style={{backgroundImage: `url(${item.poster})`}} />
                                                 </div>

                                                 {
                                                     outroData.length > 0 ?
                                                         <div className="col cursor-pointer" onClick={()=>dispatch(changeCurrentLoad('outro'))}>
                                                             <div className="bg-ul min" style={{backgroundImage: `url(${outroData[0]?.poster})`, minWidth: "250px"}}/>
                                                         </div>
                                                         : ''
                                                 }
                                             </div>
                                         </div>
                                     ))
                                     : ''
                             }

                             {
                                 campType === 'campaign_two' ?
                                 <div className="slide-box">
                                     <ul className="d-flex list-inline align-items-center m-0" style={{backgroundImage: `url(${bgFile.length > 0? bgFile[0].poster : ''})` }}>
                                         {
                                             imagesData.length > 0 && campType === 'campaign_two' ?
                                                 imagesData.map((item,index)=>(
                                                     <li className={`${activeText[0].id===item.id?'active-slide':''}`} key={index}>
                                                         <div className="box-ar-wrapper box-slide-cover cursor-pointer" onClick={()=>handleSelectTile(item)}
                                                            style={{backgroundColor: `${activeImage.length > 0 && item.type === 'color' ? item.bgcolor : ''}`}}>
                                                             {
                                                                 activeImage.length > 0 && item.type!=='color' ?
                                                                     <img src={item.type==='video'? item.size : item.path} alt="image" className="img-fluid" />
                                                                 : ''
                                                             }
                                                         </div> 
                                                         <div className="vid-dur del">
                                                            <div className="delete-icon icon cursor-pointer" onClick={(e)=>handleRemoveSlide(e, item.id, activeText[0].id)}>
                                                                            <svg viewBox="0 0 448 512">
                                                                                <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                                                            </svg>
                                                                        </div>
                                                            </div>
                                                     </li>
                                                 ))
                                                 : ''
                                         }

                                         {
                                             textTiles.length > 0 && campType === 'campaign_two' && textTiles.length < 6 ?
                                                 <div className="add-more-image plus-icon">
                                                     <a className="cursor-pointer text-white" onClick={()=>handleAddText()}>
                                                         <FontAwesomeIcon icon={faPlus} />
                                                     </a>
                                                 </div>
                                                 : ''
                                         }

                                     </ul>
                                 </div>
                                 : ''
                             }
                         </div>
                    </div>
                </div>
            </section>

            <div className={`modal fade demo-modal popular-videos  ${showModal ? 'show-drop' : ''}`} id="demoModal"
                 tabIndex="-1" role="dialog"
                 aria-labelledby="demoModal" aria-hidden="true" style={{opacity: "1"}}>
                <div className="modal-dialog" role="document"
                     style={{width: "600px", margin: "0px auto", top: "80px"}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Audio Controls</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => setShowModal(false)}>
                                <span aria-hidden="true">
                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                </span>
                                {/* <span aria-hidden="true">&times;</span> */}
                            </button>
                        </div>
                        <div className="modal-body">
                                <div className="row mb-2">
                                    <div className="col-md-5">
                                        <h6>Foreground Audio</h6>
                                    </div>
                                    <div className="col-md-7">
                                        <select value={foreAudio.type} onChange={(e)=>handleForeground(e)} className="form-control">
                                            <option value={'tts'} >Robo Voice</option>
                                            {/*<option value={'library'} >Library Audio</option>*/}
                                            {/*<option value={'vr_video'}>VR Audio</option>*/}
                                            <option value={'layer_audio'}>Layer Audio</option>
                                            {/*<option value={'upload'} >Upload Audio</option>*/}
                                        </select>
                                    </div>
                                    {/*<div className="col-md-5">*/}
                                    {/*    <div className="volDiv">*/}
                                    {/*    <label>Volume</label>*/}
                                    {/*    <input type="range" name="volume" min="0" max="100"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            <div className="row">
                                <div className="col-md-5">
                                    <h6>Background Audio</h6>
                                </div>
                                <div className="col-md-7">
                                    <select value={backgroundAudio.type} onChange={(e)=>handleBackground(e)} className="form-control">
                                        <option value={'library'} selected>Library Audio</option>
                                        <option value={'vr_audio'}>VR Audio</option>
                                        {/*<option> value={'tts'}>Robo Voice</option>*/}
                                        {/*<option value={'layer_audio'}>Layer Audio</option>*/}
                                        {/*<option value={'upload'}>Upload Audio</option>*/}
                                    </select>
                                </div>
                                {/*<div className="col-md-5">*/}
                                {/*<div className="volDiv">*/}
                                {/*    <label>Volume</label>*/}
                                {/*    <input type="range" name="volume" min="0" max="100"/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(VideoTiles);