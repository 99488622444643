import React, {useState} from "react";

import youtubeDisable from "../../images/YT_DISABLE.png";
import youtubeEnable from "../../images/YT-DEFAULT.png";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import {fetchSocialAccounts} from "../../actions/socialAction";
import {useDispatch, useSelector} from "react-redux";
import GoogleLogin from "react-google-login";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../Alert";

const ConnectYoutube = (props) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [intData, setIntData] = useState({
        id: '',
        user_id: auth.user!==null?auth.user.id:''
    })

    // Sweetalert
    const [button, setButton] = useState('Disconnect');
    const [showDelete, setShowDelete] = useState(false);

    const deleteIntegration = (id) => {
        setIntData({...intData, id: id});
        setShowDelete(true);
    }

    const onConfirm = () => {
        setButton('Disconnecting...');
        deleteSocialAccounts();
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        }else{
            console.log(response);
        }
    }

    const deleteSocialAccounts = () => {
        axios({
            method: "POST",
            url: `${baseURL}delete-social-network`,
            data: intData,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(res.data.message,'success'));
                dispatch(fetchSocialAccounts(auth.token));
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err=>{
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const connectYoutube = (code) => {
        axios({
            method: "POST",
            url: `${baseURL}save-youtube`,
            data: JSON.stringify({code: code,user_id: auth.user.id}),
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(fetchSocialAccounts(auth.token));
            }else{
                dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(err=>{

        })
    }

    return(
        <>
            <Alert/>
            <div id="youtube" className="row justify-content-between align-items-center row-2">
                <div className="col-lg-1 col-12">
                    <div className="img-wrapper">
                        <img src={props.data.length>0?youtubeEnable:youtubeDisable} className="mx-auto img-fluid" width="70px" />
                    </div>
                </div>
                {
                    props.data.length > 0 ?
                        props.data.map((item,index)=>(
                            <>
                                <div className="col-lg-7 col-12" key={index}>
                                    <div className="text-wrapper">
                                        <p className="md-txt">YouTube</p>
                                        <p className="md-txt">{item?.username} - Added on {item.created}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 text-right">
                                    <div className="row justify-content-end btns">
                                        <button className="btn-connect btn-2" onClick={(e)=>deleteIntegration(item.id)}>Disconnect</button>
                                    </div>
                                </div>
                            </>
                        ))
                    :
                        <>
                            <div className="col-lg-7 col-12">
                                <div className="text-wrapper">
                                    <p className="md-txt">YouTube</p>
                                    <p className="md-txt">Not Connected</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 text-right"></div>
                        </>
                }
                <div className="col-12 text-center mt-4">
                    <GoogleLogin
                        clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                        render={(renderProps) => (
                            <button onClick={renderProps.onClick} type="button" className="btn-connect btn-1">Connect</button>
                        )}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        scope="https://www.googleapis.com/auth/youtube"
                        responseType="code"
                        accessType="offline"
                        prompt="consent"
                        cookiePolicy={"single_host_origin"}
                    />
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to disconnect this YouTube account ?
            </SweetAlert>
        </>
    )
}

export default React.memo(ConnectYoutube);