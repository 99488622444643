import React from "react";

import youtubeDisable from "../../images/YT_DISABLE.png";
import youtubeEnable from "../../images/YT-DEFAULT.png";
import facebookEnable from "../../images/logo-facebook.svg";
import facebookDisable from "../../images/FB_DISABLE.png";
import trimEnable from "../../images/logo-trim.svg";
import trimDisable from "../../images/TRIM_DISABLE.png";
import reelEnable from "../../images/reelmerge-icon.png";
import reelDisable from "../../images/REELMERGE (Disable).png";
import { Link, animateScroll as scroll } from "react-scroll";

const IntegrationHead = ({data}) => {

    return(
        <>
            <div className="integrations">
                <div className="title text-center">
                    <p className="h3 f-600">Integrations</p>
                </div>
                <div className="integrations-row content">
                    <div className="row justify-content-center align-items-center">
                        <a href="#facebook" className="integrations-row-box hvr-grow col colmn colmn-1">
                            <div className={`img-wrapper ${data.facebook.length>0?'':'disable-img'}`}>
                                {/* <img src={data.facebook.length>0?facebookEnable:facebookDisable} alt="facebook" className="mx-auto img-fluid" /> */}
                                <img src={facebookEnable} alt="facebook" className="mx-auto img-fluid" />
                            </div>
                            <p className="fs-18 text-center">Facebook</p>
                        </a>
                        <a href="#youtube" className="integrations-row-box hvr-grow col colmn colmn-2">
                            <div className={`img-wrapper ${data.youtube.length>0?'':'disable-img'}`}>
                                {/* <img src={data.youtube.length>0?youtubeEnable:youtubeDisable} alt="youtube" className="mx-auto img-fluid" /> */}
                                <img src={youtubeEnable} alt="facebook" className="mx-auto img-fluid" />

                            </div>
                            <p className="fs-18 text-center">Youtube</p>
                        </a>
                        <a href="#trimreel" className="integrations-row-box hvr-grow col colmn colmn-2">
                            <div className={`img-wrapper ${data.trimreel.length>0?'':'disable-img'}`}>
                                {/* <img src={data.trimreel.length>0?trimEnable:trimDisable} alt="trimreel" className="mx-auto img-fluid" /> */}
                                <img src={trimEnable} alt="trimreel" className="mx-auto img-fluid" />

                            </div>
                            <p className="fs-18 text-center">TrimReel</p>
                        </a>
                        <a href="#reelmerge" className="integrations-row-box hvr-grow col colmn colmn-2">
                            <div className={`img-wrapper ${data.reelmerge.length>0?'':'disable-img'}`}>
                                {/* <img src={data.reelmerge.length>0?reelEnable:reelDisable} alt="reelmerge" className="mx-auto img-fluid" /> */}
                                <img src={reelEnable} alt="reelmerge" className="mx-auto img-fluid" />

                            </div>
                            <p className="fs-18 text-center">ReelMerge</p>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(IntegrationHead)