import React from "react";
import {Link} from "react-router-dom";

const TrainingContent = ({supportData, id}) => {
    return(
        <>
            <div className="vid-content">
                <div className="scrollable-content">
                    <div className="row" style={{display: "block"}}>

                        {
                            supportData.length > 0 ?
                                supportData.map((item,index)=>(
                                    item.id === id ?
                                        item.items.map((article,index)=>(
                                            <Link to={`/training-article?id=${item.id}&art_id=${article.id}`} className="colmns colmns-1 mb-4" key={index}>
                                                <div className="vid-title">
                                                    <label style={{color: "#fff"}} className="fs-18">{article?.title}</label>
                                                </div>
                                                <div className="img-wrapper">
                                                    <img className="img-fluid" src={article?.feature_url} />
                                                </div>
                                            </Link>
                                        ))
                                    : ''
                                ))
                            : ''
                        }

                    </div>
                </div>
            </div>

        </>
    )
}

export default TrainingContent;

