import React from "react";

import EditTeamClient from "./EditTeamClient";

const TeamClientAccount = (props) => {
    return(
        <>
            <section id="create-acc-table-sec" className="create-acc-table-sec">
                <div className="custom-wrapper container">
                    <div className="content-wrapper">
                        <p className="h4 mb-4">{props.type} Account</p>
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col" className="md-txt">Name</th>
                                    <th scope="col" className="md-txt">Email Address</th>
                                    <th scope="col" className="md-txt">Created</th>
                                    <th scope="col" className="md-txt">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    props.users.length > 0 ?
                                        props.users.map((user,index)=>(
                                            <EditTeamClient user={user} type={props.type} fetchClientTeam={props.fetchClientTeam} key={index} />
                                        ))
                                    :
                                        <tr align="center">
                                            <td colSpan="4">No {props.type} created</td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(TeamClientAccount);