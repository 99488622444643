import React, {useEffect, useState} from "react";
import soundsIcon from "../../images/SOUNDS WAVE.png";
import SearchAudio from "./SearchAudio";
import UploadAudio from "./UploadAudio";
import TtsAudio from "./TtsAudio";
import {useSelector} from "react-redux";

let audio = false;
const AudioLibrary = () => {

    const audioUrl = useSelector(state => state.currentLayer.data.audio_url);
    const bgAudioUrl = useSelector(state => state.currentLayer.data.bg_audio_url);

    const [play, setPlay] = useState(false);
    const [audioType, setAudioType] = useState('');
    const [currentUrl, setCurrentUrl] = useState('');
    const [selectedAudio, setSelectedAudio] = useState('');

    const playAudio = (url, type) => {

        setAudioType(type);
        if(play === false) {
            if (audio !== false) {
                audio.pause();
                setPlay(false);
            }

            audio = new Audio(url);
            audio.play()
                .then(r => console.log('audio playing...'));
            audio.onended = function (){
                setPlay(false);
            }

            setPlay(true)
        }else{
            if (audio !== false) {
                audio.pause();
                setPlay(false);
                audio = false;
            }
        }
    }

    useEffect(()=>{
        if (audio !== false) {
            audio.pause();
            setPlay(false);
            audio = false;
        }
    },[currentUrl])


    return(
        <>
            <div className="audio-box-sec">
                <div className="row">
                    <div className="col-md-11 mx-auto ">
                        <div className="audio-box-menu">
                            <ul className="nav mb-3 d-flex justify-content-between align-items-center py-1" id="pills-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pills-audioMusic-tab" data-toggle="pill" href="#pills-audioMusic" role="tab" aria-controls="pills-audioMusic" aria-selected="true">Library Audio</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-tts-tab" data-toggle="pill" href="#pills-tts" role="tab" aria-controls="pills-tts" aria-selected="false">Robo Voice</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="pills-audioLibrary-tab" data-toggle="pill" href="#pills-audioLibrary" role="tab" aria-controls="pills-audioLibrary" aria-selected="false">Upload Audio</a>
                                </li>
                            </ul>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <span className="horigental-line" />
                                </div>
                            </div>
                            <div className="tab-content mt-3" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-audioMusic" role="tabpanel" aria-labelledby="pills-audioMusic-tab">
                                    {
                                        audioUrl !== '' ?
                                            <div className="preview-selected mb-4"> Current Audio <button onClick={()=>playAudio(audioUrl,'library')}>{play && audioType==='library' ? 'Pause': 'Play'}</button> </div>
                                        : ''
                                    }
                                    <SearchAudio currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} selectedAudio={selectedAudio}  setSelectedAudio={setSelectedAudio}/>
                                </div>
                                <div className="tab-pane fade" id="pills-tts" role="tabpanel" aria-labelledby="pills-tts-tab">
                                    {
                                        bgAudioUrl !== '' ?
                                            <div className="preview-selected mb-4"> Current Audio <button onClick={()=>playAudio(bgAudioUrl,'tts')}>{play && audioType==='tts' ? 'Pause': 'Play'}</button> </div>
                                        : ''
                                    }

                                    <TtsAudio/>
                                </div>
                                <div className="tab-pane fade" id="pills-audioLibrary" role="tabpanel" aria-labelledby="pills-audioLibrary-tab">
                                    {
                                        audioUrl !== '' ?
                                            <div className="preview-selected mb-4"> Current Audio <button onClick={()=>playAudio(audioUrl, 'upload')}>{play && audioType==='upload' ? 'Pause': 'Play'}</button> </div>
                                        : ''
                                    }
                                    <UploadAudio currentUrl={currentUrl} setCurrentUrl={setCurrentUrl} selectedAudio={selectedAudio}  setSelectedAudio={setSelectedAudio} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(AudioLibrary)