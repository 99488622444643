import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {addAudio} from "../../actions/editorAction";
import {faSpinner, faPlay, faPause} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

let audio = false;
const AudioFiles = ({type, icon , url, title, setCurrentUrl, currentUrl, selectedAudio, setSelectedAudio,id ,handleDelete}) => {

    const dispatch = useDispatch();

    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);

    let clearPause;
    const playAudio = (url) => {

        if (audio !== false) {
            audio.pause();
            setPlay(false);
        }

        setLoader(true);
        audio = new Audio(url);
        audio.play()
            .then(r => setLoader(false)).catch(err => console.log(err));

        setCurrentUrl(url);

        setPlay(true)

        // clearPause = setTimeout(() => {
        //     pauseAudio()
        // }, audioDuration * 1000)

    }

    const pauseAudio = (url) => {

        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }

    const selectAudio = (url) => {
        setSelectedAudio(url);

        dispatch(addAudio(url));
    }

    const removeAudio = () => {
        setSelectedAudio('');

        dispatch(addAudio(''));
    }

    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])

    // useEffect(() => {
    //     let au = document.createElement('audio');
    //     au.src = url;
    //     au.addEventListener('loadedmetadata', function () {
    //         setAudioDuration(Number((au.duration).toFixed(1)));
    //     }, false);
    // }, [])

    return(
        <>
            <div className="col-12 col-md-6 mb-3">
                <div className={`${type}-music-box`}>
                    <div className={`card ${selectedAudio===url ? 'active-audio' : ''}`}>

                        <div className="active-audio-wrapper cursor-pointer" onClick={()=>removeAudio()}>
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </div>

                        <h6 className="card-title text-left m-0 "></h6>
                        <div className="card-body mb-3 d-flex justify-content-center cursor-pointer" onClick={()=>selectAudio(url)}>
                            <img src={icon} alt="audio file" />
                        </div>
                        <div className="card-footer d-flex align-items-center">
                            <div className="card-play-icon">
                                {
                                    play && currentUrl === url ? <FontAwesomeIcon icon={faPause} onClick={() => pauseAudio(url)} />
                                        : <FontAwesomeIcon icon={loader?faSpinner:faPlay} onClick={() => playAudio(url)} />
                                }
                            </div>
                            <div className="card-music-name ml-2">
                                <h6 className="m-0">{title}</h6>
                            </div>
                            {/* <div className="row">
                                <div className="col-sm-3 d-flex align-items-center justify-content-center">
                                    <div className="card-play-icon">
                                        {
                                            play && currentUrl === url ? <FontAwesomeIcon icon={faPause} onClick={() => pauseAudio(url)} />
                                                : <FontAwesomeIcon icon={loader?faSpinner:faPlay} onClick={() => playAudio(url)} />
                                        }
                                    </div>
                                </div>
                                <div className="col-9 d-flex align-items-center">
                                    <div className="card-music-name">
                                        <h6 className="m-0">{title}</h6>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {
                        type === 'upload' ?
                            <div className="vid-dur cursor-pointer" onClick={(e)=>handleDelete(e, id, 'audio')}>
                                <div className="delete-icon icon">
                                    <svg viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                    </svg>
                                </div>
                            </div>
                        : ''
                    }

                </div>
            </div>
        </>
    )
}

export default React.memo(AudioFiles);