import React, {useEffect, useState} from "react";
import base219 from "../../images/base-21-9.mp4";

const TextPreview = ({currentLayer}) => {

    const [bgData, setBgData] = useState([]);
    const [activeLayer, setActiveLayer] = useState([]);
    const [textStyles, setTextStyles] = useState({
        fontSize: '18px',
        fontFamily: 'popins',
        color: '#ffffff',
        textAlign: 'left',
        lineHeight: 1,
        fontStyle: 'normal',
        fontWeight: 'normal',
        textTransform: 'normal'
    })
    let activeText = currentLayer.texts_data.filter(text => text.is_selected === true);


    useEffect(()=>{

        setActiveLayer(activeText)

        if(activeText.length > 0) {

            let activeImage = currentLayer.images_data.filter(image => image.id == activeText[0].id);
            setBgData(activeImage);

            setTextStyles({
                fontSize: activeText[0].fontSize+'px',
                fontFamily: activeText[0].fontFamily,
                color: activeText[0].color,
                textAlign: activeText[0].fontAlign,
                lineHeight: activeText[0].lineHeight,
                fontStyle: activeText[0].italic?'italic':'normal',
                fontWeight:  activeText[0].bold?'bold':'normal',
                textTransform: activeText[0].fontSize,
                background: activeText[0].bgColor
            })
        }

    },[JSON.stringify(currentLayer.texts_data), JSON.stringify(currentLayer.images_data)])

    useEffect(()=>{
        if(bgData.length > 0){
            if(bgData[0]['type']==='video'){
                const videoE = document.getElementById('text-prev-video');
                videoE.pause();
                videoE.muted = true;
                videoE.loop = true;
                videoE.crossOrigin = "anonymous";
                const videoS = document.getElementById('text-prev-video-src');
                videoS.src = bgData[0]['path'];
                videoS.type = 'video/mp4';
                videoE.load();
                videoE.addEventListener('canplay', (event) => {
                    videoE.play();
                });
            }
        }
    },[bgData])

    return(
        <>
            <div className="editor-preview-wrapper">
                <div className="editor-preview-box">
                    {/* THIS IS VIDEO BASE TO KEEP THE EDITOR PREVIEW RESPONSIVE */}
                    {/* Don't! Remove This Video Tag I used this a wrapper to preview to contain the responsiveness of preview*/}
                    <video className="preview-def-wh" width="100%" height="100%" style={{ opacity: 1 }}>
                        <source src={base219} type="video/mp4" />
                    </video>
                    {/* Don't! Remove This Video Tag I used this a wrapper to preview to contain the responsiveness of preview*/}
                    {/* THIS IS VIDEO BASE TO KEEP THE EDITOR PREVIEW RESPONSIVE */}

                    {
                        activeLayer.length > 0 ?
                            <div className="aspect-ratio-wrapper text-preview-wrapper " style={{background: `${bgData.length>0 && bgData[0]['type']==='color' ? bgData[0].bgcolor : ''}`}}>
                                <div className="preview-text">
                                    <span>
                                        <span style={textStyles}>
                                           {activeLayer[0].text}
                                        </span>
                                    </span>
                                </div>

                                <div className="text-preview-wrapper-overlay">

                                    {
                                        bgData.length>0 && bgData[0]['type']==='image' ?
                                           <img src={bgData[0]['path']} alt={`image`} />
                                        : ''
                                    }

                                    {
                                        bgData.length>0 && bgData[0]['type']==='video' ?
                                            <video id="text-prev-video">
                                                <source id="text-prev-video-src" src={``} />
                                            </video>
                                        : ''
                                    }

                                </div>
                            </div>
                        :
                            <div className="aspect-ratio-wrapper" />
                    }
                </div>


            </div>
        </>
    )
}

export default React.memo(TextPreview);