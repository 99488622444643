import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";

const VideoPopupModal = ({showModal, setShowModal, videoUrl}) => {
    return(
        <>
            <div className={`modal fade demo-modal popular-videos  ${showModal ? 'show-drop' : ''}`} id="demoModal" tabIndex="-1" role="dialog"
                 aria-labelledby="demoModal" aria-hidden="true" style={{opacity: "1"}}>
                <div className="modal-dialog" role="document"
                     style={{width: "800px", margin: "0px auto", top: "80px"}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" />
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                                <span aria-hidden="true">
                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                </span>
                            </button>
                        </div>
                        <div className="modal-body player-popup">
                            {
                                videoUrl !== '' ? <ReactPlayer controls={true} playing={showModal} url={videoUrl}/>
                                : <h6 className="text-center">Video not generated yet</h6>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(VideoPopupModal);