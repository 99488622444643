import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {baseURL, imageData} from "../../global/global";
import InfiniteScroll from "react-infinite-scroll-component";
import {addImageLayer, replaceImageLayer, updateCampTwoMedia, updateTextStyle} from "../../actions/editorAction";
import HoverVideoPlayer from "react-hover-video-player";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import randomstring from "randomstring";
import {addTimelineData} from "../../actions/timelineAction";


const SearchMediaVideo = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const campType = useSelector(state => state.currentLayer.data.campaign_type);
    const textData = useSelector(state => state.currentLayer.data.texts_data);

    const selectedText = textData.filter(text => text.is_selected === true);

    const [loader, setLoader] = useState();
    const [error, setError] = useState(false);

    const [videoData, setVideoData] = useState([]);
    const [search, setSearch] = useState({
        keyword: 'nature',
        type: 'video',
        page_number: 1
    });

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });

        setVideoData([]);
    }

    const searchVideo = (page = 1) => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}load-library-images`,
            data: JSON.stringify({ keyword: search.keyword, type: search.type, page_number: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setError(false);

                let arr = [...videoData];
                setVideoData(arr.concat(res.data.data));
            } else {
                setError(res.data.message);
            }

            setSearch({ ...search, page_number: page });

            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);

        searchVideo();
    }

    const processBackground = (url, poster, id) => {

        dispatch(updateTextStyle('UPDATE_TIMELINE_SELECTED_LAYER', ''));

        imageData.id = id;
        imageData.video_src = url;
        delete imageData.src;
        delete imageData.scaleX;
        delete imageData.scaleY;
        delete imageData.hover_video;
        delete imageData.background_slide_type;

        dispatch(addTimelineData(imageData))
    }

    const handleVideoLayer = (type, url, poster) => {

        if(campType === 'campaign_two'){
            let repImage = {
                path: url,
                type: 'video',
                bgcolor: '',
                size: poster
            }

            if(selectedText.length > 0){
                repImage.id = selectedText[0].id;
                dispatch(updateCampTwoMedia('UPDATE_CAMP_TWO_MEDIA',repImage));
            }
        }else{
            dispatch(updateTextStyle('UPDATE_SELECTED_IMAGE_LAYER', ''));
            let id = randomstring.generate({length: 10, charset: 'numeric'});

            let imageJson = {
                id: id,
                size: "1920x1080",
                startTime: 0,
                endTime: 10,
                type: type,
                path: url,
                poster: poster,
                position: [0, 0],
                scale: [1, 1],
                opacity: 1,
                is_selected: true
            }

            if(campType === 'campaign_three'){
                dispatch(replaceImageLayer(imageJson));
            }else{
                dispatch(addImageLayer(imageJson));
                processBackground(url, poster, id)
            }
        }

    }

    useEffect(() => {
        searchVideo();

        return () => {
            setVideoData([]);
        }
    }, [])

    return (
        <>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="media-search-box">
                        <form method="post" onSubmit={(e) => onFormSubmit(e)} className="input-group">
                            <div className="input-group-prepend">
                                <button type="submit" className="input-group-text"><i className="fas fa-search" /></button>
                            </div>
                            <input type="text" onChange={(e) => onInputChange(e)} className="form-control" placeholder="Search" />
                        </form>
                    </div>
                </div>
            </div>
            <div className="search-load-box mt-4 test3">
                <div className="row scrollSt" id="scrollableVideo" style={{ overflowX: "hidden" }}>
                    <InfiniteScroll
                        dataLength={videoData.length} //This is important field to render the next data
                        next={() => searchVideo(search.page_number + 1)}
                        hasMore={true}
                        scrollableTarget="scrollableVideo"
                        className="row"
                    >
                        {
                            videoData.length > 0 ?
                                videoData.map((item, index) => (
                                    <div className="colmn col-6 cursor-pointer" key={index} onClick={() => handleVideoLayer('video', item.url, item?.thumbnail)}>
                                        <div className="search-load d-flex justify-content-center align-items-center testmedia">
                                        {/*<div className="vid-dur"><span>1:30</span></div>*/}
                                            <HoverVideoPlayer
                                                videoSrc={item.url}
                                                pausedOverlay={
                                                    <img src={item?.thumbnail} alt={`3d video`} width="100%" />
                                                }
                                                loadingOverlay={
                                                    <div className="loader-center"><i style={{ color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></div>
                                                }
                                                className="video"
                                            />
                                        </div>
                                    </div>
                                ))
                                : ''
                        }
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h6 className="text-center" style={{fontSize: "30px"}}><FontAwesomeIcon icon={faSpinner} /></h6>
                                </div>
                                : ''
                        }
                        {
                            error ?
                                <div className="col-md-12">
                                    <h6 className="text-center">{error}</h6>
                                </div>
                                : ''
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>
    )
}

export default React.memo(SearchMediaVideo);