import React, {useEffect, useState} from "react";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useSelector} from "react-redux";
import ProjectCard from "../project/ProjectCard";
import {Link} from "react-router-dom";


const DashboardProjects = () => {

    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [projects, setProjects] = useState([]);

    const fetchProjects = () => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-all-projects`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res=>{
            if(res.data.status === true){
                setProjects(res.data.data);
            }
        }).catch(err=>{

        })
    }


    useEffect(()=>{
       fetchProjects();
    },[])

    return(
        <>
            <section id="s1" className="s1">
                <div className="custom-wrapper container">
                    <div className="title-area row mx-0 align-items-center justify-content-between">
                        <div className="colmn colmn-1" style={{width: "auto"}}>
                            <h3 className="h2 f-500">Recent Projects</h3>
                        </div>

                        <div className="colmn colmn-1" style={{width: "auto"}}>
                            <Link to="/projects" className="proj-btn d-flex align-items-center">
                                <p className="h4 view-all-text">View all Projects</p>

                                <div className="img-wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38.746" height="38.746"
                                         viewBox="0 0 38.746 38.746">
                                        <path id="Path_97" data-name="Path 97"
                                              d="M19.373,0A19.373,19.373,0,1,0,38.746,19.373,19.394,19.394,0,0,0,19.373,0Zm5.985,20.514-8.072,8.072A1.614,1.614,0,0,1,15,26.3l6.931-6.931L15,12.442a1.614,1.614,0,1,1,2.283-2.283l8.072,8.072A1.613,1.613,0,0,1,25.358,20.514Z"
                                              fill="#fff"/>
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="projects row mx-0">
                        {
                            projects.length > 0 ?
                                projects.slice(0,8).map((item, index)=>(
                                    <ProjectCard item={item} fetchProjects={fetchProjects} key={item.project_id} />
                                ))
                            :
                                <h6 className="text-center">You have not created any 360 Degree Video yet. Start creating a stunning videos from your dashboard now.</h6>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardProjects;