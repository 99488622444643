import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {useDispatch, useSelector} from "react-redux";
import {deleteUserData, downloadData, stopUserData} from "../../actions/authAction";
import Alert from "../Alert";

const PrivacyGdpr = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [button, setButton] = useState('Delete Data');
    const [msgData ,setMsgData] = useState('You will not be able to recover this file');
    const [showDelete, setShowDelete] = useState(false);
    const [type, setType] = useState('delete');

    const modalData = [
        {
            message : 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
        },
        {
            message : 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your account, you would have to contact our support team.'
        },
        {
            message : 'VirtualReel stores your private information. If you wish to download these data, you can do that by clicking Download My Data button.'
        }
    ]

    const onConfirm = () => {
        setButton('Please Wait...');

        if(type === 'delete'){
            dispatch(deleteUserData(auth.user?.id, setShowDelete))
        }
        else if(type === 'stop'){
            dispatch(stopUserData(auth.user?.id, setShowDelete))
        }
        else if(type === 'download'){
            dispatch(downloadData(auth.user?.id, setShowDelete));
        }
    }

    const onButtonClick = (e) =>{
        setShowDelete(true);

        if(e === 'delete'){
            setMsgData(modalData[0].message);
            setButton('Delete Data');
            setType('delete');
        }
        else if(e === 'stop'){
            setMsgData(modalData[1].message);
            setButton('Stop Processing');
            setType('stop');
        }
        else if(e === 'download'){
            setMsgData(modalData[2].message);
            setButton('Download');
            setType('download');
        }
    }

    const onCancel = (e) => {
        setShowDelete(false);
    }


    return(
        <>
            <Alert/>
            <section id="account-data-sec" className="account-data-sec">
                <div className="custom-wrapper container">
                    <div className="content-wrapper">
                        <p className="h4 mb-4">Manage Your Account Data</p>
                        <div className="account-data-box">
                            <div className="row align-items-center mx-0 justify-content-between">
                                <div className="text-wrapper">
                                    <p className="md-txt f-14">VirtualReel stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music tiles along with TTS Text to speech). If you wish to download these data, you can do that -by clicking" download your data. </p>
                                </div>
                                <div className="btn-wrapper">
                                    <button onClick={(e)=>onButtonClick('download')} id="download-my-data" className="f-500 md-txt account-data-btn download-data">
                                        Download my data
                                    </button>
                                </div>
                            </div>
                            <div className="row align-items-center mx-0 justify-content-between">
                                <div className="text-wrapper">
                                    <p className="md-txt f-14">If you wish to stop your private data from getting processed on VirtualReel you can dick the button to do so. Please note that doing this will disable your account and you will not be able to use VirtualReel further. In order to reactivate your account, you would have to contact our support team at <a href="https://support.vineasx.com" target="_blank">support@vineasx.com</a>. <span style={{color: '#FF6363'}}>Please Proceed With Caution.</span></p>
                                </div>
                                <div className="btn-wrapper">
                                    <button onClick={(e)=>onButtonClick('stop')} id="stop-my-data" className="f-500 md-txt account-data-btn stop-data">
                                        Stop Processing my data
                                    </button>
                                </div>
                            </div>
                            <div className="row align-items-center mx-0 justify-content-between">
                                <div className="text-wrapper">
                                    <p className="md-txt f-14">If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use VirtualReel further. You cannot undo this step from support also. So if you wish to re-use VirtualReel again, then you will have to start all over again from scratch. <span style={{color: '#FF6363'}}>Please Proceed With Caution.</span></p>
                                </div>
                                <div className="btn-wrapper">
                                    <button onClick={(e)=>onButtonClick('delete')} id="delete-my-data" className="f-500 md-txt account-data-btn delete-data">
                                        Delete my data
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                {msgData}
            </SweetAlert>
        </>
    )
}

export default React.memo(PrivacyGdpr);