import React, {useEffect, useState} from "react";
import thumb from "../../images/img-vr.png";
import {Link} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../actions/alert";
import SocialPostModel from "./SocialPostModel";
import VideoPopupModal from "../editor/VideoPopupModal";
import icon from "../../images/trimreel-icon.png";

let intVideo = false;
let intVideoQueue = false;
const ProjectCard = ({item, fetchProjects}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [showViewModal, setShowViewModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const [showModal,setShowModal] = useState(false);
    const [user, setUser] = useState(undefined);

    const [buttonText, setButtonText] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [progress, setProgress] = useState(0);
    const [projectStatus, setProjectStatus] = useState(item.render_status);
    const [renderStatus, setRenderStatus] = useState(item.renderProgress);
    const [thumbnail, setThumbnail] = useState(item.output_thumbnail);
    const [outputVideo, setOutputVideo] = useState(item.output_video);
    const [itemRender, setItemRender] = useState(item.project_status);


    const handlePlayVideo = (e, url) => {
        e.stopPropagation();

        setShowViewModal(true);
        setVideoUrl(url)
    }

    const openDeletePopUp = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        deleteCampaign();
    }

    const deleteCampaign = () => {
        setButtonText('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-project`,
            data: { project_id: deleteId },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchProjects();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButtonText('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButtonText('Delete');
        })
    }

    const clearProgress = (type) => {
        if(intVideo!==false && type === 'render'){
            clearInterval(intVideo);
        }
        if(intVideoQueue !== false && type === 'queue'){
            clearInterval(intVideoQueue);
        }
    }

    const checkProgress = (type) => {
        axios({
            method: 'POST',
            url: `${baseURL}fetch-project-status`,
            data: {project_id: item.project_id},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res=>{
            if(res.data.status === true){
                if(res.data.message === 'Success'){
                    clearProgress(type);

                    setThumbnail(res.data.output_thumbnail);
                    setOutputVideo(res.data.output_video);
                }
                setProgress(res.data.renderProgress);
                setProjectStatus(res.data.message);

                if(res.data.message === 'Rendering'){
                    setRenderStatus(res.data.renderProgress);
                }

            }else{
                clearProgress(type);
                setProjectStatus(res.data.message);
            }
        }).catch(err=>{
            clearProgress(type);
        })
    }

    useEffect(()=>{

        if(item.project_status === "1" || item.project_status === "4"){

            intVideo = setInterval(()=>{
                checkProgress('render');
            }, 8000)

        }else if(item.project_status === "5"){

            intVideoQueue = setInterval(()=>{
                checkProgress('queue');
            }, 8000)

        }

        return () => {
            if(intVideo !== false){
                clearInterval(intVideo)
            }
            if(intVideoQueue !== false){
                clearInterval(intVideoQueue);
            }
        }

    },[])

    useEffect(()=>{
        if(auth.user!==null){
            setUser(auth.user);
        }
    },[auth.user])

    return(
        <>
            <div className="col-12 col-md-6 col-lg-3">
                <div className="streaming-projects-box">
                    <div className="img-wrapper mb-3">
                        <div className="proj-info">
                            <div className="row flex-elem">
                                <p className="col-12 proj-date-duration text-center mb-3 fs-18 f-600">
                                    {item?.created_on}
                                </p>

                                <ul className="col-12 list-inline m-0 px-2 d-flex justify-content-around">

                                    {
                                        user!==undefined && user.is_client_account === "0" ?
                                            <li className="text-center">
                                                <Link to={`/editor?id=${item?.project_id}`} className="cursor-pointer text-white">
                                                    <div className="edit-icon icon">
                                                        <svg viewBox="0 0 576 512">
                                                            <path fill="currentColor" d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"></path>
                                                        </svg>
                                                    </div>
                                                    <span className="fs-12">Edit</span>
                                                </Link>
                                            </li>
                                        : ''
                                    }

                                    {
                                        outputVideo !== '' && itemRender !== "3" ?
                                            <>
                                                <li className="text-center">
                                                    <a href={`${baseURL}download-file?url=${outputVideo}`} target="_blank" className="cursor-pointer text-white">
                                                        <div className="download-icon icon">
                                                            <svg viewBox="0 0 512 512">
                                                                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-92.5-4.5l-6.9-6.9c-4.7-4.7-12.5-4.7-17.1.2L273 330.3V140c0-6.6-5.4-12-12-12h-10c-6.6 0-12 5.4-12 12v190.3l-82.5-85.6c-4.7-4.8-12.4-4.9-17.1-.2l-6.9 6.9c-4.7 4.7-4.7 12.3 0 17l115 115.1c4.7 4.7 12.3 4.7 17 0l115-115.1c4.7-4.6 4.7-12.2 0-16.9z"></path>
                                                            </svg>
                                                        </div>
                                                        <span className="fs-12">Download</span>
                                                    </a>
                                                </li>
                                                {
                                                    user!==undefined && user.is_client_account === "0" ?
                                                        <li className="text-center">
                                                            <a onClick={()=>setShowModal(true)} className="cursor-pointer">
                                                                <div className="publish-icon icon">
                                                                    <svg viewBox="0 0 640 512">
                                                                        <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path>
                                                                    </svg>
                                                                    {/* <img src={icon} alt={"logo"}/>  */}
                                                                </div>
                                                                <span className="fs-12">Publish</span>
                                                            </a>
                                                        </li>
                                                    : ''
                                                }
                                            </>
                                        : ''
                                    }

                                    {
                                        user!==undefined && user.is_client_account === "0" ?
                                            <li className="text-center">
                                                <a className="cursor-pointer" onClick={()=>openDeletePopUp(item.project_id)}>
                                                    <div className="delete-icon icon">
                                                        <svg viewBox="0 0 448 512">
                                                            <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                                        </svg>
                                                    </div>
                                                    <span className="fs-12">Delete</span>
                                                </a>
                                            </li>
                                        : ''
                                    }
                                </ul>
                            </div>
                        </div>


                        {
                            projectStatus === "2" || projectStatus === 'Success' ||  projectStatus === 'Completed' ?
                                <img className="img-fluid w-100" src={thumbnail} alt="Thumbnail" />
                            :
                                <div className="render-status" style={{backgroundColor: `${projectStatus==='Failed'?'#e31313':''}`}}>
                                    <div>
                                      <p>{projectStatus}</p>
                                      <img src={thumb} />
                                    </div>
                                    {
                                        projectStatus === "1" || projectStatus === 'Rendering' ?
                                            <>
                                            <div className="progress-wrap">
                                                <div className="progress mt-4">
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${renderStatus}%`, backgroundColor: "#00a5b8" }} aria-valuenow={`${renderStatus}`}
                                                        aria-valuemin="0" aria-valuemax="100">{renderStatus}%
                                                    </div>
                                                </div>
                                                </div>
                                            </>
                                        : ''
                                    }
                                </div>
                        }

                        {/*<img className="img-fluid w-100" src={thumb} alt="Thumbnail" />*/}

                    </div>

                    <div className="d-flex">

                        <div alt={item?.project_name} className="tooltip-cus">
                            <div className="title-area mb-0" style={{overflow: 'hidden'}}>
                                <p className="f-600">{item?.project_name}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={buttonText}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this campaign
                </SweetAlert>

            </div>

            <SocialPostModel showModal={showModal} setShowModal={setShowModal} url={item.output_video} thumbnail={item.output_thumbnail} key={item.project_id} />

            <VideoPopupModal showModal={showViewModal} setShowModal={setShowViewModal} videoUrl={videoUrl} />

        </>
    )
}

export default React.memo(ProjectCard);