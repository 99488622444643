import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import {fetchSocialAccounts} from "../../actions/socialAction";
import {setAlert} from "../../actions/alert";
import SweetAlert from "react-bootstrap-sweetalert";


const ConnectReelapps = (props) => {

    const auth = useSelector(state => state.auth);
    const user_id = auth.user!==null?auth.user.id:'';
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [appDetails, setAppDetails] = useState({
        user_id:  user_id,
        app_name: props.type,
        email: '',
        password: ''
    });

    const [intData, setIntData] = useState({
        id: '',
        user_id: user_id
    })

    // Sweetalert
    const [button, setButton] = useState('Disconnect');
    const [showDelete, setShowDelete] = useState(false);

    const onConfirm = () => {
        setButton('Disconnecting...');
        deleteSocialAccounts();
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const deleteIntegration = (id) => {
        setIntData({...intData, id: id});
        setShowDelete(true);
    }

    const connectPopUp = (e) => {
        setShowModal(true);
    }

    const onInputChange = (e) => {
        setAppDetails({...appDetails, [e.target.name] : e.target.value});
    }

    const deleteSocialAccounts = () => {
        axios({
            method: "POST",
            url: `${baseURL}delete-integration`,
            data: intData,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(res.data.message,'success'))
                dispatch(fetchSocialAccounts(auth.token));
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err=>{
            setShowDelete(false);
            setButton('Delete');
        })
    }


    const connectSocial = async () => {
        await axios({
            method: "POST",
            url: `${baseURL}connect-to-app`,
            data: appDetails,
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                dispatch(setAlert(`${props.name} connected successfully`,'success'))
                dispatch(fetchSocialAccounts(auth.token));
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }
            setShowModal(false);
            setLoader(false);
        }).catch(err=>{
            setShowModal(false);
            setLoader(false);
        })
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        connectSocial().then(r => console.log(r));
    }

    return(
        <>
            <div id={props.type} className="row justify-content-between align-items-center row-2">
                <div className="col-lg-1 col-12">
                    <div className="img-wrapper">
                        <img src={props.icon} className="mx-auto img-fluid" width="70px" alt="icon" />
                    </div>
                </div>
                {
                    props.data.length > 0 ?
                        props.data.map((item,index)=>(
                            <>
                                <div className="col-lg-7 col-12" key={index}>
                                    <div className="text-wrapper">
                                        <p className="md-txt">{props.name}</p>
                                        <p className="md-txt">{item?.username} - Added on {item.created}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 text-right">
                                    <div className="row justify-content-end btns">
                                        <button className="btn-connect btn-2" onClick={(e)=>deleteIntegration(item.id)}>Disconnect</button>
                                    </div>
                                </div>
                            </>
                        ))
                        :
                        <>
                            <div className="col-lg-7 col-12">
                                <div className="text-wrapper">
                                    <p className="md-txt">{props.name}</p>
                                    <p className="md-txt">Not Connected</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 text-right"></div>
                        </>
                }
                <div className="col-12 text-center mt-4">
                    <button onClick={(e)=>connectPopUp(e)} type="button" className="btn-connect btn-1">Connect</button>
                </div>
            </div>

            <div className={`modal fade demo-modal ${showModal ? 'show-drop show' : ''} `}>
                <div className="modal-dialog modal-dialog-centered" role="document" 
                // style={{width: "600px", margin: "50px auto 0", top: "50px"}}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{margin: "auto"}}>
                                {/*<img src={props.icon} width="56px" alt={props.name} className="mr-2" /> */}
                                {`Connect ${props.name}`}
                            </h5>
                            <button onClick={(e)=>setShowModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                                <div className="">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <small className="form-text text-white">Email Address</small>
                                            <input type="email" className="form-control p-2" placeholder="Enter Email"
                                                   required name="email"
                                                   onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <small className="form-text text-white">Password</small>
                                            <input type="password" className="form-control p-2"
                                                   placeholder="********" required name="password"
                                                   onChange={(e) => onInputChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button style={{padding: "12px 20px"}} type="submit" className="btn-connect btn-2" disabled={loader}>
                                            {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Connect
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e)=>onConfirm(e)}
                onCancel={(e)=>onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to disconnect this {props.name} account?
            </SweetAlert>

        </>
    )
}

export default React.memo(ConnectReelapps);