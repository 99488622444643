import React, { useEffect, useState } from "react";
import base219 from "../../images/base-21-9.mp4";
import { fabric } from 'fabric';
import { fabricGif } from "../../global/fabricGif";
import {useDispatch} from "react-redux";
import {
    addSelectLayer,
    updateEditorPosition,
    updateEditorScale,
    updateTextData,
    updateTextStyle
} from "../../actions/editorAction";
import {
    selectTimelineLayer,
    updateTimelineData,
    updateTimelinePosition,
    updateTimelineScale,
    updateImageLayers
} from "../../actions/timelineAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faPause} from "@fortawesome/free-solid-svg-icons";

const initCanvas = () => (
    new fabric.Canvas('canvas', {
        preserveObjectStacking: true,
        height: 432,
        width: 768,
        backgroundColor: '#000000'
    })
);

const initCanvasStyle = () => {
    fabric.Object.prototype.set({
        borderColor: '#3e99f3',
        cornerColor: '#16d489',
        cornerSize: 10,
        cornerStyle: 'circle',
        transparentCorners: false,
        padding: 0,
        rotatingPointOffset: 20,
        borderDashArray: [10, 10],
        objectCaching: true,
        hoverCursor: 'pointer'
    });

}

const Preview = ({ currentLayer, timelineLayer , setCanvasImgData, campaign_type, setCanData}) => {

    const dispatch = useDispatch();

    const [playStatus, setPlayStatus] = useState('pause');
    const [loadVideo, setLoadVideo] = useState(false);
    const [loadLayers, setLoadLayers] = useState(false);
    const [canvas, setCanvas] = useState(null);

    function getVideoElement(url) {

        let videoE = document.createElement('video');
        videoE.muted = true;
        videoE.loop = true;
        videoE.crossOrigin = "anonymous";
        let source = document.createElement('source');
        source.src = url;
        source.type = 'video/mp4';
        videoE.appendChild(source);
        return new Promise((resolve,reject) => {
            videoE.addEventListener('canplay', (event) => {

                videoE.width = videoE.videoWidth;
                videoE.height = videoE.videoHeight;

                resolve(videoE);
            });

            videoE.addEventListener("error", function (e) {
                reject(e);
            });

        });
    }

    const loadCanvasVideo = async (url, id, top=0, left=0, scaleX=0, scaleY=0, obj=false) => {
        await getVideoElement(url).then(videoE=>{
            let fab_video = new fabric.Image(videoE, {
                left: left,
                top: top,
                id: id
            } );

            if(scaleX === 0 && scaleY === 0){
                fab_video.scaleToHeight(200);
            }else{
                fab_video.scaleX = scaleX;
                fab_video.scaleY = scaleY
            }

            canvas.add(fab_video);

            if(currentLayer.campaign_type === 'campaign_three'){
                canvas.centerObject(fab_video)
            }

            fab_video.getElement().play();
            fabric.util.requestAnimFrame(function render() {
                canvas.renderAll();
                fabric.util.requestAnimFrame(render);
            });
        }).catch(err=>{
            console.log(err);
        }).finally(()=>{
            console.log('log', url)
        });


    }

    const getBgVideoElement = (url) => {
        const videoE = document.getElementById('canvas-video');
        videoE.muted = true;
        videoE.loop = true;
        videoE.crossOrigin = "anonymous";
        const videoS = document.getElementById('canvas-video-src');
        videoS.src = url;
        videoS.type = 'video/mp4';
        videoE.load();
        videoE.pause();
        return new Promise((resolve,reject) => {
            videoE.addEventListener('canplay', (event) => {

                videoE.width = videoE.videoWidth;
                videoE.height = videoE.videoHeight;


                resolve(videoE);
            });

            videoE.addEventListener("error", function (e) {
                reject(e);
            });


        });
    }

    const loadVideoBg = async (canvas,url, id) => {
        setLoadVideo(true);

        const videoElement = await getBgVideoElement(url);
        const fab_video = new fabric.Image(videoElement, {left:-10, top: -10, id});
        canvas.add(fab_video);
        fab_video.set('video_src',url);
        fab_video.set('src','https://coursereelserver.reelapps.io/assets/images/logo.png');
        fab_video.scaleToWidth(canvas.width);
        fab_video.getElement().play().then(res=>{
            console.log('bg playing...');
            setLoadVideo(false);

            setPlayStatus('play');
        }).catch(err=>{
            console.log('Error: ',err);
        });

        fab_video.center();
        fabric.util.requestAnimFrame(function render() {
            canvas.renderAll();
            fabric.util.requestAnimFrame(render);
        });


        return fab_video;
    }

    const saveToData = () => {
        let multiplier = 5;
        let url = canvas.toDataURL({
            multiplier: multiplier
        });

        setCanvasImgData(url);
        setCanData(canvas);
    }

    const handlePause = () => {
        setPlayStatus('pause');
        const videoE = document.getElementById('canvas-video');
        videoE.pause();

    }

    const handlePlay = () => {
        setPlayStatus('play');
        const videoE = document.getElementById('canvas-video');
        videoE.play();

    }

    const addTextLayer = (obj) => {
        let quote = new fabric.Textbox(obj.text, {
            id: obj.id,
            fontFamily: 'arial',
            left: 100,
            top: 100,
            width: 180,
            height:50,
            fontSize: 18,
            objecttype: 'textbox',
            fill: '#ffffff'
        });

        quote.type = 'textbox';
        canvas.add(quote);
        canvas.renderAll();

        saveToData();
    }


    const addImageLayer = async (object) => {
        if(object.hasOwnProperty('video_src')){

            setLoadLayers(true);

            let video_url = object['video_src'];
            if(object.hasOwnProperty('hover_video')){
                applyGif(object['hover_video'], object.id).then(res=>{
                    setLoadLayers(false);
                });
            }else{
                loadCanvasVideo(video_url, object.id).then(res=>{
                    setLoadLayers(false);
                })
            }

        }else{
            let fab = new fabric.Image.fromURL(object.src, img => {
                img.id = object.id
                img.scaleToHeight(200);
                img.top = 0;
                img.left = 0;
                canvas.add(img);
                canvas.renderAll();
            },{ crossOrigin: 'Anonymous' });

            if(currentLayer.campaign_type === 'campaign_three'){
                canvas.centerObject(fab)
            }

        }

        saveToData();
    }

    const deleteLayer = () => {

        if(campaign_type !== 'campaign_three') {
            let activeObject = canvas.getActiveObject();

            if (activeObject!==null && activeObject!==undefined && activeObject!=='null') {
                let conf = window.confirm('Your about to delete this layer?');
                if (conf == true) {
                    canvas.remove(activeObject);

                    if (activeObject.type === 'textbox') {
                        dispatch(updateTextData('DELETE_TEXT_LAYER', activeObject.id))
                    } else if (activeObject.type === 'image') {
                        dispatch(updateTextData('DELETE_IMAGE_LAYER', activeObject.id))
                    }

                    canvas.discardActiveObject();
                    canvas.renderAll();
                }
            }

        }else{
            alert(`You can't delete regular video`)
        }
    }

    const handleUpdateOpacity = () => {

        let activeObject = canvas.getActiveObject();

        dispatch(updateTextStyle('UPDATE_IMAGE_BY_ID', activeObject.id));

    }

    const removeEmptyLayers = (timeline) => {
        return new Promise((resolve, reject)=>{
            let arr =  timeline.filter(({src}) => console.log(src));

            resolve(arr);
        })
    }

    useEffect(()=>{
        if(canvas!==null){
            let activeObject = canvas.getActiveObject();

            if(activeObject!==null && activeObject!==undefined && currentLayer.campaign_type !== 'campaign_three') {
                let currentImage = currentLayer.images_data.filter(image => image.id == activeObject.id);

                if(currentImage!==undefined && currentImage!==null && currentImage.length > 0) {
                    if (activeObject.type === 'image') {
                        activeObject.set({
                            opacity: currentImage[0].opacity
                        })
                    }
                }
            }
        }
    },[canvas, JSON.stringify(currentLayer.images_data)])


    /* This useEffect will initialize canvas */
    useEffect(() => {
        setCanvas(initCanvas);
        initCanvasStyle();
    }, []);


    /* This useEffect will load initialData on canvas */
    useEffect(()=>{
        if(canvas!==null){

            let allObjects = canvas.getObjects();
            if(allObjects.length > 0) {
                allObjects.forEach(function (object) {
                    canvas.remove(object);
                });
            }

            canvas.loadFromJSON(JSON.stringify(timelineLayer), canvasLoaded , function(o, object) {
                fabric.log(o, object);
            });
            function canvasLoaded(){
                canvas.renderAll.bind(canvas);
                let objs = timelineLayer['objects'];
                for(let i=0; i< objs.length; i++){

                    if(objs[i].hasOwnProperty('background_slide_type')){

                        if(objs[i]['background_slide_type']==='video'){
                            loadVideoBg(canvas,objs[i]['video_src'],objs[i]['id']).then(fab => {
                                fab.set('background_slide_type','video');
                                fab.set('src',false);
                                fab.sendToBack(fab);
                                fab.selectable = false;
                                fab.hasBorders = false;

                                let bgObject = canvas.getObjects()[1];
                                if(bgObject.hasOwnProperty('background_slide_type')){
                                    canvas.remove(bgObject);
                                }

                                saveToData();

                            });


                        }else if(objs[i]['background_slide_type']==='image'){

                            new fabric.Image.fromURL(objs[i]['video_src'], img => {
                                img.scaleToWidth(canvas.width);
                                img.top = 0;
                                img.left = 0;
                                img.sendToBack(img);
                                img.selectable = false;
                                img.hasBorders = false;
                                canvas.add(img);
                                canvas.renderAll();
                                canvas.remove(canvas.getObjects()[0]);
                            },{ crossOrigin: 'Anonymous' });

                            saveToData();
                        }

                    }
                    else if(objs[i].hasOwnProperty('hover_video')){

                        canvas.remove(objs[i]);
                        applyGif(
                            objs[i]['hover_video'],
                            objs[i]['id'],
                            objs[i]['top'],
                            objs[i]['left'],
                            objs[i]['scaleX'],
                            objs[i]['scaleY']
                        ).then(res=>{
                            canvas.remove(objs[i]);
                        });
                    }
                    else if(objs[i].hasOwnProperty('video_src')){

                        setLoadLayers(true);

                        canvas.remove(objs[i]);
                        loadCanvasVideo(
                            objs[i]['video_src'],
                            objs[i]['id'],
                            objs[i]['top'],
                            objs[i]['left'],
                            objs[i]['scaleX'],
                            objs[i]['scaleY'],
                            objs[i]
                        ).then(res=>{
                            setLoadLayers(false);
                            saveToData();
                        })
                    }


                }

                saveToData();
            }

        }
    },[canvas, JSON.stringify(currentLayer.bg_file)])

    /* This useEffect will add new added layer */
    useEffect(()=>{

        if(canvas !== null) {

            let newObject = timelineLayer.objects[timelineLayer.objects.length - 1];

            switch (newObject.type) {
                case 'textbox':
                    addTextLayer(newObject);
                    break;

                case 'image':
                    addImageLayer(newObject);
                    break;

                default:
                    break;
            }
        }

    },[timelineLayer.objects.length])


    /* This useEffect for change text layers styles */
    useEffect(()=>{
        if(canvas !== null){

            let activeObject = canvas.getActiveObject();

            if(activeObject!==null && activeObject!==undefined && currentLayer.texts_data.length > 0){

                if(activeObject.type === 'textbox') {
                    let currentText = currentLayer.texts_data.filter(text => text.id == activeObject.id);

                    dispatch(addSelectLayer(activeObject.id));

                    activeObject.set({
                        text: currentText[0].uppercase ? currentText[0].text.toUpperCase() : currentText[0].lowercase ? currentText[0].text.toLowerCase() : currentText[0].text,
                        fontSize: currentText[0].fontSize,
                        fill: currentText[0].color,
                        backgroundColor: currentText[0].bgColor,
                        linethrough: currentText[0].stroke,
                        fontWeight: currentText[0].bold ? 'bold' : 'normal',
                        fontStyle: currentText[0].italic ? 'italic' : 'normal',
                        textTransform: 'uppercase',
                        fontFamily: currentText[0].fontFamily,
                        textAlign: currentText[0].fontAlign,
                        lineHeight: currentText[0].lineHeight,
                        underline: currentText[0].underline,
                        textBackgroundColor: false
                    });
                    canvas.renderAll();

                    saveToData();
                }
            }


        }
    },[canvas, JSON.stringify(currentLayer.texts_data)]);


    /* This useEffect for check change images layer (Create VR Video from Regular) */
    useEffect(()=>{

        if(canvas !== null){

            let activeObject = canvas.getActiveObject();
            let currentImage = currentLayer.images_data;

            if(activeObject!==null && activeObject!==undefined && campaign_type === 'campaign_three') {

                let id = activeObject.id;
                canvas.remove(activeObject);


                if(currentImage[0].type ==='video'){
                    setLoadLayers(true);
                    loadCanvasVideo(currentImage[0]['path'], id).then(res=>{
                       setLoadLayers(false);
                       dispatch(updateImageLayers('REPLACE_TIMELINE_LAYER',id ,currentImage[0]['path']))
                    })

                }else if(currentImage[0].type ==='image'){

                    new fabric.Image.fromURL(currentImage[0]['poster'], img => {
                        img.id = id
                        img.scale(0.93);
                        img.top = 0;
                        img.left = 0;
                        canvas.add(img);
                        canvas.renderAll();
                        canvas.centerObject(img)
                    },{ crossOrigin: 'Anonymous' });
                }


            }

            saveToData();

        }

    },[canvas, JSON.stringify(currentLayer.images_data)])


    /* This useEffect for check changes, update, modified in layer */
    useEffect(()=>{
        if(canvas !== null){
            // alert('canvas Layer Modification UseEffect is Firign');
            /* for scaling images and text layer */

            window.addEventListener('keydown',  ({keyCode})=>{
                switch (keyCode) {
                    case 46:
                        deleteLayer();
                        break;

                    default:
                        break;

                }

            })

            canvas.on('object:scaled', function(){
                let activeObject = canvas.getActiveObject();

                switch (activeObject.type) {
                    case 'image':
                        dispatch(updateTimelineScale(activeObject));
                        dispatch(updateEditorScale(activeObject));
                        break;

                    case 'textbox':
                        dispatch(updateTextData('UPDATE_FONT_SIZE_SCALE', activeObject));
                        dispatch(updateTextData('UPDATE_TIMELINE_FONT_SIZE_SCALE', activeObject));
                        //alert(activeObject.fontSize)
                        break;

                    default:
                        break;
                }
            })

            /* for change input text */
            canvas.on('object:modified', function(){
                let activeObject = canvas.getActiveObject();

                switch (activeObject.type) {
                    case 'textbox':
                        dispatch(updateTimelineData('UPDATE_TIMELINE_FONT_TEXT', activeObject));
                        dispatch(updateTextData('UPDATE_FONT_TEXT', activeObject.text));
                        // dispatch(updateTextData('UPDATE_TIMELINE_FONT_SIZE', Math.floor(activeObject.scaleX)*activeObject.fontSize));
                        break;

                    default:
                        break;
                }

            });

            /* for selecting active layer */
            canvas.on('selection:updated', function(){
                let activeObject = canvas.getActiveObject();

                switch (activeObject.type) {
                    case 'textbox':
                        dispatch(addSelectLayer(activeObject.id));
                        dispatch(selectTimelineLayer(activeObject.id));
                        break;

                    case 'image':
                        handleUpdateOpacity(activeObject.id);
                        dispatch(selectTimelineLayer(activeObject.id))
                        break;

                    default:
                        break;
                }

            });

            /* for get object position */
            canvas.on('object:moved', function (){
                let activeObject = canvas.getActiveObject();

                dispatch(updateTimelinePosition(activeObject));
                switch (activeObject.type) {
                    case 'textbox':
                        dispatch(updateEditorPosition('UPDATE_TEXT_POSITION',activeObject));
                        break;

                    case 'image':
                        dispatch(updateEditorPosition('UPDATE_IMAGE_POSITION',activeObject));
                        break;

                    default:
                        break;

                }

            })


            saveToData();

        }
    },[canvas])

    async function applyGif(url, id, top=50, left=50, scaleX =0, scaleY =0) {
        const gif1 = await fabricGif(
            url,
            640,
            360
        );

        if(scaleX!==0 && scaleY!==0){
            gif1.set({ top: top, left: left, id: id, scaleX: scaleX, scaleY: scaleY });
        }else{
            gif1.set({ top: top, left: left, id: id });
        }


        canvas.add(gif1);

        fabric.util.requestAnimFrame(function render() {
            canvas.renderAll();
            fabric.util.requestAnimFrame(render);
        });
    }



    return (
        <>
            <div className="editor-preview-wrapper">
                {
                    loadLayers && loadVideo===false ? <p className="text-center">Please wait loading layers...</p> : ''
                }
                <div className="editor-preview-box">
                    {/* THIS IS VIDEO BASE TO KEEP THE EDITOR PREVIEW RESPONSIVE */}
                    {/* Don't! Remove This Video Tag I used this a wrapper to preview to contain the responsiveness of preview*/}
                    <video className="preview-def-wh" width="100%" height="100%" style={{ opacity: 1 }}>
                        <source src={base219} type="video/mp4" />
                    </video>
                    {/* Don't! Remove This Video Tag I used this a wrapper to preview to contain the responsiveness of preview*/}
                    {/* THIS IS VIDEO BASE TO KEEP THE EDITOR PREVIEW RESPONSIVE */}

                    <div className="aspect-ratio-wrapper">
                        <canvas className="preview-wrapper" id="canvas">

                            {/*
                            
                                THIS IS WHERE YOU PUT ALL THE CONTENT HERE
                            
                            */}
                        </canvas>
                        <video style={{display: 'none'}} id="canvas-video">
                            <source id="canvas-video-src" src={``} />
                        </video>

                        {
                            loadVideo ?
                                <div className="preview-loader">
                                    <div className="preview-loader-text">
                                        Please wait background video is loading...
                                    </div>
                                </div>
                                : ''
                        }

                    </div>
                </div>

                <div className="controls-box">
                    <div className="row mx-0 w-100 justify-content-between align-items-center">
                        <div className="col-12">
                            <div className="play-push text-center">
                                {
                                    playStatus === 'pause' ?
                                        <a onClick={() => handlePlay()}>
                                            <FontAwesomeIcon icon={faPlay}/>
                                        </a>
                                        : <a onClick={() => handlePause()}>
                                            <FontAwesomeIcon icon={faPause}/>
                                        </a>
                                }
                            </div>
                        </div>


                    </div>
                </div>
                {/* <div className="controls-box">
                    <div className="row mx-0 w-100 justify-content-between align-items-center">
                        <div className="col-4 cb-colmn-1">
                            <div className="play-push-left-right">
                                <ul className="list-inline d-flex justify-content-between mx-auto mb-0">
                                    <li><a href="#"><img src={leftIcon} alt="left" /></a></li>
                                    <li><a href="#"><img src={pathIcon} alt="play" /></a></li>
                                    <li><a href="#"><img src={rightPath} alt="right" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-4 cb-colmn-2">
                            <div className="row">
                                <div className="cb-col ccb-col-1 col-6">
                                    <div className="start-end-time">
                                        <input type="text" className="control-time text-center ct-start" placeholder="00:00:00" />
                                    </div>
                                </div>
                                <div className="cb-col ccb-col-2 col-6">
                                    <div className="start-end-time">
                                        <input type="text" className="control-time text-center ct-end" placeholder="00:01:00" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 cb-colmn-3">
                            <div className="img-wrapper d-flex justify-content-end">
                                <img className="img-fluid ml-auto expand-btn" src={expandIcon} alt="expand" />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default React.memo(Preview);