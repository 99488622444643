const initialState = {
    facebook : [],
    youtube: [],
    trimreel: [],
    reelmerge: []
}

export default function (state = initialState, action){
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            return{
                ...state,
                facebook: action.payload.data.facebook,
                youtube: action.payload.data.youtube,
                trimreel: action.payload.data.trimreel,
                reelmerge: action.payload.data.reelmerge
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}