import React, {useState} from "react";
import { useSelector} from "react-redux";
import HoverVideoPlayer from "react-hover-video-player";
import {faPlay, faSpinner, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
import VideoPopupModal from "../editor/VideoPopupModal";

const MetaVerseFootage = ({videos, handleSelectVideo, type, user, error, fetch360Videos, loader}) => {

    const scriptData = useSelector(state => state.script);

    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const selectVideo = (url, poster) => {
        let tempData = {
            user_id: user.id,
            campaign_type: type==='five'? `campaign_one` : `campaign_${type}`,
            bg_file: [{
                startTime: 0,
                endTime: 10,
                path: url,
                poster: poster,
                opacity: 1
            }],
            duration: 0
        }

        if(type === 'two'){
            tempData.text = scriptData.script;
        }
        if(type === 'regular'){
            tempData.campaign_type = 'campaign_three';
        }

        handleSelectVideo(tempData);
    }

    const handlePlayVideo = (e, url) => {
        e.stopPropagation();

        setShowModal(true);
        setVideoUrl(url)
    }

    const handleLoadMore = () => {
        fetch360Videos();
    }

    return(
        <>
            <div className="gallery">
                <div className="row">
                {
                    videos.length > 0 ?
                        videos.map((item,index)=>(
                            <div className="col-12 col-md-3 cursor-pointer" key={index} >
                                <div className="media-wrapper metaverse-img metaverse-img-alt" onClick={()=>selectVideo(item.url,item?.thumbnail ,index)} >
                                    <div className="vid-dur"><span>{item?.duration} s</span></div>
                                    <img src={item?.thumbnail } alt={`360 video`} width="100%" height="100%" />
                                    <div className="overlay__media__wrapper">
                                        {/* <FontAwesomeIcon color={`#00c780`} icon={faPlay} onClick={(e)=>handlePlayVideo(e, item.url)} /> */}
                                        <button onClick={(e)=>handlePlayVideo(e, item.url)}>Preview</button>
                                        <button>Select</button>
                                    </div>
                                </div>
                            </div>
                        ))
                        : ''
                }

                    {
                        error !== '' ?
                            <p>{error}</p>
                        : ''
                    }
                </div>
            </div>

            <div className="lMore">
                <button onClick={()=>handleLoadMore()}>
                    {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} Load More
                </button>
            </div>

            <VideoPopupModal showModal={showModal} setShowModal={setShowModal} videoUrl={videoUrl} />
        </>
    )
}

export default React.memo(MetaVerseFootage);