import axios from "axios";
import {baseURL} from "../global/global";
import {setAlert} from "./alert";
import {fetchSocialAccounts} from "./socialAction";
import {fetchRebrandData} from "./rebrandAction";

export const loadUser = () => (dispatch) => {

    axios({
        method: "POST",
        url: `${baseURL}fetch-user`,
        data: {},
        headers: {'Content-Type': 'application/json', 'Authorization': localStorage.token }
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'USER_LOADED',payload: res.data.data});
            dispatch(fetchSocialAccounts(localStorage.token))
            dispatch(fetchRebrandData(localStorage.token));
        }else {

        }
    }).catch(err=>{
    })
}

export const loginUser = (data, setLoader) => (dispatch) => {
    axios({
        method: "POST",
        url: `${baseURL}login`,
        data: data,
        headers: {'Content-Type': 'application/json' }
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'LOGIN_USER',payload: res.data.data});
        }else {
            dispatch(setAlert(res.data.message,'danger'));
        }
        setLoader(false);
    }).catch(err=>{
        setLoader(false);

        dispatch(setAlert('Something went wrong, Please try again','danger'));
    })
}


export const resetPassword = (data, setLoader, history) => (dispatch) => {
    axios({
        method: "POST",
        url: `${baseURL}reset-password`,
        data: data,
        headers: {'Content-Type': 'application/json' }
    }).then(res=>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));

            history.push('/login');
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setLoader(false);
    }).catch(err=>{
        setLoader(false);
    })
}


export const forgetPassword = (data, setLoader) => (dispatch) => {
    axios({
        method: "POST",
        url: `${baseURL}forgot-password`,
        data: data,
        headers: {'Content-Type': 'application/json' }
    }).then(res=>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));

        }else{
            dispatch(setAlert(res.data.message, "danger"));

        }
        setLoader(false);
    }).catch(err=>{
        setLoader(false);
    })
}


export const logoutUser = () => (dispatch,getState) => {
    axios({
        method: 'POST',
        url : `${baseURL}logout`,
        data: {},
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token }
    }).then(res =>{
        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: 'LOGOUT'});
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
    }).catch(error => {
        console.log(error);
    })
}

export const changeProfile = (data,setProfileStatus) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url: `${baseURL}update-profile-image`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: 'CHANGE_USER_IMAGE', payload: res.data});
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setProfileStatus(false);

    }).catch(error => {
        setProfileStatus(false);
    })
}


export const updateName = (data, setNameLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}update-profile`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
            dispatch({type: 'CHANGE_USER_NAME',payload: data.name});
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setNameLoader(false);

    }).catch(error => {
        setNameLoader(false);
    })

}


export const updatePassword = (data, setNameLoader) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}update-profile`,
        data: data,
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(setAlert(res.data.message, "success"));
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setNameLoader(false);

    }).catch(error => {
        setNameLoader(false);
    })

}


export const downloadData = (id, setShowDelete) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}gdpr-request`,
        data: {type: 'downloadUserData', downloadUserData: 'yes', id: id},
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            window.location.href = res.data.data.url;
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);

    }).catch(error => {
        setShowDelete(false);
    })

}

export const deleteUserData = (id, setShowDelete) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}gdpr-request`,
        data: {type: 'deleteUserData', deleteUserData: 'yes', id: id},
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(logoutUser())
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);

    }).catch(error => {
        setShowDelete(false);
    })

}

export const stopUserData = (id, setShowDelete) => (dispatch,getState) =>{

    axios({
        method: 'POST',
        url : `${baseURL}gdpr-request`,
        data: {type: 'stopProcessingData', stopProcessingData: 'yes', id: id},
        headers: {'Content-Type': 'application/json','Authorization': getState().auth.token}
    }).then(res =>{

        if(res.data.status === true){
            dispatch(logoutUser())
        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }
        setShowDelete(false);

    }).catch(error => {
        setShowDelete(false);
    })

}


export const masterLoginUser = (email,token,history) => (dispatch) => {
    axios({
        method: 'POST',
        url : `${baseURL}master-login`,
        data: JSON.stringify({email, token}),
        headers: {'Content-Type': 'application/json' }
    }).then(res =>{

        if(res.data.status === true){
            dispatch({type: 'LOGIN_USER',payload: res.data.data});

            history.push('/dashboard');

        }else{
            dispatch(setAlert(res.data.message, "danger"));
        }

    }).catch(error => {
        dispatch(setAlert('Getting Internal Server Error', "danger"));
    })
}
