import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import SweetAlert from "react-bootstrap-sweetalert";

const PanaromicUpload = ({handleSelectVideo, user, videos, type, fetchUserLibrary}) => {

    const scriptData = useSelector(state => state.script);
    const dispatch = useDispatch();

    const [deleteId, setDeleteId] = useState({
        id: '',
        type: '',
        user_id: ''
    });
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const selectVideo = (url) => {
        let tempData = {
            user_id: user.user.id,
            campaign_type: type==='five'? `campaign_one` : `campaign_${type}`,
            bg_file: [{
                startTime: 0,
                endTime: 10,
                path: url,
                poster: url,
                opacity: 1
            }],
            file_type: 'image',
            duration: 0
        }

        if(type === 'two'){
            tempData.text = scriptData.script;
        }

        handleSelectVideo(tempData);
    }

    const handleDelete = (e, id, type) => {
        e.stopPropagation();

        setDeleteId({...deleteId, id: id, type: type, user_id: user.user.id});
        setShowDelete(true);
    }

    const onConfirm = () => {
        setShowDelete(true);
        setButton('Deleting...');

        axios({
            method: "POST",
            url: `${baseURL}delete-media`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': user.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchUserLibrary();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    return(
        <>
            <div className="gallery panImg">
                <div className="row">
                {
                    videos.length > 0 ?
                        videos.map((item,index)=>(
                            item.type === "panorama" ?
                                <div className="col-12 col-md-3 cursor-pointer" key={index} >
                                    {/*metaverse-img metaverse-img-alt Removed these classes from below div since it was causing */}
                                    <div className="media-wrapper metaverse-img metaverse-img-alt" onClick={()=>selectVideo(item.url, index)} >
                                    <div className="vid-dur" onClick={(e)=>handleDelete(e, item.id, 'image_panorama')}>
                                        <span><FontAwesomeIcon icon={faTrash}/></span>
                                    </div>
                                        <img src={item.url} alt="panorma" width="100%" />
                                        <div className="overlay__media__wrapper">
                                           <button>Select</button>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        ))
                        : ''
                }
            </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={()=>onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>
        </>
    )
}

export default React.memo(PanaromicUpload);