import React from "react";
import SearchMediaImage from "./SearchMediaImage";
import SearchMediaVideo from "./SearchMediaVideo";
import UploadMedia from "./UploadMedia";


const MediaLibrary = () => {
    return(
        <>
            <div className="media-part-sec">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="media-box-wrraper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="media-menu-box">
                                        <ul className="nav d-flex justify-content-between align-items-center py-1" id="pills-tab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-images-tab" data-toggle="pill" href="#pills-images" role="tab" aria-controls="pills-images" aria-selected="true">Images</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-video-tab" data-toggle="pill" href="#pills-video" role="tab" aria-controls="pills-video" aria-selected="false">Videos</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-medialibrary-tab" data-toggle="pill" href="#pills-medialibrary" role="tab" aria-controls="pills-medialibrary" aria-selected="false">Media Library</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-images" role="tabpanel" aria-labelledby="pills-images-tab">
                                                <SearchMediaImage/>
                                            </div>
                                            <div className="tab-pane fade" id="pills-video" role="tabpanel" aria-labelledby="pills-video-tab">
                                                <SearchMediaVideo/>
                                            </div>
                                            <div className="tab-pane fade" id="pills-medialibrary" role="tabpanel" aria-labelledby="pills-medialibrary-tab">
                                                <UploadMedia/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(MediaLibrary);