
export const addTextLayer = (data) => (dispatch) => {
    dispatch({type: 'ADD_TEXT_LAYER',payload: data});
}

export const addSelectLayer = (id) => (dispatch) => {
    dispatch({type: 'ADD_SELECTED_LAYER', payload: id});
}

export const updateTextStyle = (type,data) => (dispatch) => {
    dispatch({type: type, payload: data});
}

export const addImageLayer = (data) => (dispatch) => {
    dispatch({type: 'ADD_IMAGE_LAYER',payload: data});
}

export const replaceImageLayer = (data) => (dispatch) => {
    dispatch({type: 'REPLACE_IMAGE_LAYER',payload: data});
}

export const addAudio = (data) => (dispatch) => {
    dispatch({type: 'ADD_AUDIO', payload: data})
}

export const addIntroVideo = (type,data) => (dispatch) => {
    dispatch({type: type, payload: data})
}

export const removeIntroVideo = (type) => (dispatch) => {
    dispatch({type: type})
}

export const addTtsAudio = (data) => (dispatch) => {
    dispatch({type: 'ADD_TTS_AUDIO', payload: data});
}

export const addMetaVerseVideo = (url, poster) => (dispatch) => {
    let data = {url:url, poster:poster}

    dispatch({type: 'ADD_BG_VIDEO', payload: data});
}

export const addCampaignData = (data) => (dispatch) => {
    dispatch({type: 'ADD_CAMPAIGN_DATA', payload: data});
}

export const addDuration = (data) => (dispatch) => {
    dispatch({type: 'ADD_DURATION', payload: data});
}

export const addSlideTime = (data) => (dispatch) => {
    dispatch({type: 'ADD_SLIDE_DURATION', payload: data});
}

export const updateEditorPosition = (type,data) => (dispatch) => {
    dispatch({type: type, payload: data})
}

export const updateTextData = (type, data) => (dispatch) => {
    dispatch({type: type, payload: data})
}

export const updateEditorScale = (data) => (dispatch) => {
    let scaleData = {
        scaleX: data.scaleX,
        scaleY: data.scaleY,
        top: data.top,
        left: data.left,
        id: data.id
    }
    dispatch({type: 'UPDATE_EDITOR_SCALE', payload: scaleData});
}

export const addImageColor = (data) => (dispatch) => {
    dispatch({type: 'UPDATE_IMAGE_COLOR', payload: data});
}

export const updateCampTwoMedia = (type, data) => (dispatch) => {
    dispatch({type: type, payload: data})
}


export const addAudioControl = (type, value, volume) => (dispatch) => {
    let data = {
        type: value,
        volume: volume
    }

    dispatch({type: type, payload: data});
}