import React, {useEffect, useState} from "react";
import Header from "../Header";
import "./css/meta-library.css";
import axios from "axios";
import {baseURL} from "../../global/global";
import swal from "sweetalert";
import {useDispatch, useSelector} from "react-redux";
import defaultIcon from "../../images/aulbm.png";
import VideoPopupModal from "../editor/VideoPopupModal";
import randomstring from "randomstring";
import {addRegularData} from "../../actions/supportAction";
import {useHistory} from "react-router-dom";

const UploadRegularVideo = () => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();

    const [uploadLoader, setUploadLoader] = useState(false);
    const [percent, setPercent] = useState(0);
    const [videosUpload, setVideosUpload] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();


    }

    const handleFileChange = (e) => {
        if(e.target.files[0] !== undefined){

            let allowedExt = ['video/mp4'];

            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 20000000;
                let type = "image_panorama";
                if (e.target.files[0].type === "video/mp4") {
                    allowedSize = 500000000;
                    type = "threesixty";
                }


                if (e.target.files[0].size < allowedSize) {
                    setUploadLoader(true);

                    const formData = new FormData();
                    formData.append('upload_type', type);
                    formData.append('user_id', auth?.user?.id);
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}file-upload`, formData, options).then(res => {
                        if (res.data.status === true) {
                            fetchUserLibrary();
                        }
                        setUploadLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for video 500MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    const fetchUserLibrary = () => {

        axios({
            method: "POST",
            url: `${baseURL}fetch-user-assets`,
            data: {user_id: auth.user!==null?auth.user.id:''},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setVideosUpload(res.data.data.threesixtyvideo);
            }

        }).catch(err=>{

        })
    }

    const selectVideo = (url) => {

        let id = randomstring.generate({length: 10, charset: 'numeric'});

        let imageJson = {
            id: id,
            size: "1920x1080",
            startTime: 0,
            endTime: 10,
            type: 'video',
            path: url,
            position: [65.1256, 325.6281],
            scale: [13, 13],
            opacity: 1,
            is_selected: true
        }

        dispatch(addRegularData(imageJson));
        history.push('/create-metaverse?type=regular');

    }

    const handlePlayVideo = (e, url) => {
        e.stopPropagation();

        setShowModal(true);
        setVideoUrl(url)
    }

    useEffect(()=>{
        fetchUserLibrary();

        return () => {
            setVideosUpload([]);
        }

    },[])

    return(
        <>
            <div className="meta-lib-page-body">
                <div className="meta-lib-page-main">

                    <Header/>

                    <section id="videos-wrapper" className="videos-wrapper mb-5" >
                        <div className="custom-wrapper container">

                            <h6 className="mt-3 mb-1">Please Select / Upload a Regular Video</h6>

                            <form method="post" onSubmit={(e)=>handleSubmit(e)}>
                                <div className="search-wrapper alt">
                                    {/*<div className="alert alert-warning alertModify mb-2">*/}
                                    {/*    <strong>Note: </strong> To get a high quality output, make sure the video you convert into VR video, is a minimum on 1080p or higher (1920x1080). VR Videos play well on 4k only.*/}
                                    {/*</div>*/}

                                    <div className="alert alert-warning alertModify">
                                        <strong>Note: </strong> Please upload a Regular video only. Uploading any video other then Regular video will not produce the expected output
                                    </div>
                                </div>

                                <div className="search-wrapper d-flex align-items-center w-100 search-videos" style={{marginTop: '10px'}}>
                                    <div className="d-flex align-items-center">
                                        <label className="search-video"> Choose File
                                            <input type="file" name="search" placeholder="Search here for videos" onChange={(e)=>handleFileChange(e)} className="search-box input-file"/>
                                        </label>
                                        <span className="mp4Al">.mp4 file upto 500mb is allowed</span>
                                    </div>
                                    <button type="submit" className="fs-18 btn search-btn">GO</button>
                                </div>
                            </form>

                            {
                                uploadLoader ?
                                    <div className="progress mt-1 mb-4">
                                        <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#00a5b8" }} aria-valuenow={`${percent}`}
                                             aria-valuemin="0" aria-valuemax="100">{percent>5?percent+'%':''}
                                        </div>
                                    </div>
                                    : ''
                            }


                            <div className="gallery">
                                <div className="row">
                                    {
                                        videosUpload.length > 0 ?
                                            videosUpload.map((item,index)=>(
                                                item.video_type === "360" ?
                                                    <div className="col-12 col-md-3 cursor-pointer" key={index} >
                                                        <div className="media-wrapper test3" onClick={()=>selectVideo(item.video_url,item.poster ,index)} >

                                                            <img src={item?.poster ? item.poster : defaultIcon} alt={item?.name} width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                            <div className="overlay__media__wrapper">
                                                                {/* <FontAwesomeIcon color={`#00c780`} icon={faPlay} onClick={(e)=>handlePlayVideo(e, item.video_url)} /> */}
                                                                <button onClick={(e)=>handlePlayVideo(e, item.video_url)}>Preview</button>
                                                                <button>Select</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                            ))
                                            : ''
                                    }
                                </div>
                            </div>

                        </div>
                    </section>
                </div>

                <VideoPopupModal showModal={showModal} setShowModal={setShowModal} videoUrl={videoUrl} />

            </div>
        </>
    )
}

export default React.memo(UploadRegularVideo);